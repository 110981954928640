import React, { useEffect, useState } from "react"
import { FoldsContainer, Form } from "./styles"
import Inputfield from "src/components/inputfield"
import Equations from "src/modules/fithub-shared/anthropometry/fold-equations"
import skinfolds from "src/modules/fithub-shared/anthropometry/skinfolds.json"
import { ListItem } from "../perimetry/styles"
import {
    SkinFoldsList,
    SkinFoldsListKeys,
} from "src/modules/fithub-shared/anthropometry/folds"

interface FoldsProps {
    defaultValues: SkinFoldsList
    onChangeFolds(folds: SkinFoldsList): void

    defaultProtocol: number
    onChangeProtocol(protocol: number): void
}

const Folds: React.FC<FoldsProps> = (props) => {
    const [valores, setValores] = useState<SkinFoldsList>(props.defaultValues)
    const [protocol, setProtocol] = useState(props.defaultProtocol)

    useEffect(() => {
        setValores(props.defaultValues)
    }, [props.defaultValues])

    function handleChangeProtocol(e: React.ChangeEvent<HTMLSelectElement>) {
        const s = parseInt(e.target.value)

        setProtocol(s)
        props.onChangeProtocol(s)
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target

        const v = {
            ...valores,
            [name]: isNaN(parseFloat(value)) ? undefined : parseFloat(value),
        }
        setValores(v)
        props.onChangeFolds(v)
    }

    const Highlight = (d: string) => {
        if (Equations[protocol].affects.includes("folds_all")) return "bold"
        else return Equations[protocol].affects.includes(d) ? "bold" : undefined
    }

    return (
        <FoldsContainer>
            <select defaultValue={protocol} onChange={handleChangeProtocol}>
                {Equations.map((element, index) => (
                    <option key={index + "op"} value={index}>
                        {element.title}
                    </option>
                ))}
            </select>
            <span />
            <Form>
                {skinfolds.map((dobra, index) => (
                    <ListItem key={index}>
                        <input
                            name={dobra.name}
                            min={0}
                            style={{ width: "50px" }}
                            onFocus={(e) => e.target.select()}
                            type="number"
                            defaultValue={
                                props.defaultValues[
                                    dobra.name as SkinFoldsListKeys
                                ]
                            }
                            onChange={handleInputChange}
                        />
                        <p
                            style={{
                                textAlign: "end",
                                fontWeight: Highlight(dobra.name),
                            }}
                        >
                            {dobra.pt}
                        </p>
                    </ListItem>
                ))}
            </Form>
        </FoldsContainer>
    )
}

export default Folds
