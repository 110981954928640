import { useQuery } from "react-query"
import api from "src/API/rest"

import { ExerciseTemplate } from "src/modules/fithub-shared/interfaces/templates"

export const useExercisesList = (trainer_id?: string) => {
    return useQuery(
        "exercises",
        () =>
            api
                .post("exercises/list", { created_by: trainer_id })
                .then((res) => res.data as ExerciseTemplate[])
                .catch((error) => {
                    console.error("Erro na requisição:", error)
                    return [] as ExerciseTemplate[]
                }),
        {
            staleTime: 60 * 1000,
            cacheTime: 3600 * 1000,
        }
    )
}

export * as Queries from "./queries"
