import styled from "styled-components"

export const ListItem = styled.li`
    flex-direction: row;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
        font-size: 12px;
        text-align: end;
    }
`
