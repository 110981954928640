import React, { CSSProperties, InputHTMLAttributes, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "../styles"
import { InputMask } from "src/modules/fithub-shared/functions/functions"
import { MdError } from "react-icons/md"
import { ValidatedField } from "src/modules/fithub-shared/interfaces/form"
import mostrarAlerta from "src/components/utils/alert"
import { filterProps } from "../functions"
// import { Container } from './styles';

interface CustomButton extends InputHTMLAttributes<HTMLInputElement> {
    ref?: React.RefObject<HTMLInputElement>
    error?: boolean
    icon?: IconType
    height?: number
    width?: number | string
    selectOnFocus?: boolean
    maxLength?: number
    minLength?: number
    iconColor?: string
    radius?: string
    autofocus?: boolean
    backgroundColor?: string
    mask: InputMask
    onChangeText?: (value: string) => void
    onChangeValidate?: (value: ValidatedField) => void
}

const MaskInput: React.FC<CustomButton> = (props) => {
    const id = props.placeholder + "_input"

    const [error, setError] = useState(props.error)
    const [value, setValue] = useState<string>(props.defaultValue as string)
    const [typing, setTyping] = useState(false)

    const _maxLength = !props.maxLength ? props.mask.length : props.maxLength

    const Ico = () => {
        if (props.icon === undefined) {
            return <></>
        } else {
            return (
                <div style={{ marginInline: 5 }}>
                    <button />
                    <props.icon
                        color={props.iconColor ? props.iconColor : "#444"}
                        size={18}
                    />
                </div>
            )
        }
    }

    function _OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const finalValue = e.target.value
            .replace(/[^0-9]+/g, "")
            .replace("-", "")
            .substring(0, _maxLength)

        setValue(finalValue)

        e.target.value = finalValue

        if (props.onChange) props.onChange(e)
        if (props.onChangeText) props.onChangeText(finalValue)
        if (props.onChangeValidate)
            props.onChangeValidate({
                value: finalValue,
                valid: !error as boolean,
            })
    }

    function _OnBlur(e?: React.FocusEvent<HTMLInputElement, Element>) {
        setTyping(false)
        props.onEnded && e && props.onEnded(e)
        if (error !== false) setError(!props.mask.isValid(value))

        if (props.onChangeValidate) {
            props.onChangeValidate({
                value: value,
                valid: props.mask.isValid(value),
            })
        }
    }

    function _OnFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
        setTyping(true)
        if (props.selectOnFocus) e.target.select()
    }

    function _OnEnd(/*_e: React.SyntheticEvent<HTMLInputElement, Event>*/) {
        setTyping(false)
    }

    function clickPlaceholder() {
        setTyping(true)
        const input = document.getElementById(id) as HTMLInputElement | null
        if (input) {
            input.style.display = "block"
            input.focus()
            if (input.value.length > 0) input.select()
        }
    }

    const dynamicStyle = (hide: boolean) => {
        if (hide) {
            return {
                display: "hidden",
                width: 0,
                height: 0,
                fontSize: 14,
            } as CSSProperties
        } else {
            return { fontSize: 14 } as CSSProperties
        }
    }

    return (
        <Area
            tabIndex={-1}
            radius={props.radius}
            onClick={clickPlaceholder}
            style={{
                flexDirection: "row",
                width: props.width,
                position: "relative",

                backgroundColor: props.backgroundColor
                    ? props.backgroundColor
                    : "#fff",
                borderColor: error ? "red" : "#ccc",
            }}
        >
            <Ico />

            <input
                {...props}
                tabIndex={props.tabIndex}
                id={id}
                type={props.mask.type}
                maxLength={_maxLength}
                value={value}
                style={dynamicStyle(!typing)}
                onChange={_OnChange}
                onBlur={_OnBlur}
                onEnded={_OnEnd}
                onFocus={_OnFocus}
            />
            {!typing && (
                <p
                    tabIndex={-1}
                    style={dynamicStyle(typing)}
                    onClick={clickPlaceholder}
                    onFocus={clickPlaceholder}
                    onSelect={clickPlaceholder}
                    id={id + "_fake"}
                >
                    {value ? props.mask.func(value) : props.placeholder}
                </p>
            )}

            {value && error && (
                <MdError
                    tabIndex={-1}
                    style={{ position: "absolute", right: 0 }}
                    color="red"
                />
            )}
        </Area>
    )
}

export default MaskInput
