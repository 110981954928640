import styled from "styled-components"

export const ModalContent = styled.div`
    display: flex;
    flex: 1;

    padding: 10px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 10;

    align-items: center;
    justify-content: center;

    background-color: #0008;
`

export const Wrapper = styled.div`
    * {
        display: flex;
    }

    display: flex;
    flex: 1;
    padding: 10px;

    max-width: 600px;

    width: 100vw;

    border-radius: 5px;

    background-color: #fff;
`
