import { useState, useCallback, useEffect } from "react"

type UseDragResizeProps = {
    initialFlex: number
    minFlex: number
    maxFlex: number
}

const useDragResize = ({
    initialFlex,
    minFlex,
    maxFlex,
}: UseDragResizeProps) => {
    const [flex, setFlex] = useState(() => {
        const savedFlex = localStorage.getItem("flex")
        return savedFlex !== null ? parseFloat(savedFlex) : initialFlex
    })
    const [dragging, setDragging] = useState(false)

    const handleDragStart = useCallback(
        (e?: React.DragEvent<HTMLSpanElement>) => {
            setDragging(true)
            if (!e) return
            // Remove ghost image
            const img = new Image()
            img.src = ""
            e.dataTransfer.setDragImage(img, 0, 0)
        },
        []
    )

    const handleDrag = useCallback(
        (
            e:
                | React.MouseEvent<HTMLSpanElement>
                | React.TouchEvent<HTMLSpanElement>
        ) => {
            if (!dragging) return

            e.preventDefault()
            const containerWidth = e.currentTarget.parentElement?.clientWidth
            if (!containerWidth) return

            let clientX: number
            if ("touches" in e) {
                clientX = e.touches[0].clientX
            } else {
                clientX = e.clientX
            }

            if (clientX / containerWidth === 0) return
            let newFlex = (clientX - 60) / containerWidth

            // Clamping newFlex between minFlex and maxFlex
            newFlex = Math.max(minFlex, Math.min(newFlex, maxFlex))

            setFlex(newFlex)
        },
        [dragging, minFlex, maxFlex]
    )

    const handleDragEnd = useCallback(() => {
        setDragging(false)
    }, [])

    useEffect(() => {
        localStorage.setItem("flex", flex.toString())
    }, [flex])

    return {
        flex,
        handleDragStart,
        handleDrag,
        handleDragEnd,
    }
}

export default useDragResize
