import styled from "styled-components"

export const Wrap = styled.div`
    flex: 1;
    flex-direction: row;
    align-items: center;

    cursor: text;

    input,
    textarea,
    h4 {
        all: unset;
        display: flex;
        flex: 1;

        font-weight: bold;
        width: 100%;
        cursor: text;
        resize: none;

        border-bottom: 1px solid #ddd;
    }
`

export const Input = styled.textarea`
    all: unset;
    font-size: 16px;
    height: 18px;

    max-width: 400px;

    overflow: hidden;

    background-color: #fff;
    resize: horizontal;
`
