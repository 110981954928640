import React, { useContext } from "react"

import { AuthProvider } from "./contexts/auth"
import AuthRoutes from "./routes/AuthRoutes"
import SignedRoutes from "./routes/SignedRoutes"

function App() {
    return (
        <div className="App">
            <AuthProvider
                signedComponent={<SignedRoutes />}
                unsignedComponent={<AuthRoutes />}
            />
        </div>
    )
}

export default App
