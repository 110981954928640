import React, { MouseEventHandler } from "react"
import { IconType } from "react-icons"
import styled from "styled-components"

interface p {
    icon: IconType
    title: string
    disabled?: boolean
    onClick?: MouseEventHandler
}

export const Button = styled.button<{ disabled?: boolean }>`
    all: unset;
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
    background-color: transparent;
    margin-top: 10px;

    height: 35px;

    border-radius: 8px;

    padding: 10px;

    opacity: ${(p) => (p.disabled ? "0.65" : "1")};

    p {
        text-decoration: ${(p) => (p.disabled ? "line-through" : "none")};
    }

    justify-content: space-between;

    align-items: center;

    transition: background-color 0.25s;

    :hover {
        background-color: #eee;
    }

    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
`

export const ButtonInfo = styled.button<{ disabled?: boolean }>`
    display: flex;
    cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
    background-color: var(--light);
    margin-top: 20px;
    height: 65px;
    width: 300px;
    border-radius: 3px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.1s;

    opacity: ${(p) => (p.disabled ? "0.65" : "1")};

    @media (max-width: 768px) {
        width: 100%;
    }

    p {
        color: black;
        text-decoration: ${(p) => (p.disabled ? "line-through" : "none")};
    }
    :active {
        background-color: #ddd;
    }

    border: 1px solid #ddd;
`

const LargeButton: React.FC<p> = (props) => {
    return (
        <Button {...props}>
            <props.icon />
            <p>{props.title}</p>
        </Button>
    )
}

export const LargeButtonInfo: React.FC<p> = (props) => {
    return (
        <ButtonInfo {...props}>
            <props.icon color="black" />
            <p>{props.title}</p>
        </ButtonInfo>
    )
}

export default LargeButton
