import React from "react"
import useLoadingButton from "src/modules/assessment/useLoadingButton"
import { usePlan } from "src/modules/protocols/context"

// import { Container } from './styles';

const SaveButton: React.FC = () => {
    const { save } = usePlan()

    const button = useLoadingButton(
        () => {
            save().then(() => button.changeState("UNLOADED"))
        },
        1,
        "UNLOADED"
    )

    return <div>{button.Button}</div>
}

export default SaveButton
