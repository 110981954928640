import md5 from "md5"
import moment from "moment"
import React, {
    ChangeEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import Toggle from "react-toggle"
import { Backdrop } from "src/components/DrawerMenu/styles"
import { useOnOutClick } from "src/components/hooks"
import Inputfield from "src/components/inputfield"
import styled from "styled-components"
import { ColorTag } from "../styling/styles"
import { getContrast, pallete } from "../functions"
import EditableText from "src/components/editable-text"
import { FaCheck } from "react-icons/fa"
import api from "src/API/rest"
import AuthContext from "src/contexts/auth"
import { DescriptionArea } from "./styles"

// import { Container } from './styles';

function toLocalISOString(date: string | Date) {
    if (typeof date === "string") date = new Date(date)

    const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
    ) //offset in milliseconds. Credit https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset

    // Optionally remove second/millisecond if needed
    localDate.setSeconds(0)
    localDate.setMilliseconds(0)
    return localDate.toISOString().slice(0, -1)
}

export interface NewEvent {
    id: string
    title: string
    description: string
    start: Date
    end: Date
    isAllDay: boolean | null
    color: string | null
}

interface EventInfoProps {
    onAddEvent(event: NewEvent): void
    onDismiss(): void
    editable?: NewEvent
}

const AddButton = styled.button<{ color: string }>`
    background-color: green;
    padding-inline: 15px;
    height: 40px;
    max-width: 150px;
    border-radius: 25px;
    color: white;

    background-color: ${(p) => p.color};
    color: ${(p) => getContrast(p.color)};
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(p) => getContrast(p.color) + "44"};
    p {
        font-weight: 600;
        transition: font-size 0.05s;
    }

    cursor: pointer;
    :active {
        opacity: 0.8;
    }

    :hover {
        p {
            font-size: 14px;
        }
    }
`

function GetDate(hoursOffset = 0) {
    if (hoursOffset > 0)
        return new Date(new Date().setHours(new Date().getHours() + 1))
    else return new Date()
}

const EventInfo: React.FC<EventInfoProps> = ({
    onAddEvent,
    onDismiss,
    editable,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const auth = useContext(AuthContext)

    const [start, setStart] = useState<Date>(editable?.start ?? GetDate())
    const [end, setEnd] = useState<Date>(editable?.end ?? GetDate(1))
    const [title, setTitle] = useState(editable?.title ?? "Novo Evento")
    const [description, setDescription] = useState(editable?.description ?? "")
    const [isAllDay, setIsAllDay] = useState(editable?.isAllDay ?? false)
    const [color, setColor] = useState(editable?.color ?? pallete[3])

    useOnOutClick(ref, onDismiss)

    function handleOnAddEvent() {
        const data = {
            id: editable?.id ?? "",
            title,
            description,
            start,
            end,
            isAllDay,
            color,
            created_by: auth.user?.id,
        }

        api.post<NewEvent>(editable ? "/events/save" : "/events/create", data)
            .then(() => {
                onAddEvent(data)
            })
            .catch((err) => alert(err))
    }

    function onChangeStart(e: ChangeEvent<HTMLInputElement>) {
        const date = new Date(e.target.value)
        if (moment(date).isValid()) setStart(date)
    }

    function onChangeEnd(e: ChangeEvent<HTMLInputElement>) {
        const date = new Date(e.target.value)
        if (moment(date).isValid()) setEnd(new Date(e.target.value))
    }

    function AllDayChange() {
        setIsAllDay(!isAllDay)
    }

    return (
        <Backdrop className="flex column">
            <div
                ref={ref}
                className="shadow flex column spaced"
                style={{
                    borderRadius: 10,
                    padding: 15,
                    width: "100vw",
                    maxWidth: 500,
                    height: "40%",
                    backgroundColor: "#fff",
                }}
            >
                <div>
                    <EditableText onChange={setTitle} defaultValue={title} />
                </div>

                <DescriptionArea
                    placeholder="Descrição"
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <div className={"flex column"}>
                    <div className={"spaced padded"}>
                        <p>O DIA TODO</p>
                        <Toggle checked={isAllDay} onChange={AllDayChange} />
                    </div>
                    <div className={"spaced padded"}>
                        <p>INICIO</p>
                        <Inputfield
                            disabled={isAllDay}
                            type={"datetime-local"}
                            defaultValue={toLocalISOString(start)}
                            onChange={onChangeStart}
                        />
                    </div>
                    <div className={"spaced padded"}>
                        <p>FIM</p>
                        <Inputfield
                            disabled={isAllDay}
                            type={"datetime-local"}
                            defaultValue={toLocalISOString(end)}
                            onChange={onChangeEnd}
                        />
                    </div>
                    <div
                        style={{ alignItems: "center" }}
                        className={"spaced padded"}
                    >
                        <p>COR</p>

                        <div
                            style={{ flexWrap: "wrap", maxWidth: 150 }}
                            className="flex row"
                        >
                            {pallete.map((e) => (
                                <ColorTag
                                    flex={1 / pallete.length}
                                    key={e}
                                    color={e}
                                >
                                    <button onClick={() => setColor(e)} />
                                </ColorTag>
                            ))}
                        </div>
                    </div>
                </div>

                <AddButton
                    className="shadow"
                    color={color}
                    onClick={handleOnAddEvent}
                >
                    <FaCheck />
                    <p>{editable ? "SALVAR" : "ADICIONAR"}</p>
                </AddButton>
            </div>
        </Backdrop>
    )
}

export default EventInfo
