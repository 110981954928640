import styled from "styled-components";

export const IconButton = styled.button`
    all: unset;
    cursor: pointer;

    transition: opacity 0.1s, transform 0.1s;

    :hover {
        transform: scale(1.2);
    }

    :active {
        transform: scale(1.05);
    }

    :focus {
        border-radius: 5px;
    }
`;
