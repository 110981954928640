import React, { useContext, useRef, useState } from "react"

import { Avatar } from "../../components/header/styles"
import { Item, List, ListHeader, Scroll, SearchField } from "./styles"

import dummy from "../../assets/png/userd-demo-pic.png"
import Inputfield from "../../components/inputfield"

import { RiSearch2Line } from "react-icons/ri"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "../../components/mutable-icon"
import api from "../../API/rest"
import { Student } from "../student-info"
import { MdPersonAdd } from "react-icons/md"
import CreateForm from "./create-form"
import AuthContext from "src/contexts/auth"
import { FilterAndSort } from "./functions"
import { useStudent } from "src/contexts/student"
import { useQuery } from "react-query"
import { ThreeDots } from "react-loader-spinner"

interface StudentsListProps {
    dismiss: () => void
    onSelect: (id: Student) => void
}

export default function StudentsList(props: StudentsListProps) {
    const listing = useQuery("Students", () =>
        api
            .post<Student[]>("/students/list", {
                trainer_id: auth.user?.id,
            })
            .then((res) => res.data)
    )

    const ref = useRef<HTMLDivElement>(null)
    const [create, setCreate] = useState(false)
    const [filter, setFilter] = useState("")

    const filtered = FilterAndSort(filter, listing.data)

    const student = useStudent()

    const auth = useContext(AuthContext)

    function ToggleCreate() {
        setCreate(true)
    }

    function onGoBack() {
        if (create) {
            listing.refetch()
            setCreate(false)
        }

        props.dismiss()
    }

    function onAdd(_s: Student) {
        setCreate(false)
        listing.refetch()
    }

    function OnEditTitle(t: string) {
        return t
    }

    return (
        <List ref={ref}>
            {create ? (
                <CreateForm onGoBack={onGoBack} onAdd={onAdd} />
            ) : (
                <div
                    ref={ref}
                    style={{ flexDirection: "column", height: "100%" }}
                >
                    <ListHeader>
                        <MutableIcon
                            icon={HiChevronLeft}
                            size={40}
                            onClick={onGoBack}
                        />
                        <SearchField>
                            {create ? (
                                <>
                                    <h3>Cadastrar Aluno</h3>
                                </>
                            ) : (
                                <>
                                    <h3>Alunos</h3>
                                    <Inputfield
                                        icon={RiSearch2Line}
                                        radius={"25px"}
                                        width={"100%"}
                                        placeholder={"Pesquisar por aluno..."}
                                        onEndText={OnEditTitle}
                                        onChangeText={setFilter}
                                    />
                                </>
                            )}
                        </SearchField>
                        <MutableIcon
                            disabled={create}
                            icon={MdPersonAdd}
                            size={40}
                            onClick={ToggleCreate}
                        />
                    </ListHeader>
                    <Scroll>
                        {listing.isLoading ? (
                            <div
                                className="flex"
                                style={{
                                    flex: 1,
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ThreeDots
                                    height={60}
                                    width={60}
                                    color="black"
                                />
                            </div>
                        ) : (
                            filtered.map((element, index) => {
                                return (
                                    <Item
                                        key={`${index}`}
                                        disabled={
                                            element.id === student.student?.id
                                        }
                                        onClick={() => props.onSelect(element)}
                                    >
                                        <Avatar src={dummy} />
                                        <div>
                                            <p>{element.name}</p>
                                            <h5>Ativo ({index + 1})</h5>
                                        </div>
                                    </Item>
                                )
                            })
                        )}
                    </Scroll>
                </div>
            )}
        </List>
    )
}
