import moment from "moment"
import React, { createContext, useContext, useEffect, useState } from "react"
import { Student } from "src/screens/student-info"
import styled from "styled-components"

interface StudentContextData {
    student?: Student
    age: number
    onSetStudent(std: Student | undefined): void
    blurContent(value: boolean): void
}

const StudentContext = createContext({} as StudentContextData)

interface Props {
    children: React.ReactNode
}

const StyledProvider = styled(StudentContext.Provider)`
    flex-direction: row;
    flex: 0.5;
`

export const StudentProvider: React.FC<Props> = ({ children }) => {
    const [student, setStudent] = useState<Student | undefined>(() => {
        // Tente obter os dados do localStorage ao inicializar
        const storedStudent = localStorage.getItem("student")
        try {
            if (storedStudent) {
                return JSON.parse(storedStudent)
            } else {
                return undefined
            }
        } catch {
            return undefined
        }
    })

    const [blur, setBlur] = useState(false)

    useEffect(() => {
        if (student !== undefined)
            localStorage.setItem("student", JSON.stringify(student))
    }, [student])

    return (
        <StyledProvider
            value={{
                student: student,
                age: moment(new Date()).diff(student?.birthdate, "years"),
                onSetStudent: setStudent,
                blurContent: setBlur,
            }}
        >
            <div className={blur ? "blur" : ""}>{children}</div>
        </StyledProvider>
    )
}

export const useStudent = () => {
    const context = useContext(StudentContext)
    if (!context) {
        throw new Error("useDrawer must be used within a DrawerProvider")
    }
    return context
}

export default StudentContext
