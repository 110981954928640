import { Student } from "../student-info"

export const FilterAndSort = (search: string, list?: Student[]) => {
    if (!list) return [] as Student[]

    return search.length > 0
        ? list
              .map((student) => ({
                  ...student,
                  matchScore: calculateMatchScore(student.name, search),
              }))
              .filter((student) => student.matchScore > 0)
              .sort((a, b) => {
                  if (a.matchScore !== b.matchScore) {
                      return b.matchScore - a.matchScore // Ordena por pontuação de correspondência decrescente
                  } else {
                      return a.name.localeCompare(b.name) // Se a pontuação for igual, ordene alfabeticamente
                  }
              })
        : list
}

const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const calculateMatchScore = (name: string, search: string) => {
    const normalizedName = removeAccents(name).toLowerCase()
    const normalizedSearch = removeAccents(search).toLowerCase()

    if (normalizedName.includes(normalizedSearch)) {
        // Se o nome do aluno contém a pesquisa, pontuação máxima
        return 3
    } else if (normalizedName.startsWith(normalizedSearch)) {
        // Se o nome do aluno começa com a pesquisa, pontuação média
        return 2
    } else if (
        normalizedName.includes(normalizedSearch.replace(/[aeiou]/g, ""))
    ) {
        // Se o nome do aluno contém a pesquisa sem vogais, pontuação mínima
        return 1
    } else if (
        normalizedName
            .split(" ")
            .some((word) => word.startsWith(normalizedSearch))
    ) {
        // Se alguma palavra do nome do aluno começa com a pesquisa, pontuação média
        return 2
    } else {
        // Se não houver correspondência, pontuação zero
        return 0
    }
}
