import React, { useEffect, useState } from "react"
import { FoldsContainer, Form } from "../folds/styles"
import Inputfield from "src/components/inputfield"
import bones from "../../../fithub-shared/anthropometry/bones.json"

import {
    BonesList,
    BonesListKeys,
} from "src/modules/fithub-shared/anthropometry/bones"
import { ListItem } from "../perimetry/styles"

interface BonesProps {
    defaultBones: BonesList
    onChangeBones(bones: BonesList): void
}

const Bones: React.FC<BonesProps> = (props) => {
    const [valores, setValores] = useState<BonesList>(props.defaultBones)

    useEffect(() => {
        setValores(props.defaultBones)
    }, [props.defaultBones])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target

        const v = {
            ...valores,
            [name]: isNaN(parseFloat(value)) ? undefined : parseFloat(value),
        }

        setValores(v)
        props.onChangeBones(v)
    }

    const Highlight = (d: string) => {
        return d === "femur" || d === "fist"
    }

    return (
        <FoldsContainer style={{ width: "100%" }}>
            <Form style={{ flexDirection: "column" }}>
                {bones.map((dobra, index) => (
                    <ListItem key={index}>
                        <Inputfield
                            autofocus
                            name={dobra.name}
                            selectOnFocus
                            min={0}
                            width={60}
                            type="number"
                            defaultValue={
                                props.defaultBones[dobra.name as BonesListKeys]
                            }
                            onChange={handleChange}
                        />
                        <p
                            style={{
                                fontWeight: Highlight(dobra.name)
                                    ? "bold"
                                    : undefined,
                            }}
                        >
                            {dobra.pt}
                        </p>
                    </ListItem>
                ))}
            </Form>
        </FoldsContainer>
    )
}

export default Bones
