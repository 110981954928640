import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const Card = styled.div`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--panel);

    width: 50%;

    height: 50%;
`

export const Product = styled.div``
