import React, { useEffect, useState } from "react"
import { MdAdd } from "react-icons/md"
import MutableIcon from "src/components/mutable-icon"
import {
    Aerobic,
    AerobicExercise,
    Workout,
} from "src/modules/fithub-shared/interfaces/workout"
import AerobicExerciseComponent from "src/modules/protocols/components/aerobic-exercise"
import { Buttons, CreateButton } from "../weight/styles"
import { Wrapper, Content, ScrollView } from "./styles"
import { usePlan } from "src/modules/protocols/context"
import { ObsWindow } from "../Header/styles"

const AerobicComp: React.FC<{ content: Aerobic }> = (props) => {
    const [list, setList] = useState<AerobicExercise[]>(props.content.exercises)
    const planContext = usePlan()
    useEffect(() => {
        console.log("refresh" + props.content.exercises.length)
    }, [])

    function CreateNewExercise() {
        const n = new AerobicExercise()
        props.content.exercises.push(n)
        setList([...props.content.exercises])
    }

    function DeleteExercise(exc: AerobicExercise) {
        const index = list.findIndex((e) => e === exc)

        if (index === -1) return

        props.content.exercises.splice(index, 1)
        setList([...props.content.exercises])
    }

    function onChange(data: AerobicExercise, index: number) {
        const workout = { ...planContext.workout.selected } as Workout
        const aerobic = workout.item as Aerobic
        aerobic.exercises[index] = data
        workout.item = aerobic
        planContext.workout.update(workout)
    }

    function onChangeObs(e: React.FocusEvent<HTMLTextAreaElement>) {
        const workout = { ...planContext.workout.selected } as Workout
        workout.obs = e.target.value
        planContext.workout.update(workout)
    }

    return (
        <Wrapper>
            <Content>
                <ScrollView>
                    {list.map((element, index) => {
                        return (
                            <AerobicExerciseComponent
                                key={index}
                                content={element}
                                onChange={(d) => onChange(d, index)}
                                onDelete={DeleteExercise}
                            />
                        )
                    })}

                    <Buttons>
                        <span />
                        <div>
                            <div
                                style={{
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginInline: 25,
                                }}
                            >
                                <div>
                                    <CreateButton onClick={CreateNewExercise}>
                                        <MutableIcon size={30} icon={MdAdd} />
                                    </CreateButton>
                                </div>
                            </div>
                        </div>
                        <span />
                    </Buttons>
                    <ObsWindow>
                        <p>Observações do Treino:</p>
                        <textarea
                            defaultValue={planContext.workout.selected?.obs}
                            onBlur={onChangeObs}
                            placeholder="Digite aqui as observações gerais do treino..."
                        />
                    </ObsWindow>
                </ScrollView>
            </Content>
        </Wrapper>
    )
}

export default AerobicComp
