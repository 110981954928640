import styled from "styled-components"

export const Content = styled.div`
    position: relative;

    flex: 1;
    display: flex;
    overflow: auto;

    width: 100%;
    padding: 25px;
    align-items: center;
    flex-direction: column;

    text-transform: uppercase;
`

export const ScrollView = styled.div`
    display: flex;
    width: 100%;
    min-height: min-content;
    flex-direction: column;
`

export const Wrapper = styled.div`
    flex: 1;
    flex-basis: 0;
    overflow: hidden;

    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`
