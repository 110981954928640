import { UseMutationResult, useMutation, useQueryClient } from "react-query"
import { useContext } from "react"

import mostrarAlerta from "src/components/utils/alert"
import AuthContext from "src/contexts/auth"
import api from "src/API/rest"
import { ExerciseTemplate } from "src/modules/fithub-shared/interfaces/templates"

// Defina os tipos necessários

type UseMutationReturnType = ReturnType<typeof useMutation>

export const useDeleteExerciseMutation = (): UseMutationReturnType => {
    const queryClient = useQueryClient()
    const auth = useContext(AuthContext)

    return useMutation<unknown, unknown, unknown, unknown>(
        async (exerciseId) => {
            await api.post("/exercises/delete", {
                id: exerciseId,
                created_by: auth.user?.id,
            })
        },
        {
            onMutate: async (variables) => {
                // Pode ser útil para lidar com a mutação antes de ela ocorrer
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                queryClient.refetchQueries("exercises")
            },
        }
    )
}

export const useNewExerciseMutation = (): UseMutationReturnType => {
    const queryClient = useQueryClient()
    const auth = useContext(AuthContext)

    return useMutation<unknown, unknown, unknown, unknown>(
        async (name) => {
            await api.post("/exercises/new", {
                name,
                created_by: auth.user?.id,
            })
        },
        {
            onMutate: async (variables) => {
                // Pode ser útil para lidar com a mutação antes de ela ocorrer
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                queryClient.refetchQueries("exercises")
            },
        }
    )
}

export const useEditExerciseMutation = (): UseMutationResult<
    void,
    Error,
    ExerciseTemplate,
    unknown
> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, ExerciseTemplate, unknown>(
        async (exerciseData) => {
            await api.post("/exercises/edit", exerciseData)
        },
        {
            onMutate: async (variables) => {
                // Pode ser útil para lidar com a mutação antes de ela ocorrer
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                mostrarAlerta("Salvo!")
                queryClient.refetchQueries("exercises")
            },
        }
    )
}
