import React from "react"
import { DataWrapper } from "../../styles"
import HistoryWrapper from "../HistoryWrapper"
import { useQuery } from "react-query"
import { ExerciseData } from "../.."
import api from "src/API/rest"
import { ThreeDots } from "react-loader-spinner"
import { GetParameter } from "src/assets/functions"
import { IoChatboxEllipses } from "react-icons/io5"

// import { Container } from './styles';

interface Exercise {
    exercise_name: string
    exercise_data: ExerciseData[]
    id: string
}

const ProgressionView: React.FC<{ planId: string; highlight?: string }> = ({
    planId,
    highlight,
}) => {
    const { data, refetch, isLoading } = useQuery(["PVIEW_", planId], () =>
        api
            .post<Exercise[]>("/charge/history/get", {
                plan: planId,
            })
            .then((res) => res.data)
            .catch((err) => {
                alert(err)
                return []
            })
    )

    if (isLoading || !data) {
        return isLoading ? <ThreeDots /> : <p>Erro!</p>
    } else {
        return (
            <>
                {data.map((e, i) => (
                    <DataWrapper key={i + "_prog"}>
                        <div
                            id={e.id === highlight ? "highlight" : ""}
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                style={
                                    e.id === highlight
                                        ? { fontWeight: "bold" }
                                        : {}
                                }
                            >
                                {e.exercise_name}
                            </h3>
                            {e.id === highlight && (
                                <IoChatboxEllipses
                                    className="pulse"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </div>
                        <HistoryWrapper
                            data={e.exercise_data}
                            highlited={e.id === highlight}
                        />
                    </DataWrapper>
                ))}
            </>
        )
    }
}

export default ProgressionView
