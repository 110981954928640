import React from "react"
import { Wrapper } from "../crossfit/styles"
import { Area, AreaContainer, Content } from "../crossfit/styles"
import { WorkoutsCommons } from "src/modules/protocols/interfaces"
import { Heating, Workout } from "src/modules/fithub-shared/interfaces/workout"
import { usePlan } from "src/modules/protocols/context"

const HeatingComp: React.FC<{ content: Heating }> = (props) => {
    const planContext = usePlan()

    function _onBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        const workout = { ...planContext.workout.selected } as Workout
        const current = workout.item as Heating
        current.instructions = e.target.value
        planContext.workout.update(workout)
    }

    return (
        <Wrapper>
            <Content>
                <p>Informações para Aquecimento</p>
                <AreaContainer>
                    <Area
                        placeholder="Digite aqui as informações..."
                        defaultValue={props.content.instructions}
                        onBlur={_onBlur}
                    />
                </AreaContainer>
            </Content>
        </Wrapper>
    )
}

export default HeatingComp
