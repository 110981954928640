import styled from "styled-components"

interface Props {
    radius?: string
    error?: boolean
}

export const Area = styled.div<Props>`
    flex-direction: row;

    height: 35px;

    @media (max-width: 768px) {
        height: 40px;
    }

    justify-content: center;
    align-items: center;

    border-radius: ${(props) => (props.radius ? props.radius : "5px")};
    border: solid 1.2px #0003;

    background-color: #fff;

    p {
        all: unset;
        text-align: left;
        font-size: 12px;
        border: 0;
        outline: 0;
        background-color: transparent;
        width: 100%;
        padding-bottom: 1px;
        opacity: 0.75;

        color: #444;
    }

    input {
        all: unset;
        text-align: left;
        font-size: 14px;
        border: 0;

        background-color: transparent;
        height: 100%;
        width: 100%;
        padding-bottom: 1px;

        padding-left: 5px;
    }

    img {
        filter: invert(15%);
        margin: 6px;
        height: 15px;
        width: 15px;
    }
`
