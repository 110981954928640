import React from "react"
import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Line = styled.span`
    flex: 1;
    background-color: #bbb;
    height: 2px;
    min-width: 10px;
`

const Text = styled.p`
    padding: 7px;
    padding-top: 8px;
    border: 2px solid #bbb;
    color: #bbb;
    font-weight: bold;
    aspect-ratio: 1;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
`

const Divider: React.FC = () => {
    return (
        <Container style={{ marginBlock: 20 }}>
            <Line />
            <Text>OU</Text>
            <Line />
        </Container>
    )
}

export default Divider
