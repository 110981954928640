import React from "react"

import { Card, Container } from "./styles"

const Checkout: React.FC = () => {
    return (
        <Container className="flex">
            <Card>
                <h2>Pagamento</h2>
            </Card>
        </Container>
    )
}

export default Checkout
