import React, { useContext } from "react"
import {
    Avatar,
    CTAButton,
    Header,
    TextWrapper,
    UserInfo,
} from "../../Plan/View/protocols-listing/styles"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "src/components/mutable-icon"
import { useNavigate } from "react-router-dom"

import dummy from "../../../../assets/png/userd-demo-pic.png"
import StudentContext from "src/contexts/student"
import { BiCloudUpload } from "react-icons/bi"
import { MdAdd } from "react-icons/md"

// import { Container } from './styles';

const StudentInfoHeader: React.FC<{
    children?: React.ReactElement
    create?: () => void
}> = ({ children, create }) => {
    const navigate = useNavigate()

    const context = useContext(StudentContext)

    return (
        <Header>
            {!create && context.student ? (
                <>
                    <UserInfo>
                        <div style={{ alignItems: "center" }}>
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={40}
                                onClick={() => navigate(-1)}
                            />
                            <Avatar src={dummy} />
                        </div>

                        <TextWrapper>
                            <h1>{context.student.name}</h1>
                            <h5>PLANOS DE TREINOS</h5>
                        </TextWrapper>
                    </UserInfo>
                </>
            ) : (
                <>
                    <UserInfo style={{ justifyContent: "space-between" }}>
                        <div style={{ alignItems: "center" }}>
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={40}
                                onClick={() => navigate(-1)}
                            />
                            <BiCloudUpload
                                style={{
                                    backgroundColor: "#0001",
                                    borderRadius: "50%",
                                    padding: 10,
                                    marginRight: 10,
                                }}
                                size={48}
                            />
                        </div>

                        <TextWrapper>
                            <h1>TEMPLATES SALVOS</h1>
                            <h5>PLANOS DE TREINOS</h5>
                        </TextWrapper>

                        {create && (
                            <CTAButton onClick={create}>
                                <MdAdd />
                                <h4>CRIAR</h4>
                            </CTAButton>
                        )}
                    </UserInfo>
                </>
            )}
            {children && children}
        </Header>
    )
}

export default StudentInfoHeader
