import styled from "styled-components"

export const Header = styled.div`
    width: 100%;
    padding: 10px;
    height: 80px;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 10px;

    background-color: #eee;
`

export const Labels = styled.div`
    flex-direction: column;
    flex: 1;
    padding-inline: 8px;

    max-width: 300px;

    p {
        font-weight: 600;
    }
`

export const TypeSelection = styled.div`
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        text-transform: uppercase;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 24px;
    }

    button {
        width: 200px;
        height: 40px;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        font-size: 20px;

        background-color: #444;
        color: white;
        font-weight: bold;
        border-radius: 5px;

        cursor: pointer;
    }
`

export const ObsWindow = styled.div`
    width: 100%;
    padding: 10px;
    flex-direction: column;
    p {
        text-transform: uppercase;
        color: #444;
        font-weight: bold;
        font-size: 14px;
    }

    textarea {
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        border-radius: 5px;
        padding: 5px;
        background-color: transparent;
        margin-top: 10px;

        border: 1px solid #aaa;
    }
`

export const ModText = styled.button<{ modified: boolean }>`
    background-color: #ddd;
    color: ${(p) => (p.modified ? "#000" : "#666")};
    padding: 2px 5px;

    cursor: ${(p) => (p.modified ? "pointer" : "default")};

    font-size: 12px;
    border-radius: 25px;
    align-items: center;
`

export const UndoTxt = styled.p`
    border-bottom: 1px dotted black;
    padding-top: 5px;

    font-size: 10px;
    align-items: center;
`
