import styled from "styled-components"

export const PanelContent = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;

    justify-content: space-between;
    align-items: center;

    width: 85%;
    max-width: 800px;
`

export const RegisterForm = styled.div`
    flex-grow: 1;
    max-width: 100%;
    min-width: 200px;
    flex-direction: column;
    width: 40%;

    text-align: center;

    h3 {
        font-weight: bold;
        font-size: 14px;
    }

    p {
        font-size: 12px;
    }

    > span {
        margin: 4px;
    }
`
