import styled from "styled-components"

export const StudentWrapper = styled.div`
    flex: 1;
    width: 100%;

    @media (min-width: 768px) {
        padding: 15px;
        justify-content: center;
        > div {
            max-width: 700px;
        }
    }

    overflow-x: hidden;
    overflow-y: auto;

    background-color: var(--panel);
    border-radius: 5px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const ConfigButton = styled.button`
    padding-inline: 5px;
    align-items: center;

    height: 30px;
    width: 30px;

    border-radius: 50%;

    background-color: transparent;

    cursor: pointer;

    margin-left: 10px;

    background-color: white;

    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

    > * {
        transition: transform 0.2s;
    }

    overflow: clip;

    :hover {
        opacity: 0.9;
        > * {
            transform: scale(1.3);
        }
    }

    :active {
        opacity: 0.7;
    }
`

export const Column = styled.div<{ maxWidth?: string; forceSize?: boolean }>`
    flex-direction: column;

    width: 100%;
    ${(p) => p.forceSize && "min-height: calc(100vh - 120px);"}
`

export const Avatar = styled.img`
    height: 65px;
    @media (max-width: 768px) {
        height: 40px;
    }
    margin-right: 10px;
    aspect-ratio: 1;
    border-radius: 50px;
`

export const BasicInfo = styled.div`
    flex-shrink: 0;
    background-color: #fff;

    @media (min-width: 768px) {
        border-radius: 4px;

        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    }

    height: 80px;
    align-items: center;
    p {
        font-weight: bold;
    }

    width: 100%;
`

export const AditionalInfo = styled.div`
    flex-direction: row;
    justify-content: space-between;

    height: 40px;
    width: 100%;

    margin-top: 20px;

    span {
        background-color: #0004;
        width: 1px;
        height: 100%;
    }

    div {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h5 {
        font-weight: bold;
    }
`

export const AddNoteButton = styled.button`
    background-color: var(--font);
    color: white;

    padding: 8px;
    border-radius: 5px;

    :hover {
        opacity: 0.8;
    }

    :active {
        opacity: 0.7;
    }

    align-items: center;

    font-size: 26px;

    height: 30px;
    aspect-ratio: 1;

    cursor: pointer;
`

export const Note = styled.li`
    flex: 1;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    padding: 5px;

    @media (min-width: 768px) {
        border: 1px solid #0002;

        :not(:nth-child(1)) {
            border-top: none;
        }

        :not(:nth-last-child(2)) {
            border-bottom: 1px dashed #0002;
        }

        :nth-child(1) {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        :nth-last-child(2) {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    @media (max-width: 768px) {
        background-color: #fff;
        min-width: calc(100vw - 107px);

        :not(:nth-last-child(2)) {
            border-right: 1px solid #0002;
        }
    }

    p {
        padding-bottom: 4px;
        width: 100%;
        font-weight: bold;
        color: #444;
    }

    textarea {
        flex: 1;
        height: 200px;
        padding: 5px;
        width: 100%;
        resize: none;

        border: none;
        vertical-align: middle;
        font-size: 16px;

        :focus {
            outline: 1px solid #0002;
            background-color: #0001;
        }
    }
`

export const Notes = styled.div`
    flex-direction: column;

    align-items: center;

    overflow-x: hidden;
    overflow-y: auto;

    height: 100%;
    width: 100%;

    min-height: 300px;

    margin-top: 20px;
    padding: 5px;

    > ul {
        flex: 1;
        width: 100%;
        flex-direction: column;
    }

    h4 {
        margin: 0px;
        padding: 0px;
        font-weight: 800;
        margin-left: 10px;
    }

    div {
        margin: 10px;
    }

    border-radius: 5px;

    @media (min-width: 768px) {
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }

    @media (max-width: 768px) {
        align-items: start;
        overflow-x: auto;

        > ul {
            flex-direction: row;
            textarea {
                min-width: 300px;
            }
        }
    }
`

export const ButtonsLayout = styled.div`
    flex: 0.6;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        padding: 10px;
        width: 100%;
    }
`

export const NotificationsLayout = styled.ul`
    flex: 1;

    > div {
        padding: 10px;
        flex-direction: column;
        align-items: center;
        flex: 1;
        width: 100%;

        border-radius: 5px;

        > ul {
            flex: 0.4;
            width: 100%;
            flex-direction: column;
            align-items: center;

            overflow-y: auto;

            border-bottom: 1px solid #0002;
        }
    }
`
