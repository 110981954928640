import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex: 1;

    background-color: var(--panel);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ccc;
`

export const ProtocolsListing = styled.div`
    flex: 1;
    overflow: hidden;
    width: 450px;
    min-width: 450px;
    max-width: 450px;

    flex-direction: column;
    //background-color: var(--panel);
    border-right: 1px solid #ccc;

    padding: 5px;

    @media (max-width: 800px) {
        flex: 1;
        max-width: 100%;
        border-right: none;
        border-bottom: 1px solid #ccc;
    }
`

export const VerticalScroll = styled.div`
    flex: 1 1 auto;
    height: 0px;
    flex-direction: column;

    width: 100%;

    overflow-y: auto;

    > h5 {
        height: 100%;
        vertical-align: middle;
        background-color: #000;
    }
`

export const EmptyList = styled.div`
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    p {
        background-color: #ddd;
        text-align: center;
        border-radius: 10px;
        padding: 5px;
        margin-top: 10px;
    }
`

export const Header = styled.div`
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const CTAButton = styled.div`
    background-color: var(--primary);
    padding: 8px;
    flex: 0;
    width: 90px;

    align-items: center;

    color: white;

    cursor: pointer;

    margin-left: 5px;

    :hover {
        opacity: 0.7;
    }

    color: white;

    border-radius: 50px;
`

export const TextWrapper = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;

    justify-content: space-between;

    h1 {
        display: flex;
        flex: 1;
        min-width: 0;
        overflow: auto;
        min-height: 12px;
        font-size: 14px;
        font-weight: 800;
    }

    h5 {
        display: flex;
        flex: 1;
        min-width: 0;
        overflow: auto;
        font-weight: 400;
        color: #aaa;
    }
`

export const UserInfo = styled.div`
    flex-direction: row;
    align-items: center;
    margin-block: 10px;
    width: 100%;
`

export const Avatar = styled.img`
    height: 48px;
    min-height: 48px;
    aspect-ratio: 1;
    transition: height 0.25s;
    margin-right: 10px;
    border-radius: 50px;
`

export const ProtocolContent = styled.div`
    flex: 1;
    flex-direction: column;

    padding: 5px;
    border-radius: 5px;
    width: 50vw;
    min-width: 300px;
    //background-color: #0001;
`

export const HeaderButton = styled.button`
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    //border: dotted var(--primary) 1px;
    border-radius: 8px;
    background-color: #ddd;
    height: 50px;

    cursor: pointer;

    :hover {
        opacity: 0.75;
    }

    :active {
        opacity: 0.5;
    }
    color: #444;
    p {
        font-weight: 600;
        text-transform: uppercase;

        size: 16px;
    }
`
