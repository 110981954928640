import styled from "styled-components"

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #444;
    padding: 2px;
    border-radius: 5px;
`

export const Item = styled.button`
    all: unset;
    display: flex;
    min-width: 100px;
    color: white;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px;

    :not(:last-child) {
        border-bottom: 1px solid #7a7a7a;
    }

    :first-child {
        border-radius: 5px 5px 0px 0px;
    }

    :last-child {
        border-radius: 0px 0px 5px 5px;
    }

    :hover {
        opacity: 0.5;
        background-color: #000;
    }
`
