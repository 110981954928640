import { NewEvent } from "./EventInfo"

export const pallete = [
    "#8e24aa",
    "#7986cb",
    "#3f51b5",
    "#039be5",
    "#e67c73",
    "#d50000",
    "#f4511e",
    "#f6bf26",
    "#33b679",
    "#0b8043",
    "#616161",
]

//rgb(213, 0, 0) //rgb(230, 124, 115)
//rgb(244, 81, 30) //rgb(246, 191, 38)
//rgb(51, 182, 121) //rgb(11, 128, 67)
//rgb(3, 155, 229)//rgb(63, 81, 181)
//rgb(121, 134, 203)//rgb(142, 36, 170)
//rgb(97, 97, 97)
export function getContrast(hexColor: string): string {
    // Remove "#" do início se estiver presente
    hexColor = hexColor.replace(/^#/, "")

    // Converter para RGB
    const r = parseInt(hexColor.substr(0, 2), 16)
    const g = parseInt(hexColor.substr(2, 2), 16)
    const b = parseInt(hexColor.substr(4, 2), 16)

    // Calcular o coeficiente de luminância
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

    // Determinar se o texto deve ser branco ou preto com base na luminância
    return luminance > 0.5 ? "#000000" : "#ffffff"
}

export const EventColor = (myEventsList: NewEvent) => {
    const backgroundColor = myEventsList.color ? myEventsList.color : pallete[3]
    const color = getContrast(backgroundColor)
    return { style: { backgroundColor, color, fontSize: 12 } }
}
