import React, { useContext, useState } from "react"
import { BiFingerprint, BiMobile } from "react-icons/bi"
import { MdPerson, MdEmail, MdPersonAdd } from "react-icons/md"
import Inputfield from "../../../components/inputfield"
import { AddForm, CreateButton, ListHeader } from "../styles"
import {
    identityMask,
    phoneMask,
} from "../../../modules/fithub-shared/functions/functions"
import mostrarAlerta from "../../../components/utils/alert"
import { RiInstagramFill } from "react-icons/ri"
import api from "../../../API/rest"
import { Student } from "../../student-info"
import AuthContext from "src/contexts/auth"
import MaskInput from "src/components/inputfield/masked-input"

import Step from "./Step"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "src/components/mutable-icon"
import { ValidateEmail } from "src/assets/functions"
import { useDialog } from "src/contexts/dialog-context"

export interface FoundUser {
    id: string
    name: string
    phone: string
}

const CreateForm: React.FC<{
    onGoBack(): void
    onAdd: (s: Student) => void
}> = (props) => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        birthdate: "",
        gender: "Male",
        phone: "",
        identity: "",
        username: "",
    })

    //optionals

    const [found, setFound] = useState<FoundUser | undefined>(undefined)

    const [validEmail, setValidEmail] = useState(false)

    const auth = useContext(AuthContext)

    async function LocateUser(s: string) {
        setFound(undefined)

        if (s.length < 5) return
        await api
            .post("/students/find", { email: s })
            .then((res) => {
                if (res.status === 201) setFound(res.data as FoundUser)
            })
            .catch(() => setFound(undefined))
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target

        if (name === "email") {
            LocateUser(value)
            setValidEmail(ValidateEmail(value))
        }

        setForm({
            ...form,
            [name]: value,
        })
    }

    const handleGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
        mostrarAlerta(e.target.value)

        setForm({
            ...form,
            gender: e.target.value,
        })
    }

    async function Submit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (found) {
            api.post("/register/link", {
                id: found?.id,
                trainer_id: auth.user?.id,
            })
                .then((res) => {
                    props.onAdd(res.data as Student)
                    mostrarAlerta("Aluno Vinculado!")
                })
                .catch(() => mostrarAlerta("Este aluno já está vinculado!"))
            return
        }

        await api
            .post("register/", {
                ...form,
                birthdate: form.birthdate,
                trainer_id: auth.user?.id,
            })
            .then((res) => {
                if (res.status === 201) {
                    props.onAdd(res.data as Student)
                    mostrarAlerta(`Aluno(a) Cadastrado!`)
                } else {
                    mostrarAlerta(`Aluno(a) Cadastrado!`)
                }
            })
    }

    const diag = useDialog()

    function UnsavedDialog() {
        diag.openDialog(
            "Cancelar Cadastro",
            "O Cadastro atual ainda não foi salvo! Gostaria de cancelar mesmo assim?",
            props.onGoBack
        )
    }

    return (
        <>
            <ListHeader style={{ paddingBlock: 10 }}>
                <MutableIcon
                    icon={HiChevronLeft}
                    size={40}
                    onClick={UnsavedDialog}
                />
            </ListHeader>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 20,
                    justifyContent: "space-between",
                }}
            >
                <AddForm onSubmit={Submit}>
                    <p
                        style={{
                            padding: 10,
                            fontSize: 14,
                        }}
                    >
                        DADOS CADASTRAIS:
                    </p>
                    <div
                        style={{
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Inputfield
                            name="email"
                            error={true}
                            onChange={handleInputChange}
                            tabIndex={1}
                            icon={MdEmail}
                            width={"60%"}
                            placeholder="Email"
                        />
                        <p>(Acesso do Aluno)</p>
                    </div>
                    <span />
                    <Step active={validEmail} found={found}>
                        <>
                            <p
                                style={{
                                    borderTop: "1px solid #ccc",
                                    padding: 10,
                                    fontSize: 14,
                                }}
                            >
                                DADOS PESSOAIS:
                            </p>

                            <div>
                                <Inputfield
                                    name="name"
                                    onChange={handleInputChange}
                                    tabIndex={2}
                                    minLength={3}
                                    icon={MdPerson}
                                    placeholder="Nome"
                                    style={{ flex: 1 }}
                                />

                                <span />

                                <div style={{ alignItems: "center" }}>
                                    <p
                                        style={{
                                            paddingInline: 10,
                                            fontSize: 12,
                                        }}
                                    >
                                        Nascimento:
                                    </p>
                                    <Inputfield
                                        name="birthdate"
                                        placeholder="Nascimento"
                                        tabIndex={3}
                                        type="date"
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <span />
                            <div>
                                <select tabIndex={4} onChange={handleGender}>
                                    <option disabled>Sexo Biológico</option>
                                    <option value={"Male"}>♂️ Masculino</option>
                                    <option value={"Female"}>
                                        ♀️ Feminino
                                    </option>
                                </select>
                                <span />

                                <MaskInput
                                    name="phone"
                                    tabIndex={5}
                                    icon={BiMobile}
                                    placeholder="+xx xx xxxx-xxxx"
                                    onChange={handleInputChange}
                                    mask={phoneMask}
                                    width={"50%"}
                                />
                                <span />
                                <MaskInput
                                    name="identity"
                                    tabIndex={6}
                                    icon={BiFingerprint}
                                    placeholder="CPF ou Identificação"
                                    onChange={handleInputChange}
                                    mask={identityMask}
                                    width={"50%"}
                                />
                            </div>

                            <span />
                            <p
                                style={{
                                    borderTop: "1px solid #ccc",
                                    padding: 10,
                                    fontSize: 14,
                                }}
                            >
                                OPCIONAL:
                            </p>
                            <div>
                                <Inputfield
                                    name="username"
                                    tabIndex={7}
                                    icon={RiInstagramFill}
                                    placeholder="Instagram"
                                    width={"70%"}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </>
                    </Step>

                    <CreateButton type="submit">
                        <MdPersonAdd />
                        <p>{found ? "VINCULAR" : "CADASTRAR"} ALUNO</p>
                    </CreateButton>
                </AddForm>
            </div>
        </>
    )
}

export default CreateForm
