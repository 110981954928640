import React, { useEffect, useRef, useState } from "react"
import { ModalContent, Wrapper } from "./styles"
import { useOnOutClick } from "../hooks"
import ReactDOM from "react-dom"

const Modal: React.FC<{
    active: boolean
    state: (active: boolean) => void
    children?: React.ReactNode
}> = (props) => {
    const root = document.getElementById("root")

    const ref = useRef<HTMLDivElement>(null)

    useOnOutClick(ref, () => props.state(false))

    if (root && props.active)
        return ReactDOM.createPortal(
            <ModalContent className="fade-in">
                <Wrapper ref={ref} className={"shadow"}>
                    {props.children}
                </Wrapper>
            </ModalContent>,
            root
        )
    else return <></>
}

export default Modal
