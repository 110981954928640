import React, { useState } from "react"
import { Background, Avatar, Labels, HeaderContent } from "./styles"

import demoPic from "src/assets/png/userd-demo-pic.png"

import IconButton from "../icon-button"

import MutableIcon from "../mutable-icon"
import { HiOutlineMenuAlt2 } from "react-icons/hi"
import { userData } from "src/assets/caching/user-cache"
import DrawerMenu from "../DrawerMenu"
import FithubLogo from "src/assets/svg/FithubLogo"
import { RiSettingsLine } from "react-icons/ri"
import { MdHelpOutline } from "react-icons/md"

import { ReactComponent as Logo } from "src/assets/svg/fh-logo.svg"

const Header: React.FC<userData> = (props) => {
    const small = window.innerWidth < 800
    const [drawer, setDrawer] = useState(false)

    return (
        <Background small={small}>
            <HeaderContent>
                <div>
                    <Avatar small={small} src={props.photourl ?? demoPic} />
                </div>

                <Labels>
                    <h5>{props.name}</h5>
                    <p>{props.name_pj}</p>
                </Labels>
            </HeaderContent>

            <Logo
                fill="#444"
                style={{
                    height: "60%",
                    maxWidth: 100,
                }}
            />

            <HeaderContent style={{ flexDirection: "row-reverse" }}>
                <IconButton src={RiSettingsLine} />
                <span style={{ margin: 10 }} />
                <IconButton src={MdHelpOutline} />
            </HeaderContent>
        </Background>
    )
}
export default Header
