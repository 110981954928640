import styled from "styled-components"

export const AnamneseSettingsBackground = styled.div`
    flex: 1;
    margin: 10px;
    padding: 10px;
    background-color: #ddd;
    justify-content: center;
    align-items: center;

    span {
        margin-top: 5px;
    }
`

export const OptionsPanel = styled.div`
    flex: 1;
    width: 50%;
    max-width: 500px;
    padding: 5px;

    flex-direction: column;
`

export const AnamneseItem = styled.div`
    height: 100%;
    aspect-ratio: 1;

    border-radius: 5px;

    margin-right: 10px;

    border: 1px solid #ccc;
`

export const ItemLabel = styled.button`
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px;

    > div + div {
        margin-bottom: 10px;
    }
`

export const GoBackButton = styled.div`
    font-weight: bold;
    font-size: 16px;
    padding: 10px;

    width: 100%;

    text-transform: uppercase;

    background-color: #fff;
    align-items: center;
    justify-content: center;

    p {
        font-weight: bold;
    }

    cursor: pointer;
`

export const SelectableItem = styled.div`
    flex-direction: column;

    min-height: 40px;
    padding: 10px;

    justify-content: space-between;

    background-color: #fff;

    margin-bottom: 15px;

    h4 {
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    p {
        text-transform: uppercase;
        color: #000;
        font-size: 12px;
    }

    overflow: hidden;
`
