import styled from "styled-components"

export const StepWrapper = styled.div<{ active?: boolean }>`
    flex: 1;
    flex-direction: column;

    opacity: ${(p) => (p.active ? "1" : "0.5")};

    ${(p) => !p.active && "pointer-events: none;"}
`

export const FoundWrap = styled.div`
    display: flex;
    flex: 0.7;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    P {
        font-weight: bold;
    }
`

export const UserInformation = styled.div`
    width: 100%;
    border: 1px solid #ccc;
    flex-direction: row;

    padding: 10px;

    border-radius: 50px;

    align-items: center;

    > div {
        flex-direction: column;

        margin-left: 20px;
    }
`

export const Avatar = styled.img`
    width: 50px;
    height: 50px;
    aspect-ratio: 1;
`
