import React from "react"
import {
    ExerciseFields,
    ExerciseValue,
} from "src/modules/fithub-shared/interfaces/exercise"
import DragInput from "../DragInput"

import * as ExerciseData from "src/modules/fithub-shared/data"

interface ColumnProps {
    name: ExerciseFields
    isDragging: boolean
    enableSelectTab?: boolean
    default: ExerciseValue
    series?: number
    maxNums: number
    onChange: (e: ExerciseValue, field: ExerciseFields) => void
}

const Column2: React.FC<ColumnProps> = (props) => {
    const cell = ExerciseData.getData(props.name)

    function onChange(value: ExerciseValue) {
        props.onChange(value, props.name)
    }

    return (
        <div style={{ flex: 1 }} className="flex row">
            <div style={{ flex: 1 }} className="column">
                <DragInput
                    cell={cell}
                    dragging={props.isDragging}
                    inputProps={{
                        style: { flex: 1 },
                        tabIndex: 1,
                        placeholder: props.name,
                    }}
                    series={props.series}
                    onChange={onChange}
                    defaultValues={props.default}
                    mnax={"series"}
                    maxNums={props.maxNums}
                    enableSelectTab={props.enableSelectTab ?? false}
                />
            </div>
        </div>
    )
}

export default Column2
