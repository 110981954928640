import React from "react"
import { MdError } from "react-icons/md"

// import { Container } from './styles';

const LoadErrorMessage: React.FC<{ error: unknown; refetch(): void }> = ({
    error,
    refetch,
}) => {
    return (
        <div
            style={{
                flexDirection: "column",
                justifyContent: "center",
                flex: 1,
            }}
        >
            <div style={{ flexDirection: "column", alignItems: "center" }}>
                <MdError color="red" />
                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                    Ocorreu um erro ao carregar a lista!...
                </p>
                <p>
                    Tente novamente, se o erro persistir, reporte no Grupo de
                    Testadores!
                </p>
                <br />
                <div
                    style={{
                        flexDirection: "column",
                        backgroundColor: "#ddd",
                        padding: 8,
                        borderRadius: 5,
                        width: "100%",
                        border: "1px solid #777",
                    }}
                >
                    <p>Informações:</p>
                    <p>{JSON.stringify(error)}</p>
                </div>
                <br />
                <button
                    style={{
                        cursor: "pointer",
                        padding: 6,
                        borderRadius: 5,
                    }}
                    onClick={() => refetch()}
                >
                    RECARREGAR
                </button>
            </div>
        </div>
    )
}

export default LoadErrorMessage
