import React from "react"
import { IconType } from "react-icons"
import { IconButton } from "./styles"

// import { Container } from './styles';

export interface mutableIcon {
    icon: IconType
    size?: number
    tabIndex?: number
    color?: string
    disabled?: boolean
    onClick?: () => void
}

const MutableIcon: React.FC<mutableIcon> = (props) => {
    if (props.icon === undefined) {
        return <></>
    } else if (props.disabled || props.onClick === undefined) {
        return (
            <div
                style={{
                    backgroundColor: "transparent",
                    opacity: props.disabled ? 0.5 : 1,
                }}
            >
                <props.icon color={props.color} size={props.size} />
            </div>
        )
    } else {
        return (
            <IconButton tabIndex={props.tabIndex} onClick={props.onClick}>
                <props.icon color={props.color} size={props.size} />
            </IconButton>
        )
    }
}

export default MutableIcon
