import React from "react"
import styled from "styled-components"

// import { Container } from './styles';

interface GoogleButtonProps {
    title: string
    onClick(): void
}

const Button = styled.button`
    border-radius: 50px;
    background-color: #1d1d1d;
    color: white;

    cursor: pointer;

    align-items: center;

    padding: 5px;

    img {
        height: 30px;
        width: 30px;
        border-radius: 50px;
        margin-right: 10px;
        aspect-ratio: 1;
    }

    p {
        margin-right: 5px;
    }

    transition: background-color 0.1s;

    :active {
        background-color: #333;
    }
`

const GoogleButton: React.FC<GoogleButtonProps> = (props) => {
    return (
        <Button onClick={props.onClick}>
            <img src={require("../../../assets/png/google.jpg")} />
            <p>{props.title}</p>
        </Button>
    )
}

export default GoogleButton
