import React, { useState } from "react"

function useCountdownTimer(initialCountdown: number) {
    const [countdown, setCountdown] = useState<number>(0)
    const [intervalActive, setIntervalActive] = useState<boolean>(false)

    React.useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined

        if (countdown === 0) {
            clearInterval(intervalId)
            setIntervalActive(false)
        } else {
            setIntervalActive(true)
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 0) {
                        clearInterval(intervalId)
                        setIntervalActive(false)
                        return 0
                    }
                    return prevCountdown - 1
                })
            }, 1000)
        }

        return () => clearInterval(intervalId)
    }, [countdown])

    const startCountdown = () => {
        setCountdown(initialCountdown)
        setIntervalActive(true)
    }

    return { countdown, intervalActive, startCountdown }
}

export default useCountdownTimer
