import React, { useState, useRef, useEffect } from "react"
import moment from "moment"
import { FaCalendarDay } from "react-icons/fa"
import styled from "styled-components"
import { HiX } from "react-icons/hi"
import { parseDateTimeLocal } from "src/modules/protocols/functions/string"

interface DateTimePickerProps {
    minDate: Date
    defaultValue: Date | null
    onChange: (date: Date | null) => void
}

const Picker = styled.button`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #ddd;
    border-radius: 50px;

    padding: 1px;
    padding-left: 5px;

    color: black;

    border: 1px solid #ddd;

    input {
        position: absolute;
        max-width: 0px;
        max-height: 0px;
        right: 30px;
        user-select: none;
        opacity: 0;
    }

    p {
        color: inherit;
        margin: 0px;
        background-color: transparent;
    }
`

const CircleButton = styled.button<{ add: boolean }>`
    position: relative;
    height: 29px;
    width: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    border: none;

    padding-top: 1px;

    margin-left: 2.5px;

    color: #444;

    transition: border 0.2s, color 0.2s, transform 0.2s;

    border: 1px solid white;

    :not(:disabled) {
        cursor: pointer;
    }

    :hover:not(:disabled) {
        border: 1px solid ${(p) => (p.add ? "green" : "red")};
        color: ${(p) => (p.add ? "green" : "red")};
        transform: scale(1.2);
    }
`

const roundToNearestQuarterHour = (date: Date): Date => {
    const minutes = date.getMinutes()
    const roundedMinutes = Math.round(minutes / 15) * 15
    date.setMinutes(roundedMinutes)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
    minDate,
    defaultValue,
    onChange,
}) => {
    const [date, setDate] = useState<Date | null>(defaultValue)
    const [min, setMin] = useState<Date | null>(minDate)
    const inputRef = useRef<HTMLInputElement & { showPicker(): void }>(null)

    useEffect(() => {
        setMin(minDate ?? new Date())
    }, [minDate])

    useEffect(() => {
        setDate(defaultValue)
    }, [defaultValue])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value != null) {
            const selectedDate = new Date(value)
            const roundedDate = roundToNearestQuarterHour(selectedDate)
            setDate(roundedDate)
            onChange(roundedDate)
        } else {
            setDate(null)
            onChange(null)
        }
    }

    const open = () => {
        if (inputRef.current) {
            inputRef.current.showPicker() // Foca no input para abrir o modal de data/hora
        }
    }

    const remove = () => {
        if (date) {
            setDate(null)
            onChange(null)
        }
    }

    const formatDate = (date: Date | null) => {
        if (!date) return <p>SELECIONE UMA DATA</p>
        return (
            <>
                <p>{moment(date).format("D [de] MMMM, ddd")}</p>

                <p
                    style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        paddingLeft: 5,
                    }}
                >
                    {moment(date).format("HH:mm")}
                </p>
            </>
        )
    }

    return (
        <Picker>
            <div>{formatDate(date)}</div>

            <CircleButton
                add={date === null}
                onClick={date == null ? open : remove}
            >
                {date ? <HiX /> : <FaCalendarDay />}
            </CircleButton>

            <input
                ref={inputRef}
                type="datetime-local"
                defaultValue={parseDateTimeLocal(date)}
                min={min?.toISOString().slice(0, 16)}
                onChange={handleChange}
                style={{
                    appearance: "none",
                }}
            />
        </Picker>
    )
}

export default DateTimePicker
