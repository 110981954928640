import styled from "styled-components"

export const List = styled.ul`
    flex-direction: column;
    padding: 5px;

    width: 100%;
`

export const Item = styled.li`
    padding: 10px;
    border: 1px solid var(--panel);
    margin-bottom: 5px;

    align-items: center;

    cursor: pointer;

    width: 100%;

    border-radius: 4px;

    justify-content: space-between;

    :active {
        background-color: var(--panel);
    }

    > div {
        flex-direction: column;

        h5 {
            font-weight: bold;
        }
    }
`

export const DataWrapper = styled.div`
    flex-direction: column;
    padding: 15px;
    margin-bottom: 5px;

    width: 100%;

    > div {
        flex-direction: column;

        h5 {
            font-weight: bold;
        }
    }

    @keyframes pulseanim {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2) rotateZ(30deg);
        }
        100% {
            transform: scale(1);
        }
    }

    .pulse {
        animation: pulseanim 2s infinite;
    }

    :nth-child(even) {
        background-color: #f8f8f8;
    }

    :not(:last-child) {
        > span {
            border-bottom: 1px dashed #aaa;
        }
    }
`
