import styled from "styled-components"

export const Backdrop = styled.div`
    display: flex;
    position: absolute;
    z-index: 1;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    justify-content: center;
    align-items: center;
    padding: 10px;

    background-color: #0008;
    width: 100vw;
    height: 100vh;
`

export const Panel = styled.div`
    background-color: #fff;

    padding: 5px;

    height: 100vh;
    width: 100vw;

    max-height: 600px;
    max-width: 600px;

    border: 1px solid #ddd;
`
