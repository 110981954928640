import React, { useContext, useEffect, useState } from "react"
import { FiUser } from "react-icons/fi"
import { MdCake, MdEmail, MdOutlineFingerprint, MdPhone } from "react-icons/md"
import Inputfield from "src/components/inputfield"
import {
    Logo,
    Msg,
    ButtonsWrapper,
    LoginButton,
    Container,
} from "../auth-page/styles"
import { ThreeDots } from "react-loader-spinner"
import AuthContext from "src/contexts/auth"
import logo from "../../../assets/svg/fh-logo.svg"
import { useNavigate } from "react-router-dom"
import MaskInput from "src/components/inputfield/masked-input"
import { PanelContent, RegisterForm } from "./styles"
import { BiBuilding, BiChevronDown } from "react-icons/bi"
import api from "src/API/rest"
import {
    birthdateMask,
    cpfMask,
    phoneMask,
} from "src/modules/fithub-shared/functions/functions"
import { TbIdBadge } from "react-icons/tb"
import moment from "moment"
import { ValidateEmail, ValidatePass } from "src/assets/functions"
import mostrarAlerta from "src/components/utils/alert"
import styled from "styled-components"
import { User } from "firebase/auth"

// import { Container } from './styles';

const Email = styled.div`
    border-radius: 50px;
    background-color: #1d1d1d;
    color: white;

    align-items: center;

    padding: 5px;

    img {
        height: 100%;
        width: 30px;
        border-radius: 50px;
        margin-right: 10px;
        aspect-ratio: 1;
    }

    margin-bottom: 10px;
`

const Register: React.FC = () => {
    const [registering, setRegistering] = useState(false)

    const context = useContext(AuthContext)

    const nav = useNavigate()

    const [form, setForm] = useState({
        name: context.GSignIn.user?.displayName ?? "",
        phone: context.GSignIn.user?.phoneNumber ?? "",
        identity: "",
        birthdate: "",
        email: context.GSignIn.user?.email ?? "",
        name_pj: "",
        password: "",
    })

    function handleForm(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target

        setForm({
            ...form,
            [name]: value,
        })
    }

    async function handleRegister() {
        if (!ValidateEmail(form.email) || !ValidatePass(form.password)) return

        setRegistering(true)

        await api
            .post("/register/trainer", {
                ...form,
                photourl: context.GSignIn.user?.photoURL ?? null,
                birthdate: moment(form.birthdate, "DDMMYYYY").format(
                    "YYYY-MM-DD"
                ),
            })
            .then((res) => {
                setRegistering(false)

                if (res.status === 201) {
                    context.Login(form.email, form.password).then(() => {
                        nav("/")
                    })
                } else mostrarAlerta(res.data)
            })
            .catch((err) => {
                setRegistering(false)
                alert(err)
            })
    }

    const EnterLabel = () => {
        if (registering)
            return (
                <ThreeDots
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            )
        else return <p>REGISTRAR</p>
    }

    return (
        <Container
            className="flex column"
            style={{ flex: 1, backgroundColor: "#444", height: "100vh" }}
        >
            <PanelContent>
                <RegisterForm style={{ alignItems: "center" }}>
                    <Logo src={logo} />
                    <h3>Bem vindo à Fithub!</h3>
                    <br />
                    <p>
                        A Fithub é uma plataforma desenvolvida para personal
                        trainers e consultores esportivos, criada por
                        profissionais da área.
                    </p>
                    <br />
                    <p>
                        Ao criar uma conta você poderá participar da versão de
                        teste de pré lançamento!
                    </p>
                    <br />
                </RegisterForm>
                <span style={{ margin: 10 }} />
                <RegisterForm>
                    {context.GSignIn.user && (
                        <Email>
                            <img
                                src={require("../../../assets/png/google.jpg")}
                            />
                            <div style={{ flexDirection: "column", flex: 1 }}>
                                <h4>{context.GSignIn.user?.email ?? "none"}</h4>
                                <p>Criar Conta</p>
                            </div>
                            <BiChevronDown size={24} />
                        </Email>
                    )}
                    <Inputfield
                        tabIndex={1}
                        name="name"
                        value={form.name}
                        placeholder="Nome e Sobrenome"
                        onChange={handleForm}
                        icon={FiUser}
                        width={`100%`}
                    />
                    <span />

                    {!context.GSignIn.user && (
                        <>
                            <Inputfield
                                tabIndex={5}
                                name="email"
                                value={form.email}
                                placeholder="Email"
                                onChange={handleForm}
                                icon={MdEmail}
                                width={`100%`}
                            />

                            {form.email && !ValidateEmail(form.email) && (
                                <Msg>Email Inválido!</Msg>
                            )}
                            <span />
                        </>
                    )}

                    <MaskInput
                        tabIndex={2}
                        name="phone"
                        value={form.phone}
                        placeholder="Telefone"
                        onChange={handleForm}
                        icon={MdPhone}
                        width={`100%`}
                        mask={phoneMask}
                        error={false}
                    />
                    <span />
                    <MaskInput
                        tabIndex={3}
                        name="identity"
                        placeholder="CPF/CNPJ"
                        onChange={handleForm}
                        icon={TbIdBadge}
                        width={`100%`}
                        mask={cpfMask}
                        error={false}
                    />
                    <span />
                    <MaskInput
                        tabIndex={4}
                        name="birthdate"
                        placeholder="Data de Nascimento"
                        onChange={handleForm}
                        icon={MdCake}
                        width={`100%`}
                        mask={birthdateMask}
                        error={false}
                    />

                    <span />
                    <Inputfield
                        tabIndex={6}
                        name="name_pj"
                        placeholder="Nome fantasia"
                        onChange={handleForm}
                        icon={BiBuilding}
                        width={`100%`}
                    />
                    <span />
                    <Inputfield
                        tabIndex={7}
                        name="password"
                        placeholder="Senha"
                        onChange={handleForm}
                        icon={MdOutlineFingerprint}
                        width={`100%`}
                        type={"password"}
                    />

                    {form.password &&
                        !ValidatePass(form.password).valido &&
                        ValidatePass(form.password).mensagem.map((e) => (
                            <Msg key={e.substring(0, 10)}>{e}</Msg>
                        ))}

                    <span />
                    <ButtonsWrapper>
                        <button onClick={() => nav("/")}>VOLTAR</button>

                        <LoginButton
                            tabIndex={8}
                            disabled={
                                !ValidateEmail(form.email) ||
                                !ValidatePass(form.password).valido
                            }
                            onClick={handleRegister}
                        >
                            <EnterLabel />
                        </LoginButton>
                    </ButtonsWrapper>
                </RegisterForm>
            </PanelContent>
        </Container>
    )
}

export default Register
