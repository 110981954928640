import styled from "styled-components"

export const FoldsContainer = styled.div`
    flex: 1;
    flex-direction: column;
    align-items: center;
    * div,
    * ul {
        flex-direction: column;
    }

    select {
        width: 100%;
        text-align: center;
    }

    option {
        height: 90px;
    }

    text-overflow: ellipsis;
`

export const Form = styled.form`
    flex: 1;

    max-width: 450px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    li {
        padding: 5px;
        border-radius: 5px;

        input {
            align-items: center;
            text-align: center;
        }
    }

    & > *:nth-child(odd) {
        background-color: #0001;
    }
`
