import React, { useEffect, useState } from "react"
import {
    WorkoutType,
    Weight,
    Crossfit,
    Aerobic,
    Heating,
    Workout,
    MultiWorkout,
} from "src/modules/fithub-shared/interfaces/workout"
import { usePlan } from "src/modules/protocols/context"
import WeightWorkout from "./weight"
import CrossfitComp from "./crossfit"
import AerobicComp from "./aerobic"
import HeatingComp from "./heating"
import { GetPCache, SetCache } from "src/assets/functions/cache"
import mostrarAlerta from "src/components/utils/alert"
import { ThreeDots } from "react-loader-spinner"

const WorkoutView: React.FC<{
    values: Workout
    tag: string
    setValues: (values: Workout) => void
    setObs: (obs: string) => void
}> = ({ tag, values, setValues, setObs }) => {
    const [workout, setWorkout] = useState<Workout | null>(values)

    useEffect(() => {
        if (values.id !== workout?.id) {
            setWorkout(null)
            setTimeout(() => {
                setWorkout(values)
            }, 250)
        }
    }, [values])

    function onChange(content: MultiWorkout) {
        SetCache(tag, content)
        setValues({ ...workout, item: content } as Workout)
    }

    if (!workout)
        return (
            <div className="flex centered">
                <ThreeDots
                    height="60"
                    width="60"
                    color="#000"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            </div>
        )
    else
        return (
            <>
                {workout.type === WorkoutType.weight && (
                    <WeightWorkout
                        content={GetPCache(tag, workout.item as Weight, false)}
                        obs={workout.obs}
                        onChange={onChange}
                        onChangeObs={setObs}
                    />
                )}
                {workout.type === WorkoutType.aerobic && (
                    <AerobicComp content={workout.item as Aerobic} />
                )}
                {workout.type === WorkoutType.crossfit && (
                    <CrossfitComp content={workout.item as Crossfit} />
                )}
                {workout.type === WorkoutType.heating && (
                    <HeatingComp content={workout.item as Heating} />
                )}
            </>
        )
}

export default WorkoutView
