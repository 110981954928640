import React from "react"
import { Avatar, FoundWrap, StepWrapper, UserInformation } from "./styles"
import { FoundUser } from ".."
import user from "../../../../assets/png/userd-demo-pic.png"
// import { Container } from './styles';

interface StepProps {
    children: React.ReactElement
    active?: boolean
    found?: FoundUser
}

const Step: React.FC<StepProps> = (props) => {
    return (
        <StepWrapper active={props.active}>
            {!props.found ? (
                <>{props.children}</>
            ) : (
                <FoundWrap>
                    <p>USUÁRIO ENCONTRADO</p>
                    <span />
                    <UserInformation>
                        <Avatar src={user} />
                        <div>
                            <p>{props.found.name}</p>
                            <p>{props.found.phone.slice(0, 4)}*******</p>
                        </div>
                    </UserInformation>
                </FoundWrap>
            )}
        </StepWrapper>
    )
}

export default Step
