import moment from "moment"
import { ExerciseData } from "src/screens/ChargeProgression"

export const formatChartData = (exercises: ExerciseData[]) => {
    const categories: string[] = [] // Eixo X (datas)
    const reps: number[] = [] // Série de repetições
    const weight: number[] = [] // Série de pesos
    const feedback: Array<{ date: string; message: string }> = [] // Feedback
    const annotations: any[] = [] // Labels para marcação do fim do treino

    exercises.forEach((exercise, index) => {
        // Adiciona uma entrada para cada série
        exercise.data.forEach(() => {
            categories.push(moment(exercise.exec_at).format("DD/MM/YY"))
        })

        // Adiciona cada repetição e peso separadamente
        exercise.data.forEach((set) => {
            reps.push(set.reps)
            weight.push(set.weight)
        })

        // Adiciona o feedback apenas uma vez por exercício
        feedback.push({ date: exercise.exec_at, message: exercise.feedback })

        // Cria uma anotação ao final de cada treino
        annotations.push({
            x: new Date(exercise.exec_at).getTime(),
            strokeDashArray: 0,
            borderColor: "#775DD0",
            label: {
                borderColor: "#775DD0",
                style: {
                    color: "#fff",
                    background: "#775DD0",
                },
                text: `Fim Treino ${index + 1}`,
            },
        })
    })

    return { categories, reps, weight, feedback, annotations }
}

export const formatSimpleChartData = (exercises: ExerciseData[]) => {
    const categories: string[] = [] // Eixo X (datas)
    const reps: number[] = [] // Série de repetições
    const weight: number[] = [] // Série de pesos
    const feedback: Array<{ date: string; message: string }> = [] // Feedback

    exercises.forEach((exercise) => {
        exercise.data.forEach(() => {
            // Adiciona a data formatada uma vez para cada série
            categories.push(moment(exercise.exec_at).format("DD/MM/YY"))
        })

        // Adiciona cada repetição e peso separadamente
        exercise.data.forEach((set) => {
            reps.push(set.reps)
            weight.push(set.weight)
        })

        // Adiciona o feedback apenas uma vez por exercício
        if (exercise.feedback !== "")
            feedback.push({
                date: exercise.exec_at,
                message: exercise.feedback,
            })
    })

    return { categories, reps, weight, feedback }
}
