import styled from "styled-components"

export const Scrollable = styled.ul`
    flex: 1 0 1;
    flex-direction: column;

    min-height: 0px;

    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;
`

export const LabelView = styled.div`
    flex-direction: column;
    margin-top: 5px;

    h3 {
        font-size: 12px;
    }

    p {
        font-size: 16px;
    }
`

export const RoundButton = styled.button`
    background-color: #888;
    padding: 5px;
    border-radius: 50%;
    color: #fff;

    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`

export const ContentView = styled.li`
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    background-color: #ddd;
    margin-top: 5px;

    border-radius: 5px;

    padding: 10px;

    h1 {
        font-size: large;
        font-weight: bold;
        margin-bottom: 10px;
    }
`

export const Content = styled.div`
    flex-direction: column;

    overflow: hidden;

    padding: 5px;

    flex: 1;

    select {
        padding: 10px;
        border-radius: 5px;
    }

    img {
    }

    span {
        margin: 5px;
    }
`

export const ImageWrapper = styled.div`
    width: 95%;
    min-width: 50px;
    max-width: 400px;
    aspect-ratio: 0.95;

    border-radius: 5px;
    border: 1px solid #ccc;

    justify-content: center;
    align-items: center;

    img {
        object-fit: cover;
        opacity: 0.1;
        height: 100%;
        width: 100%;
    }

    > div {
        flex-direction: column;

        align-items: center;

        padding: 20px;
        position: absolute;
        background-color: #444;
        color: white;
        text-transform: uppercase;
        border-radius: 5px;
        z-index: 5;

        cursor: not-allowed;
    }
`

export const Row = styled.div<{ height: string }>`
    height: ${(p) => p.height};
    width: 100%;
`

export const ContentHeader = styled.div`
    padding: 10px;

    justify-content: space-between;
`

export const Save = styled.button`
    padding: 10px;
    background-color: darkgreen;
    color: white;
    border-radius: 5px;
    cursor: pointer;

    margin-left: 10px;

    :hover {
        opacity: 0.9;
    }

    :active {
        opacity: 0.7;
    }
`

export const StudentDetails = styled.div`
    flex-direction: column;
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;

    padding: 10px;
    width: 100%;
`

export const Column = styled.div`
    flex-direction: column;
`
