import React, { useContext } from "react"

import AuthContext from "src/contexts/auth"

import { SignedRoutes } from "./SignedRoutes.1"

export function LogoutRoute() {
    const context = useContext(AuthContext)
    context.Logout()
    return <div>teste</div>
}

export default SignedRoutes
