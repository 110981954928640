import { Routes } from "react-router-dom"
import styled, { createGlobalStyle } from "styled-components"

export const StyledRoutes = styled(Routes)`
    overflow: hidden;
`

export const Container = styled.div`
    border-radius: 5px;
    background-color: #fff;

    height: 100%;

    overflow: hidden;
`

export default createGlobalStyle`

    /*h1, h2, h3, h4, h5 {
      white-space: nowrap;
      text-overflow: ellipsis;
    }*/

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      opacity: .5;
    }

    .blur{
      filter: blur(5px);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    } 

    *:not(input) {
        user-select: none;
    }

    input{
      all: unset;
      font-size: 14px;
      
      background-color: #fff;
      border-radius: 5px;
      padding: 5px;

      -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0,  0.3);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }

    .shadow {
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    }

    .disabled {
        opacity: 0.5;
    }

    #centered{
      justify-content: center;
      align-items: center;
    }

    .centered{
      
      justify-content: center;
      align-items: center;
    }

    .input-shadow{
      -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0,  0.3);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    .fade-in {
      @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
      }

      animation: fadein 0.2s forwards;
    }
    
    .zoom-in {
      @keyframes zoomin {
        0% {
            opacity: 0;
            transform: scale(0.8);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
      }

      animation: zoomin 0.1s forwards;
    }

    ::-moz-selection { /* Code for Firefox */
      color: var(--primary);
      background: #0003;
    }

    ::selection {
      color: var(--primary);
      background: #0003;
    }

    .spaced{
      justify-content: space-between;
    }

    .padded{
      padding:10px
    }

    .flex * {
      display: flex;
    }

    .column {
      display: flex;
      flex-direction: column;     
    }

    .row {
      display: flex;
      flex-direction: row;     
    }
    
    #root {
      height: 100%;
      width: 100%;
      overflow: none;
      background-color: #fff; 
    }

    .App {
      flex-direction: column;
      height: 100vh;
    }

    *
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Exo 2', sans-serif;
        font-weight: 400;

        -webkit-tap-highlight-color: transparent;
    }
    
    input 
    {
      //border: 0;
      outline: 0.1;
      outline: none !important;
    }

    

    button 
    {      
      border: 0;
      outline: 0.1;
      outline: none !important;

      color: black;

      :focus {
          outline: none;
      }

      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Para iOS */
      -webkit-appearance: none; /* Para Safari */
      -moz-appearance: none; /* Para Firefox */
      appearance: none; /* Para outros navegadores */
    }

    /* width */
    ::-webkit-scrollbar {
        background: transparent;
       
        margin: 5px;
        width: 7px;
        height: 6px;
        
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      background-color: transparent;      
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {        
        background: #0002;   
        border-radius: 25px;

        :hover{
          background: #0004;  
        }
    }

    :root {

        --bege: #e8e4db;
        --marrom: #c87c52;
        --vermelho: #dd1a00;
        --marromDark: #713217;
        --preto: #1d1d1b;

        --primary: #dd1a00;
        --primaryDark: #fff;
        --font: #000;
        --fontLight: #fff;
        --panel: #eee;

        --shadow: #00000044;

        --light: #fff;
        --dark: #000;
    }

    .react-toggle {
      touch-action: pan-x;

      display: inline-block;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      padding: 0;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      -webkit-tap-highlight-color: rgba(0,0,0,0);
      -webkit-tap-highlight-color: transparent;
    }

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all .2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 0px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 17px;
  border-color: #19AB27;
}
`
