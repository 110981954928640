import React, { useRef } from "react"
import styled from "styled-components"
import { useOnOutClick } from "../hooks"
import { MdClose } from "react-icons/md"

interface VideoOverlayProps {
    videoUrl: string
    onClose: () => void
}

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`

const IframeWrapper = styled.div`
    position: relative;
    width: 80%;
    height: 80%;
    max-width: 800px;
    max-height: 450px;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
`

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;

    border-radius: 25px;

    justify-content: center;
    align-items: center;

    background: white;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
`

const VideoOverlay: React.FC<VideoOverlayProps> = ({ videoUrl, onClose }) => {
    const ref = useRef(null)
    useOnOutClick(ref, onClose)
    return (
        <Backdrop>
            <IframeWrapper ref={ref}>
                <CloseButton
                    style={{ border: "3px solid black" }}
                    className="shadow"
                    onClick={onClose}
                >
                    <MdClose size={18} />
                </CloseButton>
                <iframe
                    className="shadow"
                    style={{
                        borderRadius: 15,
                        border: "3px solid black",
                        backgroundColor: "black",
                    }}
                    src={`${videoUrl}?modestbranding=1&rel=0&showinfo=0&controls=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </IframeWrapper>
        </Backdrop>
    )
}

export default VideoOverlay
