import styled from "styled-components"

export const List = styled.div.attrs({ className: "flex row" })`
    flex-shrink: 0;
    position: relative;
    padding: 5px 5px 0px 5px;
    overflow-x: scroll;
`

export const Tab = styled.div<{ selected: boolean; isDragging?: boolean }>`
    display: flex;

    width: 150px;
    justify-content: space-between;

    background-color: ${(p) => (p.selected ? "var(--primary)" : "#0004")};
    color: ${(p) => (p.selected ? "white" : "black")};
    height: 50px;

    cursor: ${(p) => (p.isDragging ? "grabbing" : "default")};

    border-radius: 5px;

    transition: background-color 0.1s ease-in-out;
    transition: color 0.1s ease-in-out;

    h2 {
        font-size: 10px;
        text-transform: uppercase;
    }

    span {
        height: 3px;
    }

    align-items: center;

    :not(:first-child) {
        margin-left: 5px;
    }
`

export const TitleWrap = styled.div`
    height: 28px;
    align-items: center;

    overflow: hidden;

    h1 {
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        text-overflow: clip;
    }
`

export const Labels = styled.div`
    flex-direction: column;
    padding-inline: 6px;

    cursor: pointer;

    :hover {
        opacity: 0.8;
    }

    :first {
        font-weight: bold;
    }
`
