import React from "react"
import { Activity } from ".."
import ActivityItem from "../ActivityItem"

// import { Container } from './styles';

const Activities: React.FC<{ activities: Activity[] }> = ({ activities }) => {
    return (
        <ul>
            {activities &&
                activities.map((e, i) => (
                    <ActivityItem key={`${i}_Act`} activity={e} />
                ))}
        </ul>
    )
}

export default Activities
