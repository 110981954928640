import moment from "moment"
import React, { useContext, useEffect, useState } from "react"

import { Calendar, View, momentLocalizer } from "react-big-calendar"

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"

import { CalendarStyles, CreateEventButton, Wrapper } from "./styling/styles"
import EventInfo, { NewEvent } from "./EventInfo"
import { EventColor } from "./functions"
import { CgAdd } from "react-icons/cg"
import api from "src/API/rest"
import AuthContext from "src/contexts/auth"

import { DndProvider } from "react-dnd"

import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { isMobile } from "react-device-detect"

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

const CALENDAR_TAG = "usage@CalendarView"

interface AddEventState {
    active: boolean
    current?: NewEvent
}

const CalendarScreen: React.FC = () => {
    const [events, setEvents] = useState<NewEvent[]>([])

    const [addEvent, setAddEvent] = useState<AddEventState>({ active: false })

    const [view, setView] = useState<View>(
        "month" //localStorage.getItem(CALENDAR_TAG) as View
    )
    const auth = useContext(AuthContext)

    function Load() {
        api.post<NewEvent[]>("/events/get", { id: auth.user?.id }).then(
            (res) => {
                setEvents(
                    [...res.data].map((e) => {
                        e.start = new Date(e.start)
                        e.end = new Date(e.end)
                        return e
                    })
                )
            }
        )
    }

    useEffect(() => {
        Load()
    }, [])

    function AddEvent(event: NewEvent) {
        const update = [...events]
        const index = update.findIndex((e) => e.id === event.id)
        if (index > -1) update[index] = event
        else update.push(event)
        setEvents(update)
        setAddEvent({ active: false, current: undefined })
    }

    function onChangeView(view: View) {
        console.log(events)
        //nav(`/calendar?v=${view}`)
        setView(view)
        localStorage.setItem(CALENDAR_TAG, view)
    }

    const onEventChange = (data: any) => {
        console.log(events)
        const { start, end, isAllDay } = data
        const { id, title } = data.event
        const maxEnd =
            end.getHours() > 24 ? end.setHours(start.getHours() + 1) : end

        const index = events.findIndex((e) => e.id === id)

        if (index === -1) return

        api.post<NewEvent>("/events/save", {
            ...events[index],
            start,
            end: maxEnd,
        }).then((res) => res.data)

        setEvents((old) => {
            const nArr = [...old]

            nArr[index] = {
                ...events[index],
                start: start,
                end: maxEnd,
                isAllDay,
                title: `${title}`,
            }

            return nArr
        })
    }

    return (
        <Wrapper style={{ display: "flex", flexDirection: "column" }}>
            <div>
                <CreateEventButton
                    onClick={() => setAddEvent({ active: true })}
                >
                    <CgAdd />
                    <p>ADD EVENT</p>
                </CreateEventButton>
            </div>
            <CalendarStyles />
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <DnDCalendar
                    defaultDate={moment().toDate()}
                    defaultView={view ?? "month"}
                    onView={onChangeView}
                    events={events as NewEvent[]}
                    localizer={localizer}
                    messages={{
                        today: "Hoje",
                        previous: "Anterior",
                        next: "Próximo",
                        month: "Mês",
                        week: "Semana",
                        day: "Dia",
                        noEventsInRange: "Não há eventos para este período!",
                    }}
                    onEventDrop={onEventChange}
                    onEventResize={onEventChange}
                    style={{ flex: 1 }}
                    onSelectEvent={(e) =>
                        setAddEvent({ active: true, current: e as NewEvent })
                    }
                    eventPropGetter={(m) => EventColor(m as NewEvent)}
                />
            </DndProvider>
            {addEvent.active && (
                <EventInfo
                    editable={addEvent.current}
                    onAddEvent={AddEvent}
                    onDismiss={() =>
                        setAddEvent({ active: false, current: undefined })
                    }
                />
            )}
        </Wrapper>
    )
}

export default CalendarScreen
