import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    background-color: #0008;

    justify-content: center;
    align-items: center;

    min-height: 100px;
    min-width: 100px;

    * {
        display: flex;
    }
`

export const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;

    height: 100%;
    width: 100%;
    max-height: 500px;
    max-width: 750px;

    background-color: white;
    border-radius: 15px;

    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.69);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
`

export const TemplatesButton = styled.button`
    cursor: pointer;
    padding: 5px;
    background-color: #ccc;
    border-radius: 5px;
    :hover {
        opacity: 0.5;
    }
`

export const List = styled.ul`
    display: flex;
    flex: 1 1 0;

    width: 100%;

    flex-direction: column;

    margin-top: 10px;
    overflow-y: auto;
`

export const DateLabel = styled.div`
    align-items: center;

    justify-content: space-evenly;

    font-size: 12px;
    p {
        padding-right: 5px;
    }
`

export const ExerciseListItem = styled.li`
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 2px;

    p {
        font-weight: 600;
    }

    button {
        background-color: transparent;
        :hover {
            opacity: 0.5;
        }

        height: 20px;

        cursor: pointer;
    }

    font-size: 14px;
    justify-content: space-between;
    align-items: center;

    :focus,
    :hover {
        background-color: #ddd;
    }

    transition: background-color 0.1s;
`

export const CreateExerciseButton = styled.button`
    flex-direction: row;

    height: 25px;
    background-color: #ddd;
    color: black;
    border: 1px solid #ddd;
    border-radius: 5px;

    p {
        margin-left: 5px;
    }

    padding: 5px;

    font-size: 14px;
    align-items: center;

    cursor: pointer;

    :focus,
    :hover {
        opacity: 0.8;
    }

    transition: opacity 0.1s;
`

export const Browser = styled.div`
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    padding: 5px;
`

export const Title = styled.h4`
    padding: 15px;
`

export const SearchBox = styled.div`
    width: 50%;

    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;

    input {
        all: unset;
        font-size: 14px;
        height: 100%;
        text-align: center;
        width: 100%;
    }
`
