import React, { CSSProperties, ReactNode, useEffect, useState } from "react"
import useWindowSize from "./useWindowSize"
import { Container, Content, GoBack, HorizontalResize, List } from "./styles"
import { HiChevronLeft } from "react-icons/hi"
import useDragResize from "../useDragResize"
import { MdDragHandle } from "react-icons/md"
import { RiDraggable } from "react-icons/ri"

// import { Container } from './styles';

interface SideListViewProps {
    list: ReactNode
    content: ReactNode
    view: "LIST" | "CONTENT"
    onGoBack?: () => void
    style?: CSSProperties
    useBackButton?: boolean
}

const SideListView: React.FC<SideListViewProps> = (props) => {
    const isSmall = useWindowSize()
    const [view, setView] = useState(props.view ?? "LIST")

    const { flex, handleDragStart, handleDrag, handleDragEnd } = useDragResize({
        initialFlex: 0.25,
        minFlex: 0.15,
        maxFlex: 0.5,
    })

    useEffect(() => {
        setView(props.view)
        //??
    }, [props.view])

    useEffect(() => {
        const handlePopstate = (/*event: PopStateEvent*/) => {
            if (view === "CONTENT") {
                handleGoBack()
            }
        }

        window.addEventListener("popstate", handlePopstate)

        return () => {
            window.removeEventListener("popstate", handlePopstate)
        }
    }, [view])

    function handleGoBack() {
        if (props.onGoBack) {
            props.onGoBack()
            return
        } else {
            setView("LIST")
        }
    }

    if (!isSmall) {
        return (
            <Container style={props.style ?? undefined} className="flex row">
                <List flex={flex}>{props.list}</List>
                <HorizontalResize
                    draggable
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    onTouchStart={() => handleDragStart()}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleDragEnd}
                >
                    <span>
                        <RiDraggable />
                    </span>
                </HorizontalResize>
                <Content flex={1 - flex}>{props.content}</Content>
            </Container>
        )
    } else {
        return (
            <Container style={props.style ?? undefined} className="flex column">
                {view === "LIST" ? (
                    <>
                        <List flex={1}>{props.list}</List>
                    </>
                ) : (
                    <>
                        {props.useBackButton === true && (
                            <GoBack>
                                <HiChevronLeft
                                    size={40}
                                    onClick={handleGoBack}
                                />
                            </GoBack>
                        )}
                        <Content flex={1}>{props.content}</Content>
                    </>
                )}
            </Container>
        )
    }
}

export default SideListView
