import styled from "styled-components"

export const Button = styled.button`
    all: unset;
    cursor: pointer;
    img {
        height: ${(props) => (props.size ? props.size : "25px")};
        aspect-ratio: 1;
        padding: 2px;
    }

    transition: opacity 0.1s, transform 0.1s;

    :hover {
        transform: scale(1.2);
    }

    :active {
        transform: scale(1.05);
    }

    :focus {
        border-radius: 5px;
    }
`
