import React, { CSSProperties, InputHTMLAttributes, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "./styles"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { filterProps } from "./functions"

// import { Container } from './styles';

interface CustomButton {
    error?: boolean
    style?: CSSProperties
    align?:
        | "start"
        | "end"
        | "left"
        | "right"
        | "center"
        | "justify"
        | "match-parent"
    icon?: IconType
    height?: number
    width?: number | string
    selectOnFocus?: boolean
    revealPassword?: boolean
    maxLength?: number
    minLength?: number
    iconColor?: string
    radius?: string
    autofocus?: boolean
    backgroundColor?: string
    onChangeText?: (value: string) => void
    onEndText?: (t: string) => void
}

type InputProps = CustomButton & InputHTMLAttributes<HTMLInputElement>

const Inputfield: React.FC<InputProps> = (props) => {
    const [error, setError] = useState(props.error)
    const [reveal, setReveal] = useState(false)

    const Ico = () => {
        if (props.icon === undefined) {
            return <></>
        } else {
            return (
                <div style={{ marginInline: 5 }}>
                    <props.icon
                        color={props.iconColor ? props.iconColor : "#444"}
                        size={18}
                    />
                </div>
            )
        }
    }

    const Reveal = () => {
        setReveal(!reveal)
    }

    const RevealType = () => {
        if (props.type === "password") {
            return reveal ? "reveal" : "password"
        } else {
            return props.type
        }
    }

    function _OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (props.onChange) props.onChange(e)
        if (props.onChangeText) props.onChangeText(e.target.value)
    }

    function _OnBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.minLength) {
            setError(
                e.target.value.length > 0 &&
                    e.target.value.length < props.minLength
            )
        }

        if (props.onEndText) props.onEndText(e.target.value)
    }

    function _OnFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.selectOnFocus) e.target.select()
    }

    return (
        <Area
            tabIndex={-1}
            radius={props.radius}
            style={{
                ...{
                    flexDirection: "row",
                    width: props.width,
                    height: props.height,
                    paddingLeft: props.icon ? 0 : 5,
                    opacity: props.disabled ? 0.5 : 1,
                    backgroundColor: props.backgroundColor
                        ? props.backgroundColor
                        : "#fff",
                    borderColor: error ? "red" : "#ccc",
                },
                ...props.style,
            }}
        >
            <Ico />
            <input
                {...props}
                style={{
                    textAlign: props.align,
                    paddingInline: 5,
                    fontSize: props.style?.fontSize,
                }}
                type={RevealType()}
                onChange={_OnChange}
                onBlur={_OnBlur}
                onFocus={_OnFocus}
            />
            {props.type === "password" && (
                <button
                    tabIndex={-1}
                    style={{
                        padding: 5,
                        borderRadius: 25,
                        cursor: "pointer",
                    }}
                    onClick={Reveal}
                >
                    {reveal ? <FiEyeOff /> : <FiEye />}
                </button>
            )}
        </Area>
    )
}

export default Inputfield
