import React, { useContext, useState } from "react"
import { BiSearch } from "react-icons/bi"
import { MdAddCircle } from "react-icons/md"
import MutableIcon from "../../../../../components/mutable-icon"
import {
    Browser,
    SearchBox,
    List,
    ExerciseListItem,
    CreateExerciseButton,
} from "../styles"
import { TemplatesB } from ".."
import { FilterAndSort } from "../../../functions/string"
import AuthContext from "src/contexts/auth"
import { Queries } from "src/assets/queries/exerciseTemplates/queries"

import { useNewExerciseMutation } from "src/assets/queries/exerciseTemplates/mutations"
import { HiTemplate, HiUserCircle } from "react-icons/hi"
import { FaYoutube, FaYoutubeSquare } from "react-icons/fa"
import LoadErrorMessage from "../LoadErrorMessage"

// import { Container } from './styles';

const ExercisesTemplates: React.FC<TemplatesB> = (props) => {
    const [search, setSearch] = useState("")

    const auth = useContext(AuthContext)

    const { data, isLoading, error, refetch } = Queries.useExercisesList(
        auth.user?.id
    )

    function Search(t: string) {
        setSearch(t)
    }

    function Select(str: string, id: string) {
        props.onSelect(str, id)
        props.onDismis(false)
    }

    const newExercise = useNewExerciseMutation()

    async function Create() {
        await newExercise.mutateAsync(search)
    }
    const filtered = FilterAndSort(!data ? [] : data, search).slice(0, 80)

    if (!isLoading && filtered) {
        return (
            <Browser>
                <SearchBox>
                    <BiSearch />
                    <input
                        id="search-box"
                        autoFocus
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => Search(e.target.value)}
                        placeholder="Pesquisar Exercício..."
                    />
                </SearchBox>

                <List>
                    {filtered.map((element, index) => {
                        return (
                            <ExerciseListItem key={index}>
                                <button
                                    onClick={() =>
                                        Select(element.name, element.id)
                                    }
                                    style={{ flex: 1, height: "100%" }}
                                >
                                    <p>{element.name}</p>
                                </button>

                                <div style={{ alignItems: "center" }}>
                                    {element.created_by ? (
                                        <MutableIcon
                                            icon={HiUserCircle}
                                            color={"black"}
                                        />
                                    ) : (
                                        <p
                                            style={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            FITHUB
                                        </p>
                                    )}

                                    <button
                                        disabled={!element.media_url}
                                        style={{ paddingLeft: 10 }}
                                        onClick={() =>
                                            window.open(
                                                element.media_url,
                                                "_blank",
                                                "noreferrer"
                                            )
                                        }
                                    >
                                        <FaYoutube size={20} />
                                    </button>
                                </div>
                            </ExerciseListItem>
                        )
                    })}
                </List>
                {search && (
                    <CreateExerciseButton onClick={Create}>
                        <MutableIcon icon={MdAddCircle} />
                        <p>Criar Exercício {`"${search}"`}</p>
                    </CreateExerciseButton>
                )}
            </Browser>
        )
    } else if (error) {
        return <LoadErrorMessage refetch={refetch} error={error} />
    } else {
        return <p>Carregando...</p>
    }
}

export default ExercisesTemplates
