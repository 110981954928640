import api from "../../../../API/rest"
import { ProtocolType } from "../../../fithub-shared/interfaces/protocol"
import { ExerciseTemplate } from "../../../fithub-shared/interfaces/templates"
import mostrarAlerta from "../../../../components/utils/alert"

export let currentProtocol: ProtocolType
export let exercisesList: ExerciseTemplate[]

export async function GetPlansList(student_id?: string, tid?: string) {
    if (!student_id || !tid) return []

    return await api
        .post("/protocols/list", {
            student_id: student_id,
            trainer_id: tid,
        })
        .then((res) => {
            //        mostrarAlerta(JSON.stringify(res.data));
            const _protocols = res.data as ProtocolType[]
            _protocols.forEach((element) => {
                if (localStorage.getItem(`${element.id}`) !== null) {
                    element.autosave = true
                }
            })

            return _protocols
        })
        .catch(() => {
            mostrarAlerta("Não foi possível carregar os protocolos! Erro 400")
            return [] as ProtocolType[]
        })
}

export async function GetPlanTemplates(tid?: string) {
    if (!tid) return [] as ProtocolType[]

    return await api
        .post("/protocols/list/templates", {
            trainer_id: tid,
        })
        .then((res) => {
            //        mostrarAlerta(JSON.stringify(res.data));
            const _protocols = res.data as ProtocolType[]
            _protocols.forEach((element) => {
                if (localStorage.getItem(`${element.id}`) !== null) {
                    element.autosave = true
                }
            })

            return _protocols
        })
        .catch(() => {
            mostrarAlerta("Não foi possível carregar os protocolos! Erro 400")
            return [] as ProtocolType[]
        })
}

export function StoreProtocol(protocol: ProtocolType) {
    protocol.autosave = true
    localStorage.setItem(`${protocol.id}`, JSON.stringify(protocol))
}

export async function UploadProtocolData(protocol: ProtocolType) {
    protocol.autosave = false

    RemoveLocal(protocol.id)

    return await api
        .post("/protocols/save", protocol)
        .then(() => {
            return true
        })
        .catch((err) => {
            mostrarAlerta(JSON.stringify(err))
            return false
        })
}

export function GetLocal(id: string) {
    return localStorage.getItem(`${id}`) !== null
}

export function RemoveLocal(id: string) {
    localStorage.removeItem(`${id}`)
}

export async function LoadProtocolData(id: string) {
    if (localStorage.getItem(`${id}`) === null) {
        await api
            .post(`/protocols/get`, { id: id })
            .then((res) => {
                currentProtocol = res.data as ProtocolType
            })
            .catch((err) => {
                alert(err)
                //currentProtocol = new ProtocolType()
            })
    } else if (localStorage.getItem(`${id}`) != null) {
        currentProtocol = new ProtocolType(
            JSON.parse(localStorage.getItem(`${id}`) as string) as ProtocolType
        )
    }

    return currentProtocol
}
/*
toda vez que carregar a lista de protocolos
preciso verificar se existe no async storage;
caso exista, ao invés de puxar o protocolo do banco de dados,
ele vai puxar do async storage. então vai ser {id do protocolo}
*/
