import React from "react"
import { AerobicExercise } from "../../../fithub-shared/interfaces/workout"
import {
    ButtonDelete,
    CompSelect,
    Content,
    HorizontalFlow,
    VerticalItem,
} from "./styles"
import { MdDeleteForever } from "react-icons/md"
import { compendium } from "src/modules/fithub-shared/data"
import ClampedInput from "src/components/clamped-input"

// import { Container } from './styles';

interface AerobicExerciseProps {
    content: AerobicExercise
    onChange: (data: AerobicExercise) => void
    onDelete: (data: AerobicExercise) => void
}

const AerobicExerciseComponent: React.FC<AerobicExerciseProps> = (props) => {
    function _onChangeDuration(e: React.FocusEvent<HTMLInputElement>) {
        const value = parseInt(e.target.value)
        props.content.duration = isNaN(value) ? 0 : value
        props.onChange(props.content)
    }

    function _onChangeIntensity(e: React.ChangeEvent<HTMLSelectElement>) {
        props.content.intensity = parseInt(e.target.value)
        props.onChange(props.content)
    }

    /*function _onChangeObs(t: React.ChangeEvent<HTMLTextAreaElement>) {
        props.content.obs = t.target.value
        props.onChange(props.content)

        t.target.style.height = "15px"
        t.target.style.height = t.target.scrollHeight + "px"
    }*/

    function Delete() {
        props.onDelete(props.content)
    }

    /*function auto_grow(element: React.ChangeEvent<HTMLTextAreaElement>) {
        element.target.style.height = "15px"
        element.target.style.height = element.target.scrollHeight + "px"
    }*/

    return (
        <Content className="shadow">
            <ButtonDelete onClick={Delete}>
                <MdDeleteForever size={24} />
            </ButtonDelete>
            <HorizontalFlow>
                <VerticalItem>
                    <p>Modalidade do Exercício</p>
                    <span />
                    <CompSelect
                        defaultValue={props.content.intensity}
                        onChange={_onChangeIntensity}
                    >
                        {compendium.map((e, i) => {
                            return (
                                <option key={i} value={i} title={e.description}>
                                    {e.raw}
                                </option>
                            )
                        })}
                    </CompSelect>
                </VerticalItem>

                <VerticalItem
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <p>Duração (Minutos)</p>
                    <span />
                    <ClampedInput
                        onFocus={(e) => e.target.select()}
                        clampMin={1}
                        clampMax={999}
                        defaultValue={
                            props.content.duration ? props.content.duration : ""
                        }
                        onBlur={_onChangeDuration}
                        style={{
                            height: 22,
                            border: "none",
                        }}
                    />
                </VerticalItem>
            </HorizontalFlow>
        </Content>
    )
}

export default AerobicExerciseComponent
