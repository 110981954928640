import React, { useEffect, useRef, useState } from "react"
import { useOnOutClick } from "../../hooks"
import { Item } from "./styles"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { ContextMenuState } from ".."
// import { Container } from './styles';

export const initialContextMenu = {
    show: false,
    x: 0,
    y: 0,
}

export const Menu = styled.div<{ x: number; y: number; menuSize: number }>`
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: #444;
    padding: 5px;
    z-index: 20;
    border-radius: 5px;
    top: ${(p) => calculatePosition(p.y, window.innerHeight, p.menuSize)}px;
    left: ${(p) => calculatePosition(p.x, window.innerWidth, p.menuSize)}px;
`

const calculatePosition = (
    coordinate: number,
    dimension: number,
    menuSize: number
) => {
    const buffer = 10 // Espaço mínimo entre o menu e a borda da tela

    //mostrarAlerta(menuSize + "")

    // Se o menu ultrapassar a borda direita da tela, coloque-o no lado esquerdo do ponto de origem
    if (coordinate + menuSize + buffer > dimension) {
        return coordinate - menuSize - buffer
    } else {
        // Caso contrário, coloque-o no lado direito do ponto de origem
        return coordinate + buffer
    }
}

const CustomContextMenu: React.FC<
    ContextMenuState & { onClose: () => void }
> = ({ x, y, items, onClose }) => {
    const contextMenuRef = useRef<HTMLDivElement>(null)
    useOnOutClick(contextMenuRef, onClose)

    const [menuSize, setMenuSize] = useState(100)

    useEffect(() => {
        if (!contextMenuRef.current) return
        const { height } = contextMenuRef.current.getBoundingClientRect()
        setMenuSize(height)
    }, [])

    function onClick(click: () => void) {
        click()
        onClose()
    }

    return ReactDOM.createPortal(
        <Menu
            ref={contextMenuRef}
            className={"shadow"}
            x={x}
            y={y}
            menuSize={menuSize}
        >
            {items.map((element, index) => {
                return (
                    <Item key={index} onClick={() => onClick(element.action)}>
                        <element.icon style={{ marginRight: 10 }} size={20} />
                        {element.title}
                    </Item>
                )
            })}
        </Menu>,
        document.body
    )
}

export default CustomContextMenu
