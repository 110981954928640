import React from "react"
import styled from "styled-components"
import ReactDOM from "react-dom"

const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max)

const AlertContainer = styled.div<{ color?: string }>`
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(p) => (p.color ? p.color : "black")};
    color: white;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    z-index: 9999;
    cursor: pointer;
`

const Alerta: React.FC<{
    message: string
    onClose: () => void
    color?: string
}> = ({ message, onClose, color }) => {
    return (
        <AlertContainer className={"shadow"} onClick={onClose} color={color}>
            {message}
        </AlertContainer>
    )
}

let alertContainers: HTMLElement[] = []

const createAlertContainer = () => {
    const container = document.createElement("div")
    document.getElementById("root")?.appendChild(container)
    alertContainers.push(container)
    return container
}

const mostrarAlerta = (message: string, color?: string) => {
    const container = createAlertContainer()

    const onClose = () => {
        if (container) {
            ReactDOM.unmountComponentAtNode(container)
            container.remove()
            alertContainers = alertContainers.filter((c) => c !== container)
        }
    }

    ReactDOM.render(
        <Alerta message={message} onClose={onClose} color={color} />,
        container
    )

    // Fechar o alerta após 3 segundos (ajuste conforme necessário)
    setTimeout(() => {
        onClose()
    }, clamp(message.length * 50, 2000, 10000))
}

export default mostrarAlerta
