import styled from "styled-components"

export const TableKind = styled.div`
    & > div:nth-child(odd) {
        background-color: #0001;
    }

    border-bottom: 1px solid #ccc;
`

export const Wrapper = styled.div`
    flex-direction: column;

    * {
        align-items: center;
    }

    h3 {
        font-weight: bold;
        margin-bottom: 10px;
    }
`

export const StyledTableContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const StyledTable = styled.div`
    width: 100%;
`

export const StyledTh = styled.div`
    width: 100%;
    padding: 8px;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-weight: bold;
`

export const StyledTd = styled.div`
    text-align: center;
    justify-content: center;

    height: 35px;

    p {
        padding: 8px;
    }
`

export const StyledGraphTd = styled.div`
    text-align: center;
    justify-content: center;
    flex: 1;

    height: 200px;
    p {
        height: 100%;
    }
`
