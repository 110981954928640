import { useState } from "react"

export const useVideoOverlay = () => {
    const [isVideoOpen, setVideoOpen] = useState(false)
    const [videoUrl, setVideoUrl] = useState<string | null>(null)

    const openVideo = (url: string) => {
        setVideoUrl(url)
        setVideoOpen(true)
    }

    const closeVideo = () => {
        setVideoOpen(false)
        setVideoUrl(null)
    }

    return {
        isVideoOpen,
        videoUrl,
        openVideo,
        closeVideo,
    }
}
