import styled from "styled-components"

export const Content = styled.div`
    flex: 1;

    align-items: center;
    flex-direction: row;

    margin-bottom: 10px;

    background-color: #ddd;

    text-transform: uppercase;

    border-radius: 5px;
`

export const HorizontalFlow = styled.div`
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;

    padding-inline: 10px;

    margin-bottom: 10px;

    align-items: center;
    font-size: 32px;
`

export const ButtonDelete = styled.button`
    all: unset;
    cursor: pointer;
    margin: 10px;
    padding: 5px;
    border-radius: 25px;
    color: white;
    background-color: #444;

    :hover {
        color: red;
    }
`

export const VerticalItem = styled.div`
    flex: 1;
    width: 50%;
    min-width: 200px;
    font-size: 14px;
    padding: 10px;
    flex-direction: column;
    align-items: center;

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
    }

    input {
        all: unset;
        width: 100%;
        border: 1px solid #888;
        padding: 4px;
        border-radius: 5px;
        background-color: #fff;
    }

    span {
        margin-block: 5px;
    }
`

export const CompSelect = styled.select`
    width: 100%;
    height: 30px;
    border-radius: 5px;

    border: none;
    option {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const TimeInput = styled.input`
    all: unset;
    min-height: 23px;
    width: 100%;
    background-color: #fff;

    border-radius: 5px;
    border: 0.5px solid #666;
`

export const ObsArea = styled.textarea`
    height: 15px;
    border-radius: 5px;
    width: 100%;
    resize: vertical;

    resize: none;
    overflow: hidden;
`

export const Wrapper = styled.div`
    flex: 1;
    flex-basis: 0;
    overflow: hidden;

    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`
