import React from "react"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "../../../../components/mutable-icon"
import { Container, Content, Title } from "./styles"
import ExercisesTemplates from "./exercises-templates"
import WorkoutsTemplates from "./workouts-templates"
import ReactDOM from "react-dom"
import ProtocolsTemplates from "./plan-templates"

export interface TemplatesB {
    headerTitle: string
    onSelect: (name: string, id: string) => void
    onDismis: (u: boolean) => void
}

const TemplatesBrowser: React.FC<
    TemplatesB & { active: boolean; type: "Exercise" | "Workout" | "Protocol" }
> = (props) => {
    const ModalContent = () => {
        switch (props.type) {
            default:
                return <></>
            case "Exercise":
                return <ExercisesTemplates {...props} />
            case "Workout":
                return <WorkoutsTemplates {...props} />
            case "Protocol":
                return <ProtocolsTemplates {...props} />
        }
    }

    if (props.active) {
        return ReactDOM.createPortal(
            <Container className="fade-in">
                <Content>
                    <div>
                        <div
                            style={{
                                position: "absolute",
                                top: 20,
                                left: 0,
                            }}
                        >
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={30}
                                onClick={() => props.onDismis(false)}
                            />
                        </div>

                        <Title>{props.headerTitle}</Title>
                    </div>
                    <ModalContent />
                </Content>
            </Container>,
            document.body
        )
    } else {
        return <React.Fragment />
    }
}

export default TemplatesBrowser
