import moment from "moment"
import React from "react"
import { MdChevronRight } from "react-icons/md"
import styled from "styled-components"
import { Activity } from ".."
import { useNavigate } from "react-router-dom"

// import { Container } from './styles';

export const Item = styled.button`
    padding: 10px;

    flex-shrink: 0;
    height: 60px;
    width: 100%;

    border: 1px solid var(--panel);

    border-radius: 2px;

    align-items: center;

    justify-content: space-between;

    > div {
        flex-direction: column;

        > h4 {
            font-weight: 600;
            font-size: 14px;
        }
    }

    > a {
        flex-direction: row;
        align-items: center;

        background-color: #eee;
        border-radius: 25px;

        > h6 {
            padding-left: 5px;
            font-weight: inherit;
            text-transform: uppercase;
        }
    }

    :active {
        opacity: 0.5;
    }

    :not(:last-child) {
        border-bottom: 1px solid #0002;
    }

    cursor: pointer;
`

const ActivityItem: React.FC<{ activity: Activity }> = ({ activity }) => {
    const nav = useNavigate()

    function Action() {
        switch (activity.type) {
            default:
                alert("Def")
                break
            case "FEEDBACK":
                nav(
                    `/student/progression?plan=${activity.data.plan}&exc=${activity.data.exercise}`
                )
                break
        }
    }

    return (
        <Item onClick={() => Action()}>
            <div>
                <h4>{activity.title}</h4>
                <h5>{moment(activity.date).format("LL")}</h5>
            </div>
            <a>
                <h6>Ver Progressão</h6>
                <MdChevronRight />
            </a>
        </Item>
    )
}

export default ActivityItem
