const convertYouTubeLinkToEmbed = (url: string): string => {
    const videoIdMatch = url.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|user\/\S*?\/\S*?\/\S*?\/))([\w-]{11})/
    )

    if (videoIdMatch && videoIdMatch[1]) {
        const videoId = videoIdMatch[1]

        if (url.includes("shorts")) {
            return `https://www.youtube.com/embed/shorts/${videoId}`
        } else {
            return `https://www.youtube.com/embed/${videoId}`
        }
    }

    throw new Error("Invalid YouTube URL")
}

export default convertYouTubeLinkToEmbed
