import React, { useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { MdCancel, MdCheckCircle } from "react-icons/md"
import { ActionButtons, Backdrop, ConfirmButton, Panel } from "./styles"
import { useOnOutClick } from "../hooks"

// import { Container } from './styles';

interface Dialog {
    title: string
    subtitle: string
    confirmText?: string
    cancelText?: string
    onConfirm?: () => void
    onCancel?: () => void
    state?: (b: boolean) => void
}

const Dialog: React.FC<Dialog> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    const root = document.getElementById("root")

    useOnOutClick(ref, CANCEL)

    function OK() {
        props.onConfirm && props.onConfirm()

        props.state && props.state(false)
    }

    function CANCEL() {
        props.onCancel && props.onCancel()
        props.state && props.state(false)
    }

    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (event.key === "Escape") {
                event.preventDefault()
                CANCEL()
            } else if (event.key === "Enter") {
                event.preventDefault()
                OK()
            }
        }

        document.addEventListener("keydown", handleKeyPress)

        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [])

    if (!root) return <></>
    return ReactDOM.createPortal(
        <Backdrop className="fade-in">
            <Panel ref={ref}>
                <h3>{props.title}</h3>
                <p style={{ whiteSpace: "pre-line", fontWeight: 600 }}>
                    {props.subtitle}
                </p>
                <ActionButtons>
                    <ConfirmButton color="#444" onClick={CANCEL}>
                        <MdCancel />
                        <p>{props.cancelText ?? "Cancelar"}</p>
                    </ConfirmButton>
                    <ConfirmButton color="var(--primary)" onClick={OK}>
                        <MdCheckCircle />
                        <p>{props.confirmText ?? "Confirmar"}</p>
                    </ConfirmButton>
                </ActionButtons>
            </Panel>
        </Backdrop>,
        root
    )
}

export default Dialog
