import { Workout } from "./workout"

interface ExerciseValues {
    type: number
    value: number | 0
}

export interface ContentWorkout {
    workoutId: string
    values: Array<ExerciseValues>
}

export class ProtocolType {
    id = ""
    active = false
    title = "Novo Plano"
    obs = "123"
    level: string | undefined
    enable: Date | null
    disable: Date | null
    workouts: Workout[] = []
    workouts_count = 0
    created_at: Date
    autosave = false
    lastSelected = -1

    constructor(content: ProtocolType) {
        this.id = content.id
        this.active = content.active
        this.title = content.title
        this.obs = content.obs
        this.level = content.level
        this.enable = content.enable
        this.disable = content.disable
        this.workouts = content.workouts
        this.created_at = content.created_at
        if (this.workouts !== undefined)
            this.workouts_count = this.workouts.length
        else this.workouts_count = 0
    }
}
