import React, { MouseEventHandler } from "react"
import { Button } from "./styles"
import { IconType } from "react-icons"

interface props {
    tabIndex?: number
    height?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    src: IconType
}

const IconButton: React.FC<props> = (props) => {
    return (
        <Button {...props}>
            <props.src size={24} />
        </Button>
    )
}
export default IconButton
