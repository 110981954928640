import styled from "styled-components"

export const Wrapper = styled.div`
    display: flex;
    height: 100%;
    overflow: hidden;

    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`

export const CenteredContent = styled.div`
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const WorkoutTypeButton = styled.button`
    height: 65px;
    width: 250px;
    margin-block: 10px;
    padding: 10px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    p {
        font-weight: bold;
    }

    background-color: #444;

    cursor: pointer;

    transition: all 0.2s;

    :hover {
        opacity: 0.9;
    }

    :active {
        opacity: 0.7;
    }

    span {
        margin: 5px;
    }

    text-transform: uppercase;
    color: white;
`
