import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { isMobile } from "react-device-detect"

export const SmartSensor = {
    sensor: isMobile ? TouchSensor : MouseSensor,
    activation: isMobile
        ? {
              activationConstraint: {
                  delay: 80,
                  tolerance: 8,
              },
          }
        : {
              activationConstraint: {
                  distance: 5,
              },
          },
}
