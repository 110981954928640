import React, { useContext } from "react"
import SideListView from "src/components/SideListView"
import AuthContext from "src/contexts/auth"
import { ProtocolType } from "src/modules/fithub-shared/interfaces/protocol"
import ProtocolsList from "./protocols-listing"
import StudentInfoHeader from "../../components/StudentInfoHeader"
import api from "src/API/rest"
import mostrarAlerta from "src/components/utils/alert"
import { HeaderButton } from "./protocols-listing/styles"
import { usePlan } from "../../context"
import { MdOutlineCreateNewFolder } from "react-icons/md"
import { ThreeDots } from "react-loader-spinner"
import MutableIcon from "src/components/mutable-icon"
import { BiCloudDownload } from "react-icons/bi"
import { useStudent } from "src/contexts/student"
import ProtocolView from "./ProtocolView"
import { LoadProtocolData } from "../../cache/protocol-manager"
// import { Container } from './styles';

interface PlanViewProps {
    student?: string
    list?: ProtocolType[]
    loadingList: boolean
    isTemplates: boolean
    handleRemoveProtocol(id: string, callback: () => void): void
    handleDuplicate(id: string): void
    handleSaveTemplate(id: string): void
    handleExport(id: string): void
    handleImport(): void
}

const View: React.FC<PlanViewProps> = (props) => {
    const auth = useContext(AuthContext)

    const { plan, reloadList } = usePlan()

    const { student } = useStudent()

    function handleChangeTitle(t: string) {
        if (plan.selected === null) return

        const nplan = { ...plan.selected }
        nplan.title = t
        plan.update(nplan)
    }

    function handleChangeObs(t: string) {
        if (plan.selected === null) return
        const nplan = { ...plan.selected }
        nplan.obs = t
        plan.update(nplan)
    }

    function handleRemoveProtocol(id: string) {
        props.handleRemoveProtocol(id, plan.unselect)
    }

    async function CreateProtocolTemplate() {
        await api
            .post("protocols/create", {
                trainerid: auth.user?.id,
                studentid: props.isTemplates ? null : student?.id,
            })
            .then(reloadList)
            .catch((err) => {
                mostrarAlerta(
                    "Não foi possivel criar protocolo: Função Indisponível! - " +
                        err
                )
            })
    }

    function LoadProtocol(id: string) {
        if (plan.selected?.id === id) {
            plan.setProtocol(null)
            return
        }

        LoadProtocolData(id).then((res) => {
            plan.setProtocol(res)
        })
    }

    return (
        <SideListView
            onGoBack={plan.unselect}
            view={plan.selected ? "CONTENT" : "LIST"}
            content={
                <ProtocolView
                    callbacks={{
                        onChangeTitle: handleChangeTitle,
                        onChangeObs: handleChangeObs,
                        onDelete: handleRemoveProtocol,
                        onDuplicate: props.handleDuplicate,
                        onSaveTemplate: props.handleSaveTemplate,
                        onExport: props.handleExport,
                    }}
                />
            }
            list={
                <div
                    className="flex column"
                    style={{ flex: 1, paddingInline: 5 }}
                >
                    <StudentInfoHeader
                        create={
                            props.isTemplates
                                ? CreateProtocolTemplate
                                : undefined
                        }
                    />
                    {!props.isTemplates && (
                        <div>
                            <HeaderButton onClick={CreateProtocolTemplate}>
                                <MutableIcon
                                    icon={MdOutlineCreateNewFolder}
                                    size={24}
                                />
                                <p>Criar Plano</p>
                            </HeaderButton>
                            <span style={{ margin: 2.5 }} />
                            <HeaderButton onClick={props.handleImport}>
                                <MutableIcon icon={BiCloudDownload} size={24} />
                                <p>Importar Plano</p>
                            </HeaderButton>
                        </div>
                    )}
                    {props.loadingList ? (
                        <div className="flex centered">
                            <ThreeDots
                                height="60"
                                width="60"
                                color="#000"
                                ariaLabel="three-dots-loading"
                                visible={true}
                            />
                        </div>
                    ) : (
                        <ProtocolsList
                            list={props.list ?? []}
                            selected={plan.selected ?? undefined}
                            templates={props.isTemplates}
                            onUpdateList={reloadList}
                            onSetSelected={(index: number, p: ProtocolType) =>
                                LoadProtocol(p.id)
                            }
                            onUnselect={plan.unselect}
                            onDelete={handleRemoveProtocol}
                            onDuplicate={props.handleDuplicate}
                            onSaveTemplate={props.handleSaveTemplate}
                            onExport={props.handleExport}
                        />
                    )}
                </div>
            }
        />
    )
}

export default View
