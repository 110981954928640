import styled from "styled-components"

export const Content = styled.div`
    flex: 1;
    width: 100%;
    padding: 25px;
    align-items: center;
    flex-direction: column;

    p {
        text-transform: uppercase;

        font-weight: bold;
    }
`

export const AreaContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 10px;
`

export const Area = styled.textarea`
    outline: none;
    background-color: #ddd;
    border-radius: 5px;
    border: 1px solid #888;

    flex: 1;
    resize: none;
    padding: 10px;
`

export const Wrapper = styled.div`
    flex: 1;
    flex-basis: 0;
    overflow: hidden;

    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`
