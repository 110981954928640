import moment from "moment"
import Equations from "./fold-equations"
import { PerimetryList } from "./perimetry"
import { SkinFoldsList } from "./folds"
import { BonesList } from "./bones"
import { AssessmentClass } from "./assessment"

export function GetBoneMass(height: number, bones: BonesList) {
    if (!bones.femur || !bones.fist) return 0

    return (
        3.02 *
        Math.pow(
            Math.pow(height, 2) *
                (bones.femur / 100) *
                (bones.fist / 100) *
                400,
            0.712
        )
    )
}

interface internalStudent {
    id: string
    name: string
    birthdate: Date
    username: string
    gender: string
    created_at: Date
}

export interface GraphData {
    fat: number
    wastes: number
    muscles: number
    bones?: number
}

export function GetAntrophoGraphData(
    data: AssessmentClass,
    gender: string,
    age: number
): GraphData {
    if (!data.height) return { fat: 1, wastes: 1, muscles: 1 }
    if (!data.weight) return { fat: 1, wastes: 1, muscles: 1 }

    const wasteByGender =
        (gender === "Male" ? 24.1 / 100 : 20.9 / 100) * data.weight

    const _bones = GetBoneMass(data.height, data.content.bones)

    const fat = Equations[data.content.protocol].equation({
        ...data,
        age,

        gender,
    })
    const bones = _bones === 0 ? undefined : _bones
    const wastes = wasteByGender
    const muscles = data.weight - (fat + _bones + wastes)

    return { fat, bones, wastes, muscles } as GraphData
}
