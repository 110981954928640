import React from "react"
import { Apex, ChartWrapper, ChartWrapperAssessment } from "./styles"
import { GraphData } from "src/modules/fithub-shared/anthropometry/antrophometry"

interface DonutChartProps {
    data: GraphData
    fontSize?: number
}

const DonutChartAssessment: React.FC<DonutChartProps> = ({ data }) => {
    const colors4: string[] = ["#FF004F", "#00C372", "#175ddf"]
    const labels4 = ["Gordura (Kg)", "Residual (Kg)", "Músculos e Ossos (Kg)"]

    const _data: Array<number> = [data.fat, data.wastes, data.muscles].map(
        (e) => (e = parseFloat(e.toFixed(2)))
    )

    if (data.bones) {
        _data.push(parseFloat(data.bones.toFixed(2)))
        labels4.push("Ossos (Kg)")
        labels4[2] = "Músculos (Kg)"
        colors4[2] = "#7625F5"
        colors4.push("#FFB800")
    } else {
        labels4[2] = "Músculos e Ossos (Kg)"
    }

    return (
        <ChartWrapperAssessment className="donut-chart">
            <Apex
                style={{ backgroundColor: "transparent", width: "100%" }}
                type="donut"
                height={"200px"}
                series={_data}
                labels={labels4}
                options={{
                    chart: { type: "donut" },
                    colors: colors4,
                    legend: {
                        position: "top",
                    },
                    labels: labels4,
                    plotOptions: {
                        pie: {
                            donut: {
                                size: "20%", // Ajuste este valor para alterar o raio interno
                            },
                        },
                    },
                }}
            />
        </ChartWrapperAssessment>
    )
}

export default DonutChartAssessment
