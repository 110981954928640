import React, { createContext, useContext, useEffect, useState } from "react"
import { UseQueryResult } from "react-query"
import api from "src/API/rest"
import useUndoState, { EventType } from "src/components/useUndoState"
import { ProtocolType } from "src/modules/fithub-shared/interfaces/protocol"
import { Workout } from "src/modules/fithub-shared/interfaces/workout"

interface PlanContextProps {
    plan: {
        selected: ProtocolType | null
        setProtocol(plan: ProtocolType | null): void
        update(plan: ProtocolType): void
        unselect(): void
        history?: EventType<ProtocolType>
    }
    workout: {
        selected: Workout | null
        setSelected(plan: Workout | null): void
        update(w: Workout): void
        unselect(): void
        history?: EventType<Workout>
    }
    save(before?: () => void): Promise<void>
    reloadList: () => void
}

const PlanContext = createContext<PlanContextProps | undefined>(undefined)

const PlanContextProvider = ({
    children,
    list,
}: {
    children?: React.ReactNode
    list: UseQueryResult<ProtocolType[], unknown>
}) => {
    const [protocol, setProtocol] = useState<ProtocolType | null>(null)
    const [workout, setWorkout] = useState<Workout | null>(null)

    useEffect(() => {
        console.log("Alguma coisa afetou o PlanContextProvider")
    }, [])

    async function saveProtocol(before?: () => void) {
        if (before) await before()
        return api
            .post("/protocols/save", protocol)
            .then(() => {
                list.refetch()
            })
            .catch((err) => {
                console.error(err)
            })
    }

    function updateProtocol(modified: ProtocolType) {
        setProtocol(modified)
    }

    function updateWorkout(modified: Workout) {
        const prot = { ...protocol } as ProtocolType

        const index = prot.workouts?.findIndex((w) => w.id === modified.id)

        if (index !== -1 && prot.workouts) {
            prot.workouts[index] = modified

            setWorkout(modified)
            updateProtocol(prot)
        }
    }

    const unselectProtocol = () => {
        setProtocol(null)
        setWorkout(null)
    }
    const unselectWorkout = () => setWorkout(null)

    return (
        <PlanContext.Provider
            value={{
                plan: {
                    selected: protocol,
                    setProtocol: setProtocol,
                    unselect: unselectProtocol,
                    update: updateProtocol,
                    //history: pEvents,
                },
                workout: {
                    selected: workout,
                    setSelected: setWorkout,
                    unselect: unselectWorkout,
                    update: updateWorkout,
                    //history: wEvents,
                },
                save: saveProtocol,
                reloadList: list.refetch,
            }}
        >
            {children}
        </PlanContext.Provider>
    )
}

const usePlan = () => {
    const context = useContext(PlanContext)
    if (!context) {
        throw new Error("usePlan must be used within a PlanContextProvider")
    }
    return context
}

export { PlanContextProvider, usePlan }
