import styled from "styled-components"

export const Select = styled.select`
    position: absolute;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;

    border: none;

    outline: none;

    color: transparent;

    option {
        padding: 10px;
        color: black;
    }
`

export const Icon = styled.div`
    position: absolute;
    display: flex;
    height: 23px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    justify-content: center;
    align-items: center;

    user-select: all;

    p {
        position: absolute;
        right: 2.5px;
        bottom: 5px;
        font-weight: bold;
    }
`

export const Background = styled.div`
    position: relative;

    height: 25px;

    border-radius: 5px;

    background-color: transparent;
    border: 1px solid #888;

    overflow: hidden;

    input,
    h3 {
        font-size: 12px;
        width: 100%;
        height: 100%;
        box-shadow: none;
        font-weight: bold;
        text-align: center;
        background-color: transparent;
        border-radius: 0px;
        transition: inherit;
        padding: 0px;
    }

    input:not(:last-child) {
        border-right: 1px solid #888;
    }

    h3:not(:last-child) {
        border-right: 1px solid #333;
    }

    &:focus-within {
        background-color: #fff; // Change this to the desired color
        border: 1px solid #333;
    }

    h3 {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
`

export const ContentHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;

    margin-bottom: 2px;
    padding-inline: 1px;

    background-color: #ccc;
    border: 1px solid #ccc;

    border-radius: 2px;

    height: 15px;
    align-items: center;
    justify-content: center;

    h1 {
        color: #444;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &:focus-within {
        color: black;
        border: 1px solid black;
    }

    h2 {
        font-size: 10px;
        padding: 2.5px;

        font-weight: bold;
        text-transform: uppercase;
        color: #444;
    }
`
