export class userData {
    id = ""
    name = ""
    name_pj = ""
    permission = "default"
    photourl: string | undefined

    constructor(user?: userData) {
        if (!user) return
        this.name = user.name
        this.id = user.id
        this.name_pj = user.name_pj
        this.photourl = user.photourl ?? undefined
        this.permission = user.permission
    }
}
