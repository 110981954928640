import React from "react"
import MutableIcon, { mutableIcon } from "../mutable-icon"
import { isMobile } from "react-device-detect"

const MobileOnlyButton: React.FC<mutableIcon> = (props) => {
    const mobile = isMobile || window.screen.width < 768

    return mobile ? <MutableIcon {...props} /> : <div />
}

export default MobileOnlyButton
