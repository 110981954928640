import React, { useContext, useState } from "react"
import { PlanContextProvider } from "../../context"
import { ProtocolType } from "src/modules/fithub-shared/interfaces/protocol"
import { useQuery } from "react-query"
import View from "../View"
import { useStudent } from "src/contexts/student"
import { GetPlanTemplates } from "../../cache/protocol-manager"
import AuthContext from "src/contexts/auth"
import api from "src/API/rest"
import mostrarAlerta from "src/components/utils/alert"
import Dialog from "src/components/dialog"
import Modal from "src/components/modal"
import StudentsList from "src/screens/students-list"
import { Student } from "src/screens/student-info"
import TemplatesBrowser from "../../components/templates-browser"

// import { Container } from './styles';

const PlanTemplates: React.FC = () => {
    const auth = useContext(AuthContext)
    const [remove, setRemove] = useState<string | undefined>(undefined)
    const [exportPlan, setExport] = useState<string>()

    const [importPlan, setImportPlan] = useState(false)

    const { student } = useStudent()

    const list = useQuery<ProtocolType[]>(["List_", "Templates"], () =>
        GetPlanTemplates(auth.user?.id)
    )

    function handleDuplicate(id: string) {
        api.post("protocols/duplicate", {
            id: id,
        })
            .then(() => {
                list.refetch()
            })
            .catch((err) => {
                mostrarAlerta("Não foi possivel duplicar protocolo!\n" + err)
            })
    }

    function handleSaveTemplate(id: string) {
        api.post("protocols/template/save", {
            id: id,
        })
            .then((res) => {
                if (res.status === 200) mostrarAlerta("Salvo como template")
                else mostrarAlerta(res.data)
            })
            .catch((err) => {
                mostrarAlerta(err)
            })
    }

    async function _importProtocol(e: string, i: string) {
        if (student)
            await api
                .post("protocols/export", {
                    id: i,
                    student_id: student.id,
                })
                .then(() => list.refetch())
                .catch((err) => {
                    mostrarAlerta(err)
                })
    }

    function _selectStudent(s: Student) {
        if (exportPlan === undefined) return
        if (student && s.id === student.id) {
            mostrarAlerta(
                "Erro! tentando exportando da mesma origem e destino! - // Remover em futura atualização"
            )
            return
        }

        api.post("protocols/export", {
            id: exportPlan,
            student_id: s.id,
        })
            .then(() => {
                mostrarAlerta("Exportado!")
            })
            .catch((err) => {
                mostrarAlerta(
                    "Ocorreu um erro ao processar alterações. Tente novamente!" +
                        err
                )
            })

        setExport(undefined)
    }

    function _RemoveProtocol() {
        api.post("/protocols/delete", { id: remove })
            .then(() => {
                setRemove(undefined)
                list.refetch()
            })
            .catch((err) => {
                mostrarAlerta(JSON.stringify(err))
            })
    }

    return (
        <PlanContextProvider list={list}>
            <View
                student={student?.id}
                list={list.data}
                isTemplates={true}
                loadingList={list.isLoading}
                handleRemoveProtocol={setRemove}
                handleDuplicate={handleDuplicate}
                handleExport={setExport}
                handleSaveTemplate={handleSaveTemplate}
                handleImport={() => setImportPlan(true)}
            />
            {remove && (
                <Dialog
                    active={remove !== undefined}
                    title={"Remover Protocolo"}
                    subtitle={
                        "Gostaria de remover este protocolo permanentemente?"
                    }
                    onConfirm={_RemoveProtocol}
                    onCancel={() => setRemove(undefined)}
                />
            )}
            <Modal
                active={exportPlan ? true : false}
                state={(a) => setExport(a ? exportPlan : undefined)}
            >
                <StudentsList
                    dismiss={() => setExport(undefined)}
                    onSelect={_selectStudent}
                />
            </Modal>

            <TemplatesBrowser
                active={importPlan}
                type="Protocol"
                headerTitle="Importar Plano"
                onSelect={_importProtocol}
                onDismis={setImportPlan}
            />
        </PlanContextProvider>
    )
}

export default PlanTemplates
