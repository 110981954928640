import React, { InputHTMLAttributes, useState } from "react"

// import { Container } from './styles';

export interface ClampCustomInputProps
    extends InputHTMLAttributes<HTMLInputElement> {
    clampMin: number
    clampMax: number
    selectAll?: boolean
}

const ClampedInput: React.FC<ClampCustomInputProps> = (props) => {
    const [value, setValue] = useState(props.value)

    function OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = parseFloat(e.target.value)

        if (isNaN(value)) {
            setValue("")
            return
        }

        if (
            (value <= props.clampMax && value >= props.clampMin) ||
            e.target.value === ""
        ) {
            setValue(value)
            if (props.onChange) props.onChange(e)
        }
    }

    function onFocus(e: React.FocusEvent<HTMLInputElement>) {
        e.target.select()
    }

    return (
        <input {...props} onChange={OnChange} value={value} onFocus={onFocus} />
    )
}

export default ClampedInput
