const version = ":::cachev1"

export function GetCache<T>(tag: string, def?: T) {
    const cached = localStorage.getItem(tag)

    try {
        return cached ? (JSON.parse(cached) as T) : !def ? null : (def as T)
    } catch {
        return !def ? null : (def as T)
    }
}

export function GetCacheCount(prefix: string) {
    let count = 0

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.startsWith(prefix)) {
            flushOld(key)
            count++
        }
    }

    return count
}

export function GetPCache<T>(tag: string, def: T, removeAfter: boolean) {
    const cached = localStorage.getItem(tag)
    if (!cached) return def as T

    try {
        const parsed = JSON.parse(cached) as T
        if (removeAfter === true) localStorage.removeItem(tag)
        return parsed ? parsed : (def as T)
    } catch {
        return def as T
    }
}

export function SetCache(tag: string, content: any) {
    if (content !== undefined) {
        localStorage.setItem(tag, JSON.stringify(content))
    }
}

export function Gentag(strings: Array<string | undefined>) {
    return strings.join("@") + version
}

function flushOld(key: string) {
    if (!key.endsWith(version)) localStorage.removeItem(key)
}
