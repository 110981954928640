import React, { useEffect, useState } from "react"
import { usePlan } from "src/modules/protocols/context"
import ProtocolSetup from "src/modules/protocols/Plan/View/protocol-setup"
import Tabs from "../WorkoutView/Tabs"
import WorkoutView from "../WorkoutView"
import { MdOutlineFolder } from "react-icons/md"
import CreateModal from "../CreateModal"
import {
    MultiWorkout,
    Workout,
} from "src/modules/fithub-shared/interfaces/workout"
import SaveButton from "../SaveButton"
import { BiRedo, BiUndo } from "react-icons/bi"
import MutableIcon from "src/components/mutable-icon"
import { Container } from "src/assets/GlobalStyles"
import { Gentag, GetPCache } from "src/assets/functions/cache"
import WorkoutHeader from "../WorkoutView/Header"
import { HiChevronLeft } from "react-icons/hi"
import MobileOnlyButton from "src/components/MobileOnlyButton"

// import { Container } from './styles';

interface Props {
    callbacks: {
        onChangeTitle: (title: string) => void
        onChangeObs: (obs: string) => void
        onDelete: (id: string) => void
        onDuplicate: (id: string) => void
        onSaveTemplate: (id: string) => void
        onExport: (id: string) => void
    }
}

const ProtocolView: React.FC<Props> = (props) => {
    const { plan, workout, save } = usePlan()
    const [create, setCreate] = useState(false)

    const [currentWorkout, setCurrentWorkout] = useState<Workout>()

    const [tag, setTag] = useState("")
    const [modified, setModified] = useState(localStorage.getItem(tag) !== null)

    useEffect(() => {
        if (workout.selected) {
            const _tag = Gentag([plan.selected?.id, workout.selected?.id])

            setTimeout(() => checkModified(_tag), 200)

            setTag(_tag)
            updateWorkout(workout.selected)
        } else setCurrentWorkout(undefined)
    }, [workout.selected])

    function updateWorkout(values: Workout) {
        checkModified(tag)
        setCurrentWorkout(values)
    }

    function checkModified(tag: string) {
        setModified(localStorage.getItem(tag) !== null)
    }

    function onRenameWorkout(n: string) {
        if (!currentWorkout) return

        workout.update({ ...currentWorkout, title: n } as Workout)
    }

    function onSetObs(n: string) {
        if (!currentWorkout) return

        workout.update({ ...currentWorkout, obs: n } as Workout)
    }

    function Create(w: Workout) {
        if (!plan.selected) return

        const nplan = { ...plan.selected }
        nplan.workouts.push(w)
        plan.update(nplan)
        setCreate(false)
        workout.setSelected(w)
    }

    async function onSaveWorkout(): Promise<void> {
        if (currentWorkout) {
            const nitem = GetPCache<MultiWorkout | null>(tag, null, true)
            if (nitem != null) {
                await workout.update({
                    ...currentWorkout,
                    item: nitem,
                } as Workout)

                localStorage.removeItem(tag)
            }
        }
        return save().then(() => {
            setModified(false)
        })
    }

    if (!plan.selected)
        return (
            <Container className="centered">
                <MdOutlineFolder size={48} />
                <p>SELECIONE UM PROTOCOLO</p>
            </Container>
        )
    else
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "space-between",
                }}
                className="flex column"
            >
                {create ? (
                    <CreateModal create={Create} />
                ) : (
                    <>
                        {currentWorkout ? (
                            <>
                                <WorkoutHeader
                                    workout={currentWorkout}
                                    RenameWorkout={onRenameWorkout}
                                    handleSave={onSaveWorkout}
                                    modified={modified}
                                    unsetModified={() => setModified(false)}
                                    tag={tag}
                                />
                                <WorkoutView
                                    values={currentWorkout}
                                    setValues={updateWorkout}
                                    tag={tag}
                                    setObs={onSetObs}
                                />
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        paddingInline: 10,
                                        height: 80,
                                        alignItems: "center",
                                    }}
                                >
                                    <MobileOnlyButton
                                        icon={HiChevronLeft}
                                        size={40}
                                        onClick={plan.unselect}
                                    />
                                    <SaveButton />
                                </div>
                                <ProtocolSetup
                                    {...props.callbacks}
                                    onChangeTitle={
                                        props.callbacks.onChangeTitle
                                    }
                                    onChangeObs={props.callbacks.onChangeObs}
                                    onDelete={props.callbacks.onDelete}
                                    onDuplicate={props.callbacks.onDuplicate}
                                    onSaveTemplate={
                                        props.callbacks.onSaveTemplate
                                    }
                                    onExport={props.callbacks.onExport}
                                />
                            </>
                        )}
                    </>
                )}
                <Tabs create={create} handleCreate={() => setCreate(!create)} />
            </div>
        )
}

export default ProtocolView
