import { useState, useEffect } from "react"

const useWindowSize = () => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsSmall(true)
            } else {
                setIsSmall(false)
            }
        }

        window.addEventListener("resize", handleResize)

        // Initialize the state on mount
        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return isSmall
}

export default useWindowSize
