import React, { useEffect, useState } from "react"

import {
    Workout,
    WorkoutTypeString,
    WorkoutIcon,
} from "../../../../../fithub-shared/interfaces/workout"
import EditableText from "../../../../../../components/editable-text"
import MutableIcon from "../../../../../../components/mutable-icon"
import { Header, Labels, ModText, UndoTxt } from "./styles"
import useLoadingButton from "src/modules/assessment/useLoadingButton"
import { IoMdCloudDone, IoMdCloudUpload } from "react-icons/io"
import { BiUndo } from "react-icons/bi"
import { useDialog } from "src/contexts/dialog-context"
import { usePlan } from "src/modules/protocols/context"
import mostrarAlerta from "src/components/utils/alert"
import { LoadProtocolData } from "src/modules/protocols/cache/protocol-manager"
import MobileOnlyButton from "src/components/MobileOnlyButton"
import { IconBaseProps } from "react-icons"
import { HiChevronLeft } from "react-icons/hi"
import { MdCheck, MdClose } from "react-icons/md"
import { isMobile } from "react-device-detect"

interface HeaderProps {
    handleSave: () => Promise<void>
    workout: Workout
    RenameWorkout: (t: string) => void
    modified: boolean
    unsetModified: () => void
    tag: string
}

const SaveButton: React.FC<{
    handleSave: () => Promise<void>
}> = ({ handleSave }) => {
    const button = useLoadingButton(
        () => {
            handleSave().then(() => button.changeState("LOADED"))
        },
        1,
        "UNLOADED"
    )

    return <div>{button.Button}</div>
}

export const WorkoutHeader = (props: HeaderProps) => {
    const planContext = usePlan()
    const [workout, setWorkout] = useState<Workout>()
    const dialog = useDialog()

    useEffect(() => {
        if (props.workout.id !== workout?.id) {
            setWorkout(undefined)
            setTimeout(() => setWorkout(props.workout), 5)
        }
    }, [props.workout])

    function UndoChanges() {
        dialog.openDialog(
            "Desfazer Alterações",
            "Esta ação irá descartar quaisquer modificações feitas neste treino para este dispositivo!\n\nGostaria de continuar?",
            () => {
                const currentProtocolId = planContext.plan.selected?.id

                if (!currentProtocolId) return

                LoadProtocolData(currentProtocolId).then((res) => {
                    planContext.plan.unselect()
                    setTimeout(() => {
                        planContext.plan.setProtocol(res)
                        const find =
                            res.workouts.find((e) => e.id === workout?.id) ??
                            null

                        if (find) planContext.workout.setSelected(find)

                        localStorage.removeItem(props.tag)
                        props.unsetModified()
                    }, 5)
                })
            }
        )
    }

    const CacheInfo = () => {
        if (isMobile || window.screen.width < 768) {
            return (
                <>
                    {props.modified ? (
                        <MdClose color="var(--primary)" size={12} />
                    ) : (
                        <MdCheck size={12} />
                    )}
                </>
            )
        } else {
            return <>{props.modified ? "Modificado" : "Sem Modificações"}</>
        }
    }

    if (!workout) return <></>
    return (
        <Header>
            <div
                style={{
                    alignItems: "center",
                }}
            >
                <MutableIcon
                    icon={HiChevronLeft}
                    size={40}
                    onClick={planContext.workout.unselect}
                />
                <MutableIcon
                    icon={WorkoutIcon(props.workout.type)}
                    size={28}
                    color={"#0008"}
                />
                <Labels>
                    <EditableText
                        onChange={props.RenameWorkout}
                        defaultValue={workout?.title as string}
                    />
                    <h5>{WorkoutTypeString(workout?.type)}</h5>
                </Labels>
            </div>

            <div className="flex row" style={{ alignItems: "center" }}>
                {/*workout.history && (
                    <>
                        <MutableIcon
                            icon={BiUndo}
                            disabled={!workout.history.canUndo}
                            size={36}
                            onClick={workout.history.undo}
                        />
                        <MutableIcon
                            icon={BiRedo}
                            disabled={!workout.history.canRedo}
                            size={36}
                            onClick={workout.history.redo}
                        />
                    </>
                )*/}
                <div className="flex row" style={{ alignItems: "center" }}>
                    <ModText modified={props.modified} onClick={UndoChanges}>
                        {props.modified && (
                            <>
                                <BiUndo size={20} />

                                <span style={{ width: 4 }} />
                            </>
                        )}
                        <CacheInfo />
                    </ModText>

                    <SaveButton handleSave={props.handleSave} />
                </div>
            </div>

            {/*<MutableIcon
                onClick={() => {
                    p.DeleteWorkout();
                }}
                icon={MdRemoveCircle}
                size={28}
                color={"#444"}
            />*/}
        </Header>
    )
}

export default WorkoutHeader
