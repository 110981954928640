import React, { useContext, useEffect, useState } from "react"
import { Wrapper } from "../styles"
import AuthContext from "src/contexts/auth"
import {
    useCompareAssessment,
    useListAssessment,
} from "src/modules/assessment/caching/queries"
import StudentContext from "src/contexts/student"
import moment from "moment"
import { SelectAssessment } from "./stlyes"
import ComparisionContent from "./ComparisionContent"
import { HiChevronLeft } from "react-icons/hi"
import IconButton from "src/components/icon-button"
import MutableIcon from "src/components/mutable-icon"
import { useNavigate } from "react-router-dom"

// import { Container } from './styles';

const AssessmentComparision: React.FC = () => {
    const auth = useContext(AuthContext)
    const student = useContext(StudentContext).student

    const navigate = useNavigate()

    const [assessmentA, setAssessmentA] = useState<string>("")
    const [assessmentB, setAssessmentB] = useState<string>("")

    const comparision = useCompareAssessment(assessmentA, assessmentB)

    useEffect(() => {
        Refetch()
    }, [])

    function RefetchA(e: React.ChangeEvent<HTMLSelectElement>) {
        setAssessmentA(e.target.value)
        Refetch()
    }

    function RefetchB(e: React.ChangeEvent<HTMLSelectElement>) {
        setAssessmentB(e.target.value)
        Refetch()
    }

    function Refetch() {
        setTimeout(() => {
            comparision.refetch()
        }, 150)
    }

    const { data, isLoading } = useListAssessment(student?.id, auth.user?.id)

    if (isLoading || !data) return <p>Carregando...</p>
    else
        return (
            <Wrapper
                style={{
                    padding: 10,
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                }}
                className={"fade-in flex row"}
            >
                <div
                    style={{
                        position: "absolute",
                        left: 10,
                        top: 10,
                        zIndex: 10,
                    }}
                >
                    <MutableIcon
                        icon={HiChevronLeft}
                        onClick={() => navigate(-1)}
                        size={48}
                    />
                </div>
                <div
                    style={{
                        overflowY: "scroll",
                        flexDirection: "column",
                        width: "80%",
                    }}
                >
                    <div
                        style={{
                            flexDirection: "row",
                            marginBottom: 20,
                            width: "100%",
                        }}
                    >
                        <SelectAssessment name="a" onChange={RefetchA}>
                            <option value={""}>AVALIAÇÃO A</option>
                            {data.map((e, index) => (
                                <option key={index} value={e.id}>
                                    {e.title} -{" "}
                                    {moment(e.created_at).format("DD/MM/YYYY")}
                                </option>
                            ))}
                        </SelectAssessment>
                        <span style={{ margin: 5 }} />

                        <SelectAssessment name="b" onChange={RefetchB}>
                            <option value={""}>AVALIAÇÃO B</option>
                            {data.map((e, index) => (
                                <option key={index} value={e.id}>
                                    {e.title} -{" "}
                                    {moment(e.created_at).format("DD/MM/YYYY")}
                                </option>
                            ))}
                        </SelectAssessment>
                    </div>

                    {comparision.data && (
                        <ComparisionContent
                            contentA={comparision.data.a}
                            contentB={comparision.data.b}
                        />
                    )}
                </div>
            </Wrapper>
        )
}

export default AssessmentComparision
