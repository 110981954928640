import React, { useContext } from "react"
import {
    Workout,
    WorkoutTypeString,
} from "src/modules/fithub-shared/interfaces/workout"
import { Labels, Tab, TitleWrap } from "../styles"
import { usePlan } from "src/modules/protocols/context"
import { MdDelete } from "react-icons/md"
import MutableIcon from "src/components/mutable-icon"
import { CSSProperties } from "styled-components"
import { CSS } from "@dnd-kit/utilities"
import { useSortable } from "@dnd-kit/sortable"
import { BiCopy, BiCloudUpload, BiTrashAlt } from "react-icons/bi"
import {
    ContextMenuItem,
    useCustomContextMenu,
} from "src/components/UseContextMenu"
import api from "src/API/rest"
import mostrarAlerta from "src/components/utils/alert"
import auth from "src/contexts/auth"
import AuthContext from "src/contexts/auth"

// import { Container } from './styles';
interface DraggableTabProps {
    content: Workout
    modified: boolean
    onClick: () => void
    onDuplicate: (e: Workout) => void
    onDelete: (e: Workout) => void
}

const DraggableTab: React.FC<DraggableTabProps> = (props) => {
    const { workout } = usePlan()
    const auth = useContext(AuthContext)

    const menu = useCustomContextMenu()

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props.content.id,
    })

    const style = {
        minWidth: 100,
        zIndex: isDragging ? 100 : undefined,
        transform: CSS.Transform.toString(transform),
        transition,
    } as CSSProperties

    const isSelected = () => workout.selected === props.content ?? false

    const Duplicate = () => {
        props.onDuplicate(props.content)
    }

    function SaveTemplate() {
        api.post("workout-templates/new", {
            title: props.content.title,
            obs: props.content.obs,
            type: props.content.type,
            item: JSON.stringify(props.content.item),
            created_by: auth.user?.id,
        })
            .then(() => {
                mostrarAlerta("Template Salvo!")
            })
            .catch((err) => {
                mostrarAlerta(err)
            })
    }

    const contextItems: ContextMenuItem[] = [
        {
            title: "Duplicar",
            icon: BiCopy,
            action: Duplicate,
        },
        {
            title: "Salvar como template",
            icon: BiCloudUpload,
            action: SaveTemplate,
        },
        {
            title: "Remover",
            icon: BiTrashAlt,
            action: () => props.onDelete(props.content),
        },
    ]

    function handleContextMenu(e: React.MouseEvent<HTMLDivElement>) {
        e.preventDefault()

        menu.showContextMenu(e, contextItems)
    }

    return (
        <>
            <menu.ContextMenu />
            <Tab
                ref={setNodeRef}
                isDragging={isDragging}
                style={style}
                onContextMenu={handleContextMenu}
                selected={isSelected()}
                {...attributes}
                {...listeners}
            >
                <Labels onClick={props.onClick}>
                    <TitleWrap title={props.content.id}>
                        <h1>
                            {props.modified && "*"}
                            {props.content.title}
                        </h1>
                    </TitleWrap>

                    <span />
                    <h2>{WorkoutTypeString(props.content.type)}</h2>
                </Labels>

                {isSelected() && (
                    <div
                        style={{
                            width: 25,
                            alignItems: "center",
                            flexShrink: 0,
                        }}
                    >
                        <MutableIcon
                            icon={MdDelete}
                            size={22}
                            onClick={() => props.onDelete(props.content)}
                        />
                    </div>
                )}
            </Tab>
        </>
    )
}

export default DraggableTab
