import { ExerciseTemplate } from "../../fithub-shared/interfaces/templates"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : "--"
    })
}

export enum MaskType {
    NORMAL,
    MASK_ALL,
}

export const FilterAndSort = (list: ExerciseTemplate[], search: string) => {
    return list && search.length > 0
        ? list
              .filter((e) =>
                  e.name.toUpperCase().includes(search.toUpperCase())
              )
              .sort((a, b) => {
                  const key = search.toLowerCase()
                  const isGoodMatchA = a.name.toLowerCase().startsWith(key)
                  const isGoodMatchB = b.name.toLowerCase().startsWith(key)

                  if (isGoodMatchA !== isGoodMatchB) {
                      // XOR
                      return isGoodMatchA ? -1 : 1
                  }

                  return a.name.localeCompare(b.name)
              })
        : list
}

function GetHoursOffset() {
    const date = new Date() // Cria um objeto Date com a data e hora atuais
    const timeZoneOffsetInMinutes = date.getTimezoneOffset() // Obtém o offset do fuso horário em minutos
    const timeZoneOffsetInHours = timeZoneOffsetInMinutes / 60

    return timeZoneOffsetInHours
}

export const parseDateTimeLocal = (value: Date | null) => {
    if (value === null) return undefined

    const v = typeof value === "object" ? new Date(value) : value

    console.log(
        JSON.stringify(typeof v) +
            " / " +
            JSON.stringify(v) +
            " / YYYY-MM-DDTHH:mm"
    )
    console.log("Timezone? " + GetHoursOffset())

    return undefined
}

export const parseISODate = (value: Date | null) => {
    return value ? new Date(value).toISOString().split("T")[0] : undefined
}

export const getTomorrow = () => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + 1)
    return currentDate.toISOString().split("T")[0]
}

export const maskString = (
    str: string | undefined,
    pattern: string,
    maskAll = MaskType.NORMAL
) => {
    if (str === undefined) return "0"

    if (maskAll === MaskType.MASK_ALL) {
        const padded = pattern.replace(/#/, str === undefined ? "_" : str)
        return padded
    } else {
        let i = 0
        const padded = pattern.replace(/#/g, () => {
            if (str[i] !== undefined) return str[i++]
            else return "_"
        })
        return padded
    }
}
