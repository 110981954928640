import React, { useContext } from "react"
import { Route } from "react-router-dom"
import { StyledRoutes } from "src/assets/GlobalStyles"
import Header from "src/components/header"
import AuthContext from "src/contexts/auth"
import Anamnese from "src/screens/anamnese"
import Assessment from "src/modules/assessment"
import StudentInfo from "src/screens/student-info"
import ExerciseTemplates from "src/screens/templates/exercise"
import WorkoutTemplates from "src/screens/templates/workout"
import { StudentProvider } from "src/contexts/student"
import AssessmentComparision from "src/modules/assessment/AssessmentComparision"
import { DrawerProvider } from "src/contexts/drawer-context/Drawer"
import DashboardGrid from "src/screens/dashboardGrid"
import { LogoutRoute } from "./SignedRoutes"
import CalendarScreen from "src/screens/calendar"
import ChargeProgression from "src/screens/ChargeProgression"
import Plan from "src/modules/protocols/Plan"
import PlanTemplates from "src/modules/protocols/Plan/Templates"
import { DialogProvider } from "src/contexts/dialog-context"
import StudentSettings from "src/screens/student-info/Settings"
import Checkout from "src/screens/checkout"

// import { Container } from './styles';
export const SignedRoutes: React.FC = () => {
    const context = useContext(AuthContext)

    if (!context.user) {
        return <p>Sessão Inválida!</p>
    } else
        return (
            <StudentProvider>
                <DialogProvider>
                    <DrawerProvider>
                        {context.user && <Header {...context.user} />}
                        <StyledRoutes>
                            <Route path="/" element={<DashboardGrid />} />
                            <Route path="/student" element={<StudentInfo />} />
                            <Route
                                path="/calendar"
                                element={<CalendarScreen />}
                            />

                            <Route
                                path="/tools/exercises"
                                element={<ExerciseTemplates />}
                            />
                            <Route
                                path="/tools/workout"
                                element={<WorkoutTemplates />}
                            />
                            <Route
                                path="/tools/plans"
                                element={<PlanTemplates />}
                            />
                            <Route
                                path="/student/workouts"
                                element={<Plan />}
                            />

                            <Route
                                path="/student/settings"
                                element={<StudentSettings />}
                            />

                            <Route
                                path="/student/progression"
                                element={<ChargeProgression />}
                            />

                            <Route
                                path="/student/assessments"
                                element={<Assessment />}
                            />
                            <Route
                                path="/student/assessments/compare"
                                element={<AssessmentComparision />}
                            />
                            <Route
                                path="/tools/anamnese"
                                element={<Anamnese />}
                            />
                            <Route path="checkout" element={<Checkout />} />
                            <Route path="/logout" element={<LogoutRoute />} />
                        </StyledRoutes>
                    </DrawerProvider>
                </DialogProvider>
            </StudentProvider>
        )
}
