import { User } from "firebase/auth"
import Cookies from "js-cookie"
import React, { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "src/API/rest"
import { userData } from "src/assets/caching/user-cache"

interface GUser {
    user: User | null
    setUser: (value: User | null) => void
}

interface AuthContextData {
    signed: boolean
    user?: userData
    Login(user: string, password: string): Promise<string>
    LoginGoogle(email: string, photoURL: string): Promise<number>
    Logout(): void
    GSignIn: GUser
}

const AuthContext = createContext({} as AuthContextData)

interface Props {
    signedComponent: React.ReactNode
    unsignedComponent: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({
    signedComponent,
    unsignedComponent,
}) => {
    const nav = useNavigate()

    const [guser, setGUser] = useState<User | null>(null)

    function parse(data: string | undefined) {
        return data === undefined ? null : (JSON.parse(data) as userData)
    }

    const [user, setUser] = useState<userData | undefined>(
        parse(Cookies.get("@auth:cookie")) ?? undefined
    )

    useEffect(() => {
        CheckSession()
    }, [])

    function CheckSession() {
        const user = parse(Cookies.get("@auth:cookie")) ?? undefined
        if (user === undefined) Logout()
    }

    function LoginGoogle(email: string, photoURL: string): Promise<number> {
        return new Promise((resolve, reject) => {
            api.post<userData>(`/login/google`, {
                email,
                photoURL,
            })
                .then((res) => {
                    if (res.status === 200) {
                        const user = new userData(res.data)
                        setUser(user)

                        Cookies.set("@auth:cookie", JSON.stringify(user), {
                            expires: 3,
                        })

                        resolve(res.status)
                    } else {
                        resolve(res.status)
                    }
                })
                .catch((err) => {
                    alert(err)
                    reject(400)
                })
        })
    }

    function Login(user: string, password: string): Promise<string> {
        return new Promise((resolve, reject) => {
            api.post<userData>(`/login`, {
                email: user,
                password: password,
            })
                .then((res) => {
                    if (res.status === 200) {
                        const user = new userData(res.data)
                        setUser(user)

                        Cookies.set("@auth:cookie", JSON.stringify(user), {
                            expires: 3,
                        })

                        resolve("Entrando...")
                    } else {
                        resolve("Email ou senha inválidos!")
                    }
                })
                .catch((err) => {
                    reject(`Tempo Expirado!\n${err}`)
                })
        })
    }
    async function Logout() {
        Cookies.remove("@auth:cookie")
        setUser(undefined)
        nav("/")
    }

    return (
        <AuthContext.Provider
            value={{
                signed: Boolean(user),
                user,
                Login,
                LoginGoogle,
                Logout,
                GSignIn: { user: guser, setUser: setGUser },
            }}
        >
            {Boolean(user) && user ? signedComponent : unsignedComponent}
        </AuthContext.Provider>
    )
}

export default AuthContext
