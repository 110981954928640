import api from "../../API/rest"

/* eslint-disable @typescript-eslint/no-explicit-any */

export async function ExerciseExists(id: string) {
    if (id === undefined) return null

    return await api
        .post(`/exercises/exists`, { id: id })
        .then((res) => {
            return res.data as string
        })
        .catch(() => {
            return null
        })
}
