import React, { useState, useCallback, memo } from "react"
import styled from "styled-components"
import { RotatingLines } from "react-loader-spinner"
import { CgCheck } from "react-icons/cg"

interface ButtonProps {
    buttonState: "UNLOADED" | "LOADING" | "LOADED"
}

const Button = styled.button<ButtonProps>`
    height: 35px;
    margin-left: 10px;
    width: ${({ buttonState }) =>
        buttonState === "LOADED" || buttonState === "LOADING"
            ? "35px"
            : "auto"};

    border-radius: 10px;
    background-color: ${({ buttonState }) =>
        buttonState === "LOADING" || buttonState === "LOADED"
            ? "gray"
            : "green"};
    color: white;
    border: none;
    cursor: ${({ buttonState }) =>
        buttonState === "LOADING" ? "not-allowed" : "pointer"};
    display: flex;
    justify-content: center;
    align-items: center;

    transition: width 1s ease;
`

const ButtonComponent: React.FC<{
    onClick: () => void
    buttonState: "UNLOADED" | "LOADING" | "LOADED"
}> = (props) => {
    return (
        <Button onClick={props.onClick} buttonState={props.buttonState}>
            <div
                style={{
                    display:
                        props.buttonState === "UNLOADED" ? "block" : "none",
                }}
            >
                <p style={{ paddingInline: 10 }}>SALVAR</p>
            </div>

            <div
                style={{
                    display: props.buttonState === "LOADING" ? "block" : "none",
                }}
            >
                <RotatingLines strokeColor="#fff" width="15px" />
            </div>
            <div
                style={{
                    display: props.buttonState === "LOADED" ? "block" : "none",
                }}
            >
                <CgCheck size={30} />
            </div>
        </Button>
    )
}

type state = "UNLOADED" | "LOADING" | "LOADED"

const useLoadingButton = (
    onClick: () => void,
    reactivate?: number,
    initialState?: state
) => {
    const [buttonState, setButtonState] = useState<state>(
        !initialState ? "LOADED" : initialState
    )

    const changeState = useCallback(
        (state: state) => {
            if (buttonState === state) return
            setButtonState(state)
        },
        [buttonState]
    )

    const handleClick = useCallback(() => {
        if (buttonState === "UNLOADED") {
            setButtonState("LOADING")
            onClick()
            if (reactivate != null) {
                const timeoutId = setTimeout(() => {
                    setButtonState("UNLOADED")
                    clearTimeout(timeoutId)
                }, reactivate * 1000)
            }
        }
    }, [buttonState, onClick, reactivate])

    return {
        Button: (
            <ButtonComponent onClick={handleClick} buttonState={buttonState} />
        ),
        changeState,
    }
}

export default useLoadingButton
