import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    zoom: 0.95;

    border-radius: 5px;

    style {
        display: none;
    }
`

export const FeedbackArea = styled.div`
    flex-direction: column;

    > button {
        background-color: #0001;
        border: 1px solid #888;
        border-radius: 20px;
        justify-content: space-between;
        padding: 5px;
        width: 170px;
        align-items: center;
    }

    .icon {
        transition: transform 200ms;
    }

    .icon.up {
        transform: rotateZ(-180deg);
    }

    .icon.down {
        transform: rotateZ(0deg);
    }
`

export const Baloon = styled.div`
    width: 100%;
    height: 30px;
    padding: 5px;
    flex-direction: row;

    align-items: center;

    p {
        flex: 1;
        font-size: 12px;
    }

    h4 {
        padding-right: 10px;
        font-size: 12px;
        font-weight: bold;
    }

    justify-content: space-between;

    :nth-child(odd) {
        background-color: #0001;
    }
`

export const Name = styled.h5`
    background-color: #aaa;
    border-radius: 5px;
    padding: 5px;
    color: #1d1d1d;
    font-weight: bold;
    font-size: 12px;
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    span {
        padding: 5px;
    }
`
