import styled from "styled-components"

import { FaRegClock } from "react-icons/fa"

interface item {
    selected: boolean
}

export const ClockBackground = styled.div`
    width: 40px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background-color: #888;
    border-radius: 50px;
`

export const Background = styled.div<item>`
    position: relative;
    font-weight: ${(p) => (p.selected ? "400" : "700")};
    padding: 0px;

    background-color: ${(p) => (p.selected ? "#bbb" : "white")};
    margin-block: 4px;
    border-radius: 8px;

    justify-content: space-between;
    align-items: center;

    height: 55px;
    width: 100%;

    padding-left: 5px;

    h5 {
        opacity: 0.8;
    }

    span {
        all: unset;
        width: 0px;
    }

    :hover {
        background-color: ${(p) => (p.selected ? "#bbb" : "#eee")};
    }

    border: 1px solid ${(p) => (p.selected ? "#444" : "#ccc")};
`

export const ToggleArea = styled.div`
    padding-inline: 5px;
    align-items: center;
`

export const ClickableBackground = styled.div`
    all: unset;
    overflow: hidden;
    display: flex;
    flex: 1;
    height: 100%;
    padding-right: 5px;
    padding-left: 5px;

    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
`

export const InfoWrapper = styled.div`
    display: flex; /* Troquei flex-direction por display flex */
    flex-direction: row;

    h5 {
        min-width: 0; /* Certifique-se de que o min-width não force a largura */
        font-size: 12px;
        color: black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; /* Adicione esta linha para esconder o overflow */

        strong {
            color: #555;
        }
    }
`

export const Horizontal = styled.div<{ autosaved: boolean }>`
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    font-weight: normal;

    :first-child {
        padding-top: 7px;
    }
    :last-child {
        padding-bottom: 7px;
    }

    h4 {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        width: 100%;
        min-width: 0; /* Add this line */
        font-size: 13px;
        color: #000;
    }

    div {
        align-items: center;
        justify-content: end;

        span {
            margin: 2px;
        }
    }

    p {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 10px;
        font-weight: 700;
        width: 100%;
    }
`

export const Icon = styled(FaRegClock)`
    position: absolute;
    align-self: center;

    fill: white;

    aspect-ratio: 1;
    height: 20px;
`
