import React, { useState, useEffect } from "react"
import { RotatingLines } from "react-loader-spinner"
import { Button } from "./styles"
import useWindowSize from "../SideListView/useWindowSize"

export const SaveButton: React.FC<{ onSave: () => void; saved: boolean }> = (
    props
) => {
    const [loading, setLoading] = useState(false)

    const isSmall = useWindowSize()

    function Save() {
        props.onSave()
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    return (
        <Button
            className={loading ? "loading" : "loaded"}
            disabled={props.saved}
            color={props.saved ? "black" : "green"}
            onClick={Save}
        >
            {loading ? (
                <RotatingLines strokeColor="#fff" width="25px" />
            ) : (
                <p>{props.saved ? "Salvo!" : isSmall ? "SALVAR" : "SALVAR"}</p>
            )}
        </Button>
    )
}
