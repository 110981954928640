import React, { ChangeEvent, useState } from "react"
import { Cell } from "src/modules/fithub-shared/data"
import { Background, ContentHeader, Select } from "./styles"

import {
    ExerciseFields,
    ExerciseValue,
} from "src/modules/fithub-shared/interfaces/exercise"
import { CgChevronDown } from "react-icons/cg"

// import { Container } from './styles';

interface Props {
    mnax: ExerciseFields
    maxNums?: number
    dragging: boolean
    cell: Cell
    inputProps: React.HTMLProps<HTMLInputElement>
    series?: number
    defaultValues: ExerciseValue
    onChange?: (value: ExerciseValue) => void
    enableSelectTab: boolean
}

const DragInput: React.FC<Props> = ({
    dragging,
    inputProps,
    cell,
    maxNums,
    series,
    onChange,
    defaultValues,
    enableSelectTab,
}) => {
    const [option, setOption] = useState(defaultValues.option)

    const placeholder = inputProps.placeholder

    const clampedSeries = (value?: number) =>
        Math.max(1, Math.min(value ?? 0, 9))

    const getDefault = (index: number) => {
        const value = defaultValues.value[index]
        return value === undefined || isNaN(value) ? 0 : value
    }

    const generateInputs = (numInputs: number) => {
        return new Array(numInputs).fill(0).map((_, i) => {
            if (dragging) return <h3>{defaultValues.value[i]}</h3>
            else
                return (
                    <input
                        key={i}
                        data-index={i}
                        autoComplete="false"
                        autoCorrect="false"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={maxNums ?? 3}
                        defaultValue={getDefault(i)}
                        onFocus={(e) => e.target.select()}
                        onBlur={onChangeInput}
                        {...inputProps}
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "")
                            e.target.value = value
                        }}
                    />
                )
        })
    }

    const MultiInputs = () => {
        const desc = cell.data[option].description.substring(0, 45)

        switch (cell.data[option].field_type) {
            default:
                return generateInputs(1)
            case 1:
                return generateInputs(4)
            case 2:
                return generateInputs(2)
            case 3:
                return (
                    <div
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: 10,
                            height: "100%",
                            textAlign: "center",
                        }}
                    >
                        <p
                            title={cell.data[option].description}
                            style={{
                                fontSize: 9,
                                fontWeight: "bold",
                                fontStyle: "italic",
                                color: "#000",
                            }}
                        >
                            {`${desc ?? "..."}${
                                desc.length >= 45 ? "..." : ""
                            }`}
                        </p>
                    </div>
                )
            case 4:
                return generateInputs(clampedSeries(series || 1))
        }
    }

    const onChangeInput = (e: React.FocusEvent<HTMLInputElement>) => {
        const index = e.target.getAttribute("data-index")
        if (index !== null) {
            const values = { ...defaultValues }
            if (values.value[parseInt(index)] != parseInt(e.target.value)) {
                values.value[parseInt(index)] = parseInt(e.target.value)
                if (onChange) onChange({ ...values })
            }
        }
    }

    const handleChangeOption = (option: number) => {
        if (onChange) onChange({ ...defaultValues, option })
        setOption(option)
    }

    return (
        <div className="column">
            <ContentHeader style={{ width: "100%" }} className="flex row">
                <h1>{cell.data[option].title ?? placeholder} </h1>

                <CgChevronDown size={12} />

                <Select
                    tabIndex={enableSelectTab ? 1 : -1}
                    value={option}
                    onChange={(e) => handleChangeOption(e.target.selectedIndex)}
                    onFocus={(e) => {
                        if (enableSelectTab)
                            e.target.dispatchEvent(new MouseEvent("mousedown"))
                    }}
                >
                    {cell.data.map((element, index) => {
                        return (
                            <option
                                key={index}
                                value={index}
                                title={element.description}
                            >
                                {element.title}
                            </option>
                        )
                    })}
                </Select>
            </ContentHeader>

            <Background>{MultiInputs()}</Background>
        </div>
    )
}

export default DragInput
