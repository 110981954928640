import styled from "styled-components"

export const Button = styled.button<{ color: string }>`
    height: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    transition: all 0.05s;

    :enabled {
        background-color: ${(p) => p.color};
    }

    :disabled {
        background-color: #aaa;
    }

    :hover:enabled {
        transform: scale(0.95);
    }

    :active:enabled {
        transform: scale(0.9);
    }

    p {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`
