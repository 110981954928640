import { GraphData } from "./antrophometry"
import { BonesList } from "./bones"
import { SkinFoldsList } from "./folds"
import { PerimetryList } from "./perimetry"

export interface AssessmentClass {
    id: string
    title: string
    height: number
    weight: number
    content: AssessmentObject
    created_at: Date
    created_by: string
    graph: GraphData
}

export class AssessmentObject {
    protocol = 0
    perimetry: PerimetryList = {}
    folds: SkinFoldsList = {}
    bones: BonesList = {}
}
