import React, { useEffect, useState } from "react"
import {
    Background,
    ClickableBackground,
    ClockBackground,
    Horizontal,
    InfoWrapper,
} from "./styles"

import moment from "moment"
import { ProtocolType } from "../../../fithub-shared/interfaces/protocol"
import {
    BiCheck,
    BiCloud,
    BiCloudUpload,
    BiCopy,
    BiExport,
    BiTrashAlt,
    BiX,
} from "react-icons/bi"
import { GetLocal } from "../../cache/protocol-manager"
import mostrarAlerta from "../../../../components/utils/alert"
import { useCustomContextMenu } from "src/components/UseContextMenu"
import { FaCalendar, FaClock } from "react-icons/fa"
import api from "src/API/rest"
import { PiFileFill } from "react-icons/pi"
import { GetCacheCount } from "src/assets/functions/cache"
import { CgLock } from "react-icons/cg"
import { FiClock } from "react-icons/fi"

interface ItemProps {
    index: number
    selected: boolean
    onUpdate: () => void
    onSelected: () => void
    onUnselect: () => void
    onRemove: (id: string) => void
    onDuplicate: (id: string) => void
    onExport: (id: string) => void
    onSaveTemplate: (id: string) => void
    item: ProtocolType
    template: boolean
}

const ProtocolItem: React.FC<ItemProps> = (props) => {
    const { enable, disable, active } = props.item

    const [schedule, setSchedule] = useState(
        props.item.enable && new Date(props.item.enable)
    )

    useEffect(() => {
        console.log("ref")
    }, [enable, disable, active])

    const { showContextMenu, closeContextMenu, ContextMenu } =
        useCustomContextMenu()

    const contextItems = [
        { title: "Duplicar", icon: BiCopy, action: Duplicate },
        { title: "Exportar", icon: BiExport, action: Export },
        {
            title: "Salvar como Template",
            icon: BiCloudUpload,
            action: SaveTemplate,
        },
        { title: "Deletar", icon: BiTrashAlt, action: Delete },
    ]

    function OnClick() {
        props.onSelected()
    }

    function Delete() {
        closeContextMenu()
        props.onRemove(props.item.id)
    }

    function Export() {
        closeContextMenu()
        props.onExport(props.item.id)
    }

    function Duplicate() {
        closeContextMenu()
        props.onDuplicate(props.item.id)
    }

    function SaveTemplate() {
        if (!GetLocal(props.item.id)) {
            props.onSaveTemplate(props.item.id)
        } else {
            mostrarAlerta("Este protocolos possui modificações não salvas!")
            //mostrarAlerta("Este protocolos possui modificações não salvas!")
        }

        closeContextMenu()
    }

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        showContextMenu(e, contextItems)
    }

    async function onSetActive() {
        await api
            .post("/protocols/toggle", {
                status: !active,
                id: props.item.id,
            })
            .then(() => {
                mostrarAlerta(
                    `Plano de treino foi ${
                        !active ? "ativado" : "desativado"
                    } ao usuário`
                )
            })
            .catch(() => {
                mostrarAlerta(
                    "Ocorreu um erro ao processar alterações. Tente novamente!"
                )
            })
    }

    const scheduled = (): boolean => {
        if (schedule && !props.item.active) {
            return schedule.getTime() > Date.now()
        } else {
            return false
        }
    }

    const Indicator = () => {
        const enableTime = new Date(enable ?? "").getTime() > Date.now()

        return !active && enable && enableTime ? (
            <div
                style={{
                    alignItems: "center",
                    paddingBottom: 1,
                }}
            >
                <FiClock color="white" size={21} />
                <BiCheck color="white" size={16} />
            </div>
        ) : active ? (
            <BiCheck color="lightgreen" size={24} />
        ) : (
            <BiX color="red" size={24} />
        )
    }

    const count = GetCacheCount(props.item.id)

    const remaining = () => {
        if (active && disable) {
            const daysRemaining = moment(disable).diff(Date.now(), "days")
            return `, ${daysRemaining} dias restantes`
        } else if (active === false && enable) {
            const daysUntilActive = moment(enable).diff(Date.now(), "days")
            return `, ativa em ${daysUntilActive} dias`
        } else return undefined
    }

    return (
        <Background selected={props.selected} onContextMenu={handleContextMenu}>
            <ContextMenu />

            {props.template ? (
                <BiCloud color="#444" size={24} />
            ) : (
                <div style={{ position: "relative" }}>
                    <ClockBackground>{Indicator()}</ClockBackground>
                </div>
            )}

            <ClickableBackground onClick={OnClick}>
                <Horizontal autosaved={count > 0}>
                    <h4>{props.item.title}</h4>

                    <div>
                        <p>{props.item.workouts_count}</p>
                        <span />
                        <PiFileFill color="#555" size={12} />
                    </div>
                </Horizontal>

                <Horizontal autosaved={count > 0}>
                    <InfoWrapper>
                        <h5
                            style={{
                                color: count > 0 ? "var(--primary)" : "#888",
                            }}
                        >
                            {count > 0
                                ? `${count} Modificado${count > 1 ? "s" : ""}`
                                : "Salvo"}
                            {remaining && <strong>{remaining()}</strong>}
                        </h5>
                    </InfoWrapper>

                    <div>
                        <p>
                            {moment(props.item.created_at).format(
                                "DD/MM/yyyy, HH:mm"
                            )}
                        </p>
                        <span />
                        <FaCalendar color="#555" size={12} />
                    </div>
                </Horizontal>
            </ClickableBackground>
        </Background>
    )
}

export const CreateProtocol = () => {
    return <Background selected={false}></Background>
}

export default ProtocolItem
