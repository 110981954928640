import React, { useEffect, useState } from "react"
import { FoldsContainer, Form } from "../folds/styles"
import Inputfield from "src/components/inputfield"
import { ListItem } from "./styles"
import perimetry from "../../../fithub-shared/anthropometry/perimetry.json"
import {
    PerimetryList,
    PerimetryListKeys,
} from "src/modules/fithub-shared/anthropometry/perimetry"
import Equations from "src/modules/fithub-shared/anthropometry/fold-equations"

interface PerimProps {
    defaultPerimetry: PerimetryList
    onChangePerimetry(perimetry: PerimetryList): void
    protocol: number
}

const Perimetry: React.FC<PerimProps> = (props) => {
    const [valores, setValores] = useState<PerimetryList>(
        props.defaultPerimetry
    )

    useEffect(() => {
        setValores(props.defaultPerimetry)
    }, [props.defaultPerimetry, props.protocol])

    const defInputProps = {
        selectOnFocus: true,
        min: 0,
        type: "number",
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target

        const v = {
            ...valores,
            [name]: isNaN(parseFloat(value)) ? undefined : parseFloat(value),
        }
        setValores(v)
        props.onChangePerimetry(v)
    }

    const getDefault = (e: string) => {
        return (
            props.defaultPerimetry &&
            props.defaultPerimetry[e as PerimetryListKeys]
        )
    }

    const Highlight = (d: string) => {
        return Equations[props.protocol].affects.includes(d)
            ? "bold"
            : undefined
    }

    const col1 = perimetry.slice(0, 9)
    const col2 = perimetry.slice(9, perimetry.length)

    return (
        <FoldsContainer style={{ flexDirection: "row" }}>
            <Form style={{ flexDirection: "column", width: "100%" }}>
                {col1.map((element, index) => (
                    <ListItem key={index}>
                        <input
                            {...defInputProps}
                            style={{ width: "50px" }}
                            onFocus={(e) => e.target.select()}
                            name={element.name}
                            onChange={handleInputChange}
                            defaultValue={getDefault(element.name)}
                            id={element.name + `_perim`}
                        />
                        <p style={{ fontWeight: Highlight(element.name) }}>
                            {element["pt"]}
                        </p>
                    </ListItem>
                ))}
            </Form>
            <span
                style={{
                    width: 5,
                    backgroundColor: "#0001",
                    borderRadius: 5,
                    height: `100%`,
                    margin: 15,
                }}
            />
            <Form style={{ flexDirection: "column", width: "100%" }}>
                {col2.map((element, index) => (
                    <ListItem key={index}>
                        <input
                            {...defInputProps}
                            style={{ width: "50px" }}
                            onFocus={(e) => e.target.select()}
                            name={element.name}
                            onChange={handleInputChange}
                            defaultValue={getDefault(element.name)}
                            id={element.name + `_perim`}
                        />
                        <p style={{ fontWeight: Highlight(element.name) }}>
                            {element["pt"]}
                        </p>
                    </ListItem>
                ))}
            </Form>
        </FoldsContainer>
    )
}

export default Perimetry
