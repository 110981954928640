import styled from "styled-components"

export const Backdrop = styled.div`
    div > * {
        display: flex;
    }

    position: absolute;
    display: flex;

    justify-content: center;
    align-items: center;

    z-index: 10;
    height: 100vh;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: #0004;

    @keyframes opacidade {
        0% {
            background-color: #0000;
        }
        100% {
            background-color: #0004;
        }
    }

    animation: opacidade 0.2s;
`

export const Header = styled.div``

export const StudentsWrapper = styled.div`
    background-color: white;
    width: 100vw;
    max-width: 600px;
    padding: 10px;
    border-radius: 5px;
`

export const DrawerBackground = styled.div`
    display: flex;
    flex: 1;
    min-width: 40px;
    max-width: 60px;

    @media (max-width: 768px) {
        max-width: 40px;
    }

    flex-direction: column;
    align-items: center;

    padding-bottom: 10px;

    justify-content: space-between;

    background-color: #ddd;
`

export const MenuButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;

    @media (max-width: 768px) {
        height: 35px;
        width: 35px;

        :active {
            background-color: #fff;
            p {
                opacity: 1;
                display: inline;
                left: 70px;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    @media (min-width: 768px) {
        :hover {
            background-color: #fff;
            p {
                opacity: 1;
                display: inline;
                left: 70px;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    border-radius: 10px;

    background-color: #e8e8e8;
    cursor: pointer;

    :not(:first-child) {
        margin-top: 10px;
    }
`

export const Label = styled.p`
    pointer-events: none;
    width: fit-content;
    z-index: 1000;
    position: absolute;
    display: none;
    opacity: 0;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;

    text-overflow: clip;

    background-color: #444;

    text-transform: uppercase;

    transition: opacity 0.2s, left 0.2s;
    left: 0px;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #444 transparent transparent;
        transition: opacity 0.2s;
        opacity: 0;
    }
`

export const Section = styled.div`
    flex: 0;
    flex-direction: column;
    height: 100%;
`

export const Divider = styled.div`
    background-color: #aaa;

    width: 70%;
    height: 5px;
    border-radius: 10px;
`

export const MiddleMenu = styled.div`
    h4 {
        border-bottom: 1px solid #ddd;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        color: #888;
    }
`
