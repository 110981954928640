import React, { useContext, useState } from "react"
import { Wrapper } from "./styles"
import AssessmentList from "./listing"

import AssessmentContent from "./AssessmentContent"
import AuthContext from "src/contexts/auth"
import { useDeleteAssessment, useListAssessment } from "./caching/queries"
import StudentContext from "src/contexts/student"
import { AssessmentContextProvider } from "./context/assessmentContext"
import Dialog from "src/components/dialog"
import SideListView from "src/components/SideListView"
// import { Container } from './styles';

const Assessment: React.FC = () => {
    const [selected, setSelected] = useState("")
    const [dialog, setDialog] = useState(false)
    function onSetSelected(s: string) {
        setSelected("")
        setTimeout(() => {
            setSelected(s)
        }, 100)
    }

    const student = useContext(StudentContext).student
    const auth = useContext(AuthContext)
    const deleteA = useDeleteAssessment(selected, auth.user?.id)

    const { data, isLoading, refetch } = useListAssessment(
        student?.id,
        auth.user?.id
    )

    function Reload() {
        refetch()
    }

    function Delete() {
        deleteA.mutateAsync().then(() => {
            setDialog(false)
            Reload()
        })
    }

    function handleDelete() {
        setDialog(true)
    }

    return (
        <AssessmentContextProvider>
            <Wrapper className="flex row">
                <SideListView
                    list={
                        data &&
                        !isLoading && (
                            <AssessmentList
                                onSetSelected={onSetSelected}
                                handleDelete={handleDelete}
                                selected={selected}
                                Reload={Reload}
                                list={data}
                            />
                        )
                    }
                    content={
                        selected && (
                            <AssessmentContent
                                id={selected}
                                handleUnselect={() => onSetSelected("")}
                                handleReload={Reload}
                                handleDelete={handleDelete}
                            />
                        )
                    }
                    view={selected ? "CONTENT" : "LIST"}
                />
            </Wrapper>
            {dialog && (
                <Dialog
                    active={dialog}
                    title="Excluir"
                    onConfirm={Delete}
                    onCancel={() => setDialog(false)}
                    subtitle="Deseja excluir esta avaliação?"
                />
            )}
        </AssessmentContextProvider>
    )
}

export default Assessment
