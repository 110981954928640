import React from "react"
import { ProtocolType } from "../../../../fithub-shared/interfaces/protocol"
import ProtocolItem from "../../../components/protocol-list-item"
import { EmptyList, VerticalScroll } from "./styles"
import { BiCloudUpload } from "react-icons/bi"
import { usePlan } from "../../../context"

// import { Container } from './styles';

interface Listing {
    list: ProtocolType[]
    selected?: ProtocolType
    templates: boolean

    onUpdateList: () => void
    onSetSelected: (index: number, p: ProtocolType) => void
    onUnselect: () => void

    onDelete: (id: string) => void
    onDuplicate: (id: string) => void
    onSaveTemplate: (id: string) => void
    onExport: (id: string) => void
}

const ProtocolsList: React.FC<Listing> = (props) => {
    const { plan } = usePlan()

    function handleSelect(index: number, element: ProtocolType) {
        try {
            plan.unselect()
        } finally {
            props.onSetSelected(index, element)
        }
    }

    if (props.list.length > 0) {
        return (
            <VerticalScroll>
                {props.list.map((element, index) => {
                    return (
                        <ProtocolItem
                            key={index}
                            template={props.templates}
                            index={index}
                            item={props.list[index]}
                            selected={props.selected?.id === element.id}
                            onUpdate={props.onUpdateList}
                            onSelected={() => handleSelect(index, element)}
                            onUnselect={props.onUnselect}
                            onRemove={props.onDelete}
                            onDuplicate={props.onDuplicate}
                            onSaveTemplate={props.onSaveTemplate}
                            onExport={props.onExport}
                        />
                    )
                })}
            </VerticalScroll>
        )
    } else {
        return (
            <EmptyList>
                {!props.templates ? (
                    <>
                        <h5>Este aluno não possui nenhum protocolo.</h5>
                        <p>Crie um novo para começar!</p>
                    </>
                ) : (
                    <>
                        <BiCloudUpload size={48} color={"#444"} />
                        <h5>Você ainda não salvou nenhum template!</h5>
                    </>
                )}
            </EmptyList>
        )
    }
}

export default ProtocolsList
