import styled from "styled-components"

export const Button = styled.button`
    background-color: #444;
    flex-direction: row;
    font-size: 16px;
    border-radius: 5px;
    width: 200px;
    height: 25vh;
    max-height: 60px;
    min-height: 30px;
    color: white;
    justify-content: space-between;
    align-items: center;

    :not(:last-child) {
        margin-bottom: 5px;
    }

    padding: 10px;

    cursor: pointer;
`

export const FloatDiv = styled.div`
    position: relative;
    flex: 1;

    justify-content: center;
    align-items: center;

    border: 1px solid #ddd;

    padding: 10px;
    border-radius: 10px;

    background-color: #fff;

    div {
        flex-direction: column;
    }
`
