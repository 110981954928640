import React from "react"

import {
    StyledGraphTd,
    StyledTable,
    StyledTableContainer,
    StyledTd,
    StyledTh,
    TableKind,
    Wrapper,
} from "./styles"
import { AssessmentClass } from "src/modules/fithub-shared/anthropometry/assessment"
import { PerimetryListKeys } from "src/modules/fithub-shared/anthropometry/perimetry"
import { SkinFoldsListKeys } from "src/modules/fithub-shared/anthropometry/folds"
import perimetry from "src/modules/fithub-shared/anthropometry/perimetry.json"
import skinfolds from "src/modules/fithub-shared/anthropometry/skinfolds.json"
import { HiChevronLeft } from "react-icons/hi"
import DonutChart from "../../components/DonutChart"
import DonutChartAssessment from "../../components/DonutChart/assessment"
// import { Container } from './styles';

const ComparisionContent: React.FC<{
    contentA?: AssessmentClass
    contentB?: AssessmentClass
}> = (props) => {
    const { contentA, contentB } = props

    if (contentA && contentB) {
        const perimetryA = (key: string) => {
            const res = contentA.content.perimetry[key as PerimetryListKeys]
            return res ? res : 0
        }

        const perimetryB = (key: string) => {
            const res = contentB.content.perimetry[key as PerimetryListKeys]
            return res ? res : 0
        }

        const skinfoldsA = (key: string) => {
            const res = contentA.content.folds[key as SkinFoldsListKeys]
            return res ? res : 0
        }

        const skinfoldsB = (key: string) => {
            const res = contentB.content.folds[key as SkinFoldsListKeys]
            return res ? res : 0
        }

        const fatPercent = (content: AssessmentClass) => {
            return (content.graph.fat * 100) / content.weight
        }

        const moWeight = (content: AssessmentClass) => {
            return (
                content.graph.muscles +
                (content.graph.bones ? content.graph.bones : 0)
            )
        }

        return (
            <Wrapper style={{ width: `100%` }}>
                <StyledTableContainer>
                    <StyledTable>
                        <div
                            style={{
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <StyledTh>Composição Corporal</StyledTh>

                            <TableKind
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <StyledGraphTd>
                                    <p>Gráfico</p>
                                </StyledGraphTd>

                                <StyledGraphTd style={{ flex: 0.5 }}>
                                    <DonutChartAssessment
                                        data={contentA.graph}
                                    />
                                </StyledGraphTd>
                                <StyledGraphTd style={{ flex: 0.5 }}>
                                    <DonutChartAssessment
                                        data={contentB.graph}
                                    />
                                </StyledGraphTd>
                            </TableKind>

                            <TableKind
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <StyledTd style={{ flex: 1 }}>Peso</StyledTd>

                                <StyledTd style={{ flex: 0.5 }}>
                                    {contentA.weight.toFixed(2) + " Kg"}
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    <p>{contentB.weight.toFixed(2) + " Kg"}</p>
                                    <p
                                        style={{
                                            color: "#888",
                                            fontSize: 12,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {(
                                            fatPercent(contentB) -
                                            fatPercent(contentA)
                                        ).toFixed(2) + "%"}
                                    </p>
                                </StyledTd>
                            </TableKind>

                            <TableKind
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <StyledTd style={{ flex: 1 }}>
                                    Percentual de Gordura
                                </StyledTd>

                                <StyledTd style={{ flex: 0.5 }}>
                                    {fatPercent(contentA).toFixed(2) + " %"}
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    <p>
                                        {fatPercent(contentB).toFixed(2) + " %"}
                                    </p>
                                    <p
                                        style={{
                                            color: "#888",
                                            fontSize: 12,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {(
                                            fatPercent(contentB) -
                                            fatPercent(contentA)
                                        ).toFixed(2) + "%"}
                                    </p>
                                </StyledTd>
                            </TableKind>
                            <TableKind
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <StyledTd style={{ flex: 1 }}>
                                    Massa Gorda
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    {contentA.graph.fat.toFixed(2)} Kg
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    <p>{contentB.graph.fat.toFixed(2)} Kg</p>
                                    <p
                                        style={{
                                            color: "#888",
                                            fontSize: 12,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {(
                                            contentB.graph.fat -
                                            contentA.graph.fat
                                        ).toFixed(2)}
                                    </p>
                                </StyledTd>
                            </TableKind>
                            <TableKind
                                style={{
                                    flexDirection: "row",
                                    width: "100%",
                                }}
                            >
                                <StyledTd style={{ flex: 1 }}>
                                    Massa Muscular e Ossea
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    {moWeight(contentA).toFixed(2)} Kg
                                </StyledTd>
                                <StyledTd style={{ flex: 0.5 }}>
                                    <p>{moWeight(contentB).toFixed(2)} Kg</p>
                                    <p
                                        style={{
                                            color: "#888",
                                            fontSize: 12,
                                            marginLeft: 10,
                                        }}
                                    >
                                        {(
                                            moWeight(contentA) -
                                            moWeight(contentB)
                                        ).toFixed(2)}
                                    </p>
                                </StyledTd>
                            </TableKind>
                        </div>
                    </StyledTable>
                </StyledTableContainer>

                <StyledTableContainer>
                    <StyledTable>
                        <div
                            style={{
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <StyledTh>Circunferências</StyledTh>

                            {perimetry.map((keys, index) => (
                                <TableKind
                                    key={index}
                                    style={{
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <StyledTd style={{ flex: 1 }}>
                                        {keys.pt}
                                    </StyledTd>
                                    <StyledTd style={{ flex: 0.5 }}>
                                        {perimetryA(keys.name)}
                                    </StyledTd>
                                    <StyledTd style={{ flex: 0.5 }}>
                                        <p>{perimetryB(keys.name)}</p>
                                        <p
                                            style={{
                                                color: "#888",
                                                fontSize: 12,
                                                marginLeft: 10,
                                            }}
                                        >
                                            (
                                            {(
                                                perimetryB(keys.name) -
                                                perimetryA(keys.name)
                                            ).toFixed(2)}
                                            )
                                        </p>
                                    </StyledTd>
                                </TableKind>
                            ))}
                        </div>
                    </StyledTable>
                </StyledTableContainer>

                <StyledTableContainer>
                    <StyledTable>
                        <div
                            style={{
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <StyledTh>Dobras</StyledTh>

                            {skinfolds.map((keys, index) => (
                                <TableKind
                                    key={index}
                                    style={{
                                        flexDirection: "row",
                                        width: "100%",
                                    }}
                                >
                                    <StyledTd
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        {keys.pt}
                                    </StyledTd>
                                    <StyledTd style={{ flex: 0.5 }}>
                                        {skinfoldsA(keys.name)}
                                    </StyledTd>
                                    <StyledTd style={{ flex: 0.5 }}>
                                        <p>{skinfoldsB(keys.name)}</p>
                                        <p
                                            style={{
                                                color: "#888",
                                                fontSize: 12,
                                                marginLeft: 10,
                                            }}
                                        >
                                            (
                                            {(
                                                skinfoldsB(keys.name) -
                                                skinfoldsA(keys.name)
                                            ).toFixed(2)}
                                            )
                                        </p>
                                    </StyledTd>
                                </TableKind>
                            ))}
                        </div>
                    </StyledTable>
                </StyledTableContainer>
            </Wrapper>
        )
    } else {
        return <p>Selecione as avaliações para comparar</p>
    }
}

export default ComparisionContent
