import styled from "styled-components"

export const Buttons = styled.div`
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
`

export const CreateButton = styled.button`
    height: 50px;
    width: 50px;

    font-size: 18px;

    border-radius: 25px;

    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;

    :hover {
        opacity: 0.9;
    }

    :active {
        opacity: 0.5;
    }

    :focus {
        background-color: #444;
        border: 2px solid #000;
    }

    background-color: #444;
    border: 2px solid;

    cursor: pointer;

    transition: all 0.2s;
`

export const ExerciseListing = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;

    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
`

export const Wrapper = styled.div`
    flex: 1;
    flex-basis: 0;
    overflow: hidden;

    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`
