import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Card } from ".."

const Block = styled.div`
    display: flex;
    height: 100%;
    max-width: 500px;
    overflow-y: scroll;
    padding: 10px;
    background-color: var(--bege);
    border-radius: 5px;
`

const Paragraph = styled.p`
    flex-direction: column;
    white-space: pre-wrap;
    font-size: 12px;
    color: var(--marromDark);
`

const ChangeLogCard: React.FC = () => {
    const [data, setData] = useState("")

    useEffect(() => {
        fetch("/changelog.md")
            .then((r) => r.text())
            .then((text) => {
                setData(text)
            })
    }, [])

    return (
        <Card style={{ flexDirection: "column", alignItems: "center" }}>
            <h4 style={{ fontWeight: 600 }}>Notas de Atualização</h4>
            <br />
            <Block>
                <Paragraph>{data}</Paragraph>
            </Block>
        </Card>
    )
}

export default ChangeLogCard
