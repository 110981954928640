import { useQuery } from "react-query"
import api from "src/API/rest"
import { UseMutationResult, useQueryClient, useMutation } from "react-query"
import mostrarAlerta from "src/components/utils/alert"
import {
    AssessmentClass,
    AssessmentObject,
} from "src/modules/fithub-shared/anthropometry/assessment"

export const useGetAssessment = (id: string) => {
    return useQuery(
        ["assessment", id],
        () =>
            api
                .post("assessment/get", { id: id })
                .then((res) => res.data as AssessmentClass),
        {
            staleTime: 60 * 1000,
            cacheTime: 3600 * 1000,
        }
    )
}

type compare = AssessmentClass | undefined

export const useCompareAssessment = (a: string, b: string) => {
    return useQuery(["aComparision"], async () => {
        const contentA = a
            ? await api
                  .post("assessment/get", { id: a })
                  .then((res) => res.data as AssessmentClass)
                  .catch((err) => err)
            : undefined

        const contentB = b
            ? await api
                  .post("assessment/get", { id: b })
                  .then((res) => res.data as AssessmentClass)
                  .catch((err) => err)
            : undefined

        return { a: contentA as compare, b: contentB as compare }
    })
}

export const useListAssessment = (user_id?: string, created_by?: string) => {
    //const auth = useContext(AuthContext)
    return useQuery(
        ["assessmentList", user_id],
        () =>
            api
                .post("assessment/list", {
                    user_id: user_id,
                    created_by: created_by,
                })
                .then((res) => res.data as AssessmentClass[])
                .catch(() => undefined),
        {
            retry: 5,
            staleTime: 60 * 1000,
            cacheTime: 3600 * 1000,
            refetchOnWindowFocus: true,
        }
    )
}

export const useCreateAssessment = (
    user_id?: string,
    created_by?: string
): UseMutationResult<void, Error, void, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, void, unknown>(
        async () => {
            await api.post("/assessment/create", {
                user_id,
                created_by,
                content: new AssessmentObject(),
            })
        },
        {
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                console.log("success fetching")
                queryClient.refetchQueries(["assessmentList", user_id])
            },
            onSettled: () => {
                console.error("settled called")
            },
        }
    )
}

export const useDuplicateAssessment = (
    id?: string,
    user_id?: string
): UseMutationResult<void, Error, void, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, void, unknown>(
        async () => {
            await api.post("/assessment/duplicate", {
                id,
            })
        },
        {
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                console.log("success fetching")
                queryClient.refetchQueries(["assessmentList", user_id])
            },
            onSettled: () => {
                console.error("settled called")
            },
        }
    )
}

export const useDeleteAssessment = (
    id: string,
    user_id?: string
): UseMutationResult<void, Error, void, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, void, unknown>(
        async () => {
            await api.post("/assessment/delete", {
                id,
            })
        },
        {
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                console.log("success fetching")
                queryClient.refetchQueries(["assessmentList", user_id])
            },
            onSettled: () => {
                console.error("settled called")
            },
        }
    )
}

export const useUpdateAssessment = (
    id: string
): UseMutationResult<void, Error, AssessmentClass, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, AssessmentClass, unknown>(
        async (assessment) => {
            await api.post("/assessment/update", assessment)
        },
        {
            onMutate: async (variables) => {
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                mostrarAlerta("Salvo!")
                queryClient.refetchQueries(["assessment", id])
                queryClient.refetchQueries(["assessmentList", id])
            },
        }
    )
}
