import React, { ReactNode, createContext, useContext, useState } from "react"
import api from "src/API/rest"
import Dialog from "src/components/dialog"
import { AssessmentClass } from "src/modules/fithub-shared/anthropometry/assessment"

interface AssessmentContextType {
    contentData?: AssessmentClass
    loadAssessment(id: string): Promise<AssessmentClass | null>
    save(id?: AssessmentClass): Promise<void>
    setUnsaved(): void
    saved: boolean
}

interface Props {
    children: ReactNode
}

const AssessmentContext = createContext<AssessmentContextType>(
    {} as AssessmentContextType
)

export const AssessmentContextProvider: React.FC<Props> = ({ children }) => {
    // Defina o estado ou dados que deseja fornecer através do contexto
    const [saved, setSaved] = useState(false)
    const [contentData, setSelected] = useState<AssessmentClass>()

    const [savePopup, setSavePopup] = useState<() => void>()

    function loadAssessment(id: string) {
        return api
            .post<AssessmentClass>("assessment/get", { id: id })
            .then((res) => {
                setSelected(res.data)
                setSaved(true)
                return res.data
            })
            .catch((err) => {
                alert("Não foi possível carregar avaliação!\n\n" + err)
                return null
            })
    }

    function save(assessment: AssessmentClass) {
        return api
            .post("/assessment/update", assessment ?? contentData)
            .then(() => setSaved(true))
            .catch((err) =>
                alert("Não foi possível salvar avaliação!\n\n" + err)
            )
    }

    function setUnsaved() {
        if (saved) setSaved(false)
    }

    return (
        <AssessmentContext.Provider
            value={{ contentData, loadAssessment, save, setUnsaved, saved }}
        >
            {children}
            {Boolean(savePopup) && (
                <Dialog
                    active={Boolean(savePopup)}
                    title="Excluir"
                    onConfirm={savePopup}
                    onCancel={() => setSavePopup(undefined)}
                    subtitle="Esta avaliação não foi salva! Gostaria de salvar esta avaliação?"
                />
            )}
        </AssessmentContext.Provider>
    )
}

export const useAssessmentContext = () => {
    return useContext(AssessmentContext)
}
