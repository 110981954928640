import React, { ReactNode, createContext, useContext, useState } from "react"
import DrawerMenu from "src/components/DrawerMenu"
import styled from "styled-components"

// Definindo o tipo para os dados que serão armazenados no contexto
interface DrawerContextData {
    // Coloque aqui os tipos dos dados que você quer armazenar no contexto do Drawer
    // Por exemplo:
    toggleDrawer: () => void
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    height: 100vh;
`

const Page = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`

// Criando o contexto do Drawer
const DrawerContext = createContext<DrawerContextData | undefined>(undefined)

// Componente de provedor do contexto do Drawer
export const DrawerProvider: React.FC<{
    children: ReactNode | ReactNode[]
}> = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen)
    }

    return (
        <DrawerContext.Provider value={{ toggleDrawer }}>
            <Page>
                <DrawerMenu />
                <Wrapper>{children}</Wrapper>
            </Page>
        </DrawerContext.Provider>
    )
}

// Hook personalizado para utilizar o contexto do Drawer
export const useDrawer = () => {
    const context = useContext(DrawerContext)
    if (!context) {
        throw new Error("useDrawer must be used within a DrawerProvider")
    }
    return context
}
