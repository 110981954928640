import styled from "styled-components"

export const Container = styled.div`
    border-radius: 15px;
    background-color: #ddd;

    height: 100%;

    overflow: hidden;

    span {
        margin: 2.5px;
    }

    .shadow {
        border-radius: 5px;
        background-color: white;
    }
`

export const SaveButton = styled.button`
    padding: 10px;
    background-color: var(--primary);
    color: white;
    align-items: center;
    border-radius: 5px;

    transition: border-radius 0.1s;

    :enabled {
        cursor: pointer;
        :hover {
            border-radius: 25px;
        }
    }
`

export const Scroll = styled.ul`
    flex: 1 0 1;
    flex-direction: column;

    min-height: 0px;

    padding: 10px;

    width: 100%;

    overflow-y: auto;
    overflow-x: hidden;
`

export const CreateExerciseButton = styled.button`
    cursor: pointer;
    height: 55px;
    border-radius: 30px;
    aspect-ratio: 1;
    background-color: var(--primary);
    position: absolute;
    top: 86%;

    justify-content: center;
    align-items: center;
`

export const Item = styled.li<{ selected: boolean }>`
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 5px;

    min-height: 35px;

    margin-bottom: 10px;
    border-radius: 5px;
    background-color: ${(p) => (p.selected ? "var(--primary)" : "white")};
    color: ${(p) => (p.selected ? "white" : "black")};
    font-weight: ${(p) => (p.selected ? "bold" : "normal")};

    border: 1px solid #ccc;

    cursor: pointer;
    transition: opacity 0.1s;

    p {
        font-size: clamp(12px, 1.5vw, 16px);
    }

    h4 {
        font-size: 10px;
        font-weight: bold;
        color: #1d1d1d;
    }

    :hover {
        opacity: 0.75;
    }

    :active {
        opacity: 0.5;
    }
`

export const Column = styled.div`
    position: relative;
    flex: 1;

    overflow: hidden;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    overflow: hidden;
`

export const ListHeader = styled.div`
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;

    width: 100%;

    background-color: #fff;
`

export const SearchContainer = styled.div`
    flex-direction: column;
    width: 100%;

    padding: 10px;

    align-items: center;
`

export const EditorHeader = styled.div`
    width: 100%;
    background-color: #444;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
    border-radius: 5px;

    padding: 10px;

    h4 {
        color: #fff;
        font-weight: 600;
    }

    p {
        color: #aaa;
        font-size: 12px;

        text-transform: uppercase;
    }
`

export const Editor = styled.div`
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    padding: 15px;

    justify-content: space-between;
    height: 50%;

    align-items: center;

    span {
        height: 10px;
        width: 10px;
    }
`

export const Details = styled.div`
    flex-direction: column;
    width: 50%;
    height: 100%;
`
