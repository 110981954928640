import React, { useContext, useEffect, useState } from "react"
import { TemplatesList, Container, Header } from "./styles"
import api from "../../../API/rest"

import mostrarAlerta from "../../../components/utils/alert"
import {
    Workout,
    WorkoutTypeString,
    _Workout,
} from "../../../modules/fithub-shared/interfaces/workout"
import MutableIcon from "../../../components/mutable-icon"
import { useNavigate } from "react-router-dom"
import { HiChevronLeft, HiOutlineDocumentDuplicate } from "react-icons/hi"
import moment from "moment"
import { SaveButton } from "../../../components/save-button"
import { labels } from "../../../assets/strings/labels"
import Dialog from "src/components/dialog"
import { MdAdd, MdDelete } from "react-icons/md"
import SelectWorkoutType from "src/modules/protocols/components/select-workout-type"
import AuthContext from "src/contexts/auth"
import SideListView from "src/components/SideListView"

// import { Container } from './styles';

const WorkoutTemplates: React.FC = () => {
    const [list, setList] = useState<_Workout[]>([])
    const [workout, setWorkout] = useState<_Workout | null>(null)
    const [create, setCreate] = useState(false)

    function unselect() {
        setWorkout(null)
        setCreate(false)
    }

    function handleCreate() {
        setCreate(true)
        setWorkout(null)
    }

    const [remove, setRemove] = useState<string | null>(null)

    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    useEffect(() => {
        GetList()
    }, [])

    async function GetList() {
        await api
            .post("workout-templates/list", { created_by: auth.user?.id })
            .then((res) => {
                const data = res.data as _Workout[]
                data.forEach((e) => {
                    e.modified = localStorage.getItem(`workout_${e.id}`) != null
                })
                setList(data)
            })
            .catch((err) => {
                mostrarAlerta(err)
            })
    }

    function GetWorkout(e: _Workout) {
        if (e.id === workout?.id) {
            setWorkout(null)
            return
        }

        if (e.modified) {
            const lw = JSON.parse(
                localStorage.getItem(`workout_${e.id}`) as string
            ) as _Workout

            setWorkout(lw)
        } else {
            setWorkout(e)
        }
    }

    function Delete(id: string | null) {
        if (id == null) return
        api.post("workout-templates/delete", { id: id })
            .then(() => {
                const index = list.findIndex((e) => e.id === id)
                list.splice(index, 1)
                setRemove(null)
                setCreate(false)
                setWorkout(null)
            })
            .catch(mostrarAlerta)
    }

    async function OnSave() {
        if (workout === null) return

        await api
            .post("/workout-templates/edit", {
                id: workout.id,
                title: workout.title,
                item: JSON.stringify(workout.item),
                obs: workout.obs,
            })
            .then(() => {
                mostrarAlerta("Salvo!")
                localStorage.removeItem(`workout_${workout.id}`)
                GetList()
            })
            .catch((err) => mostrarAlerta(err))
    }

    function onModifiedWorkout(w: Workout) {
        const workout = w as _Workout
        workout.modified = true

        mostrarAlerta(workout.modified ? "true" : "false")

        const index = list.findIndex((e) => e.id === workout.id)

        localStorage.setItem(
            `workout_${w.id}`,
            JSON.stringify(workout as Workout)
        )

        list[index] = workout
        setList(list)
        setWorkout(workout)
    }

    async function NewTemplate(type: number) {
        setCreate(false)
        const nw = new Workout(type, "Novo Template")
        await api
            .post("workout-templates/new", {
                title: nw.title,
                obs: "",
                type: nw.type,
                item: JSON.stringify(nw.item),
                created_by: auth.user?.id,
            })
            .then((res) => {
                const nl = [...list]
                const n = res.data as _Workout

                nl.unshift(n)
                setList(nl)
                GetWorkout(n)
            })
            .catch((err) => mostrarAlerta(err))
    }

    return (
        <Container className="flex row">
            <SideListView
                list={
                    <TemplatesList>
                        <Header>
                            <div style={{ alignItems: "center" }}>
                                <MutableIcon
                                    size={40}
                                    icon={HiChevronLeft}
                                    onClick={() => navigate(-1)}
                                />
                                <p style={{ fontWeight: "bold", fontSize: 16 }}>
                                    Treinos Templates
                                </p>
                            </div>

                            <button
                                className="addbutton"
                                onClick={handleCreate}
                            >
                                <MdAdd size={18} />
                                <span style={{ width: 5 }} />
                                <p>CRIAR</p>
                            </button>
                        </Header>
                        <ul>
                            {list.map((element, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={() => GetWorkout(element)}
                                        style={{
                                            backgroundColor:
                                                workout?.id === element.id
                                                    ? "#ccc"
                                                    : "#fff",
                                        }}
                                    >
                                        <div style={{ alignItems: "center" }}>
                                            <HiOutlineDocumentDuplicate
                                                size={18}
                                            />
                                            <div
                                                style={{
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p className="title">
                                                    {element.title}
                                                    <p
                                                        style={{
                                                            color: "var(--primary)",
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {element.modified &&
                                                            `(MODIFICADO)`}
                                                    </p>
                                                </p>

                                                <p className="subtitle">
                                                    {WorkoutTypeString(
                                                        element.type
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ alignItems: "center" }}>
                                            <p className="date">
                                                {moment(
                                                    element.created_at
                                                ).format("DD/MM/yyyy")}
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </TemplatesList>
                }
                content={
                    workout && !create ? (
                        <div
                            className="fade-in"
                            style={{
                                flexDirection: "column",
                                flex: 1,
                                padding: 5,
                                position: "relative",
                            }}
                        >
                            {/*<WorkoutViewer
                                workout={workout}
                                index={0}
                                deleteWorkout={function (): void {
                                    throw new Error("Function not implemented.")
                                }}
                                onModified={onModifiedWorkout}
                            />*/}
                            <div
                                style={{
                                    position: "absolute",
                                    top: 15,
                                    right: 15,
                                    alignItems: "center",
                                }}
                            >
                                <SaveButton onSave={OnSave} saved={false} />
                                <div>
                                    <MutableIcon
                                        icon={MdDelete}
                                        onClick={() => setRemove(workout.id)}
                                        size={28}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                flex: 1,
                                width: "100%",
                                justifyContent: "center",
                            }}
                        >
                            <SelectWorkoutType
                                onSetType={(wt) => NewTemplate(wt)}
                            />
                        </div>
                    )
                }
                onGoBack={unselect}
                view={workout || create ? "CONTENT" : "LIST"}
            />

            {remove && (
                <Dialog
                    active={remove != null}
                    onConfirm={() => Delete(remove)}
                    onCancel={() => setRemove(null)}
                    title={labels.delete.workoutTemplateTitle}
                    subtitle={labels.delete.workoutTemplateSubtitle}
                />
            )}
        </Container>
    )
}

export default WorkoutTemplates
