import styled from "styled-components"

export const Container = styled.div`
    display: flex;

    flex: 1;
    flex-direction: column;

    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;

    span {
        width: 100%;
        background-color: #0003;
        height: 1px;
    }
`

export const EditableTitle = styled.div`
    align-items: center;
    p {
    }
`

export const Content = styled.div`
    flex-direction: column;
    flex: 1;
`

export const ConfigList = styled.ul`
    flex-direction: column;
    background-color: #fff;
    width: 100%;

    max-width: 700px;

    border: 1px solid #ddd;

    padding: 10px;
    border-radius: 5px;
`

export const Actions = styled.div<{ color: string }>`
    button {
        all: unset;
        color: white;
        font-weight: bold;
        text-transform: uppercase;

        background-color: ${(p) => p && p.color};

        padding: 6px;
        border-radius: 5px;

        align-items: center;
        cursor: pointer;
    }

    span {
        all: unset;
        margin: 5px;
    }
`

export const Tooltip = styled.div<{ x: number; y: number }>`
    //funcional
    position: absolute;
    z-index: 10;
    left: ${(p) => p && `${p.x}px`};
    transform: translateX(-50%) translateY(-130%);
    top: ${(p) => p && `${p.y}px`};
    width: auto;

    //Estético
    background-color: #444;
    color: white;
    font-size: 12px;
    padding: 5px;
    border-radius: 4px;
    align-items: center;
    text-align: center;

    // Estilo da ponta do balão
    &::before {
        content: "";
        position: absolute;
        top: 95%; /* Coloca o pseudo-elemento abaixo do Tooltip */
        left: 50%; /* Posiciona o pseudo-elemento no centro horizontal do Tooltip */
        border-width: 6px;
        border-style: solid;
        border-color: #444 transparent transparent transparent;
        transform: translateX(-50%);
        margin-top: 0; /* Remove a margem superior (valor padrão é 1em) */
    }
`

export const ConfigItem = styled.li`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    flex-wrap: wrap;

    :not(:last-child) {
        border-bottom: 1px solid #bbb;
    }

    h4 {
        font-size: medium;
        font-weight: 600;
    }
`

export const ConfigSubtitle = styled.p`
    font-size: small;
    padding: 0px 0px 5px 0px;
    margin-top: 5px;
    color: #333;
    border-radius: 5px;

    color: #444;

    strong {
        font-weight: bold;
        margin-left: 3px;
    }
`

export const Badge = styled.div`
    margin-right: 5px;

    font-size: 12px;

    width: 15px;
    aspect-ratio: 1;

    font-weight: bold;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

    text-align: center;
`

export const Stats = styled.div`
    flex-direction: column;
    justify-content: flex-end;

    width: 100px;
    margin-right: 15px;

    div {
        justify-content: space-between;
        align-items: center;
    }
`

export const Header = styled.div`
    flex-direction: row;

    padding: 4px;

    align-items: center;

    justify-content: space-between;

    span {
        width: 1px;
        background-color: #0003;
        height: 100%;

        margin-inline: 10px;
    }

    .loading {
        justify-content: center;
        width: 35px;
    }

    .loaded {
        justify-content: center;
        width: fit-content;
        width: 70px;
    }

    transition: width 0.5s;
`

export const Path = styled.button<{ selected?: boolean }>`
    font-size: 14px;
    background-color: #ddd;
    padding: 5px;
    height: calc(30px - ${(p) => (p.selected ? "2px" : "0px")});
    align-items: center;
    cursor: pointer;
    background-color: #ddd;

    border-radius: 25px;

    p {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
    }

    ${(p) => p.selected && "border: 1px solid #777;"}

    :not(:first-child) {
        margin-left: 10px;
    }

    :hover {
    }
`
