import React, { useContext, useRef, useState } from "react"

import { Divider, DrawerBackground, Label, MenuButton, Section } from "./styles"
import { useNavigate } from "react-router-dom"
import { Student } from "src/screens/student-info"
import { BiCalendar } from "react-icons/bi"
import {
    HiHome,
    HiOutlineDocument,
    HiOutlineDocumentDuplicate,
} from "react-icons/hi"

import { TbZoomMoney } from "react-icons/tb"
import { CgLogOut } from "react-icons/cg"
import { PiPackageBold, PiUsersThreeBold } from "react-icons/pi"
import { useStudent } from "src/contexts/student"
import StudentsModal from "../StudentsModal"
import { useDialog } from "src/contexts/dialog-context"
import api from "src/API/rest"
import AuthContext from "src/contexts/auth"
// import { Container } from './styles';

const DrawerMenu: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()

    const [alunos, setAlunos] = useState(false)

    function handle(e: React.MouseEvent<HTMLButtonElement>) {
        //Dismiss()
        navigate(e.currentTarget.name)
    }

    const dialog = useDialog()

    function Logout() {
        dialog.openDialog(
            "Encerrar Sessão",
            "Gostaria de encerrar a sessão atual?",
            () => navigate("/logout")
        )
    }

    return (
        <DrawerBackground className="column" ref={ref}>
            <StudentsModal active={alunos} setActive={setAlunos} />

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "60px",
                    width: "100%",
                }}
            >
                {/*<MutableIcon icon={BiMenu} size={30} onClick={Dismiss} />*/}
            </div>
            <MenuButton name="/" onClick={handle}>
                <HiHome size={25} color={"#777"} />
                <Label>Dashboard</Label>
            </MenuButton>
            <Divider />
            <Section>
                <MenuButton onClick={() => setAlunos(true)}>
                    <PiUsersThreeBold size={25} color={"#777"} />
                    <Label>Alunos</Label>
                </MenuButton>
                <MenuButton name="/calendar" onClick={handle}>
                    <BiCalendar size={25} color={"#777"} />
                    <Label>Agenda</Label>
                </MenuButton>
                <MenuButton onClick={undefined}>
                    <TbZoomMoney size={25} color={"#777"} />
                    <Label>Financeiro</Label>
                </MenuButton>
            </Section>
            <Divider />
            <Section>
                <MenuButton name="/tools/plans" onClick={handle}>
                    <PiPackageBold size={25} color={"#777"} />
                    <Label>Templates de Planos</Label>
                </MenuButton>
                <MenuButton name="/tools/workout" onClick={handle}>
                    <HiOutlineDocumentDuplicate size={25} color={"#777"} />
                    <Label>Templates de Treinos</Label>
                </MenuButton>
                <MenuButton name="/tools/exercises" onClick={handle}>
                    <HiOutlineDocument size={25} color={"#777"} />
                    <Label>Templates de Exercícios</Label>
                </MenuButton>
            </Section>
            <Divider />
            <MenuButton onClick={Logout}>
                <Label>Finalizar Sessão</Label>
                <CgLogOut size={25} color="red" />
            </MenuButton>
        </DrawerBackground>
    )
}

export default DrawerMenu
