import React, { useState, ChangeEvent, KeyboardEvent } from "react"
import styled from "styled-components"

const InputWrapper = styled.div`
    align-items: center;
    justify-content: space-between;
    background-color: #fff1;
    padding: 10px;
    border-radius: 10px;
    cursor: text;
    input {
        outline: none;
        border: 1.5px solid var(--font);
        border-radius: 25%;
        background-color: #fff1;
        font-size: 14px;
        color: var(--font);
    }
`

interface EmailRecoveryInputProps {
    disabled?: boolean
    onFinish?: () => void
    onChange: (value: string) => void
}

const EmailRecoveryInput: React.FC<EmailRecoveryInputProps> = ({
    onFinish,
    onChange,
    disabled,
}) => {
    const [codes, setCodes] = useState<string[]>(["", "", "", "", "", ""])

    function focusNextInput(index: number) {
        if (disabled) return
        const element = document.getElementById(
            `input-${index + 1}`
        ) as HTMLInputElement
        if (element) element.focus()
    }

    const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return
        const { value } = e.target

        if (/^\d*$/.test(value)) {
            setCodes((prevCodes) => {
                const newCodes = [...prevCodes]
                newCodes[index] = value
                onChange(newCodes.join(""))
                return newCodes
            })

            if (value !== "" && index < 5) focusNextInput(index)
            else if (value !== "" && index === 5 && onFinish) onFinish()
        }
    }

    const handleKeyDown = (
        index: number,
        e: KeyboardEvent<HTMLInputElement>
    ) => {
        if (disabled) return

        const elm = document.getElementById(
            `input-${index - 1}`
        ) as HTMLInputElement

        if (e.key === "Backspace" && index > 0 && codes[index] === "") {
            elm?.focus()
        }
    }

    return (
        <InputWrapper style={{ opacity: disabled ? 0.5 : 1 }}>
            {codes.map((code, index) => (
                <input
                    disabled={disabled}
                    key={index}
                    id={`input-${index}`}
                    type="text"
                    maxLength={1}
                    value={code}
                    onFocus={(e) => e.currentTarget.select()}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    style={{
                        width: "30px",
                        height: "30px",
                        marginRight: "5px",
                        textAlign: "center",
                    }}
                />
            ))}
        </InputWrapper>
    )
}

export default EmailRecoveryInput
