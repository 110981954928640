import React, { useState } from "react"
import { Baloon, Container, FeedbackArea } from "./styles"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"

import setts from "./settings.json"
import { ExerciseData } from "../.."
import moment from "moment"
import { formatSimpleChartData } from "./Utils/functions"
import { HiChevronDown } from "react-icons/hi"

const HistoryWrapper: React.FC<{
    data: ExerciseData[]
    highlited: boolean
}> = ({ data, highlited }) => {
    const { categories, reps, weight, feedback } = formatSimpleChartData(data)

    const [showFeedback, setShowFeedback] = useState(highlited)

    const [state] = useState({
        series: [
            {
                name: "WEIGHT",
                data: weight,
            },
            {
                name: "REPS",
                data: reps,
            },
        ] as ApexAxisChartSeries,
        options: {
            ...setts._chartInstances[0].chart.options,

            stroke: { width: 3, curve: "smooth" },
            annotations: {
                points: [
                    {
                        x: new Date(
                            "2024-09-07T17:01:58.080693-03:00"
                        ).getTime(), // Data específica no eixo X
                        y: 18, // Valor no eixo Y
                        marker: {
                            size: 8,
                            fillColor: "#fff",
                            strokeColor: "red",
                            radius: 2,
                            cssClass: "apexcharts-custom-class",
                        },
                        label: {
                            borderColor: "#FF4560",
                            offsetY: 0,
                            style: {
                                color: "#fff",
                                background: "#FF4560",
                            },
                            text: "Ponto importante",
                        },
                    },
                ],
            } as ApexAnnotations,
            yaxis: setts.yaxis,
            xaxis: {
                ...setts.xaxis,
                categories, // Dados no eixo X
            },
            colors: ["#1A1A1A", "#7CFFD3"], // Configuração de cores
            legend: {
                show: true,
                fontWeight: 600,
                offsetX: 0, // Ajusta a posição horizontal da legenda
                offsetY: 5, // Ajusta a posição vertical da legenda
            },
            chart: {
                ...setts.chart,
                ...setts.legend,
                background: "transparent",
                chart: setts.chart,
                fontFamily: "Exo 2",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            } as ApexChart,
        } as ApexOptions,
    })

    return (
        <Container>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={"200px"}
                width={"100%"}
            />

            <FeedbackArea>
                {feedback.length > 0 ? (
                    <button onClick={() => setShowFeedback(!showFeedback)}>
                        <span
                            className={
                                "icon" + (!showFeedback ? " up" : " down")
                            }
                        >
                            <HiChevronDown size={20} />
                        </span>
                        <p>
                            {showFeedback
                                ? "Esconder Feedbacks"
                                : "Mostrar Feedbacks"}
                        </p>
                    </button>
                ) : (
                    <></>
                )}
                {showFeedback &&
                    feedback.map((e, i) =>
                        e.message != "" ? (
                            <Baloon key={`b${i}`}>
                                <h4>Feedback:</h4>
                                <p>
                                    {e.message === ""
                                        ? "Não há mensagem de feedback para este dia."
                                        : e.message}
                                </p>
                                <h4>{moment(e.date).format("LLL")}</h4>
                            </Baloon>
                        ) : (
                            <></>
                        )
                    )}
            </FeedbackArea>
        </Container>
    )
}

export default HistoryWrapper

/*dados input = [
    {
        feedback: "Feedback X...",
        data: [
            {
                reps: 7,
                weight: 10,
            },
            {
                reps: 7,
                weight: 10,
            },
            {
                reps: 7,
                weight: 10,
            },
        ],
        exec_at: "2024-09-07T17:01:58.080693-03:00",
    },
    {
        feedback: "Feedback Y...",
        data: [
            {
                reps: 8,
                weight: 15,
            },
            {
                reps: 8,
                weight: 15,
            },
            {
                reps: 8,
                weight: 15,
            },
        ],
        exec_at: "2024-09-08T17:03:27.517418-03:00",
    },
]

output:

categories = [
    "07/09/24",
    "07/09/24",
    "07/09/24",
    "08/09/24",
    "08/09/24",
    "08/09/24",
]

feedback = [
    { date: "2024-09-07T17:01:58.080693-03:00", message: "Feedback X..." },
    { date: "2024-09-07T17:03:27.517418-03:00", message: "Feedback Y..." },
]

reps = [7, 7, 7, 8, 8, 8]
weight = [10, 10, 10, 15, 15, 15]*/
