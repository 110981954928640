export enum FieldType {
    NUMBER,
    NUMBER_4,
    NUMBER_2,
    DESCRIPTION,
    PYRAMID,
}

export class WeightExercise {
    name: string
    id: string
    obs?: string
    media_url?: string
    createdat: Date
    values: ExerciseValues

    linkId: string

    constructor(name: string, linkId: string, id: string) {
        this.name = name
        this.id = id
        this.linkId = linkId
        this.createdat = new Date()
        this.values = new ExerciseValues()
    }
}

export type ExerciseFields =
    | "series"
    | "reps"
    | "cadency"
    | "interval"
    | "method"

export class ExerciseValue {
    option = 0
    value: number[] = [0]
}

export class ExerciseValues {
    series = new ExerciseValue()
    reps = new ExerciseValue()
    cadency = new ExerciseValue()
    interval = new ExerciseValue()
    method = new ExerciseValue()
}
