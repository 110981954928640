import { SkinFoldsList } from "./folds"
import { AssessmentClass } from "./assessment"

const GetTotalFolds = (skin: SkinFoldsList) => {
    return Object.values(skin).reduce((a, n) => a + n, 0)
}

function PetroskiMale(person: EquationNeeds) {
    if (!person.weight) return 0

    const totalFolds = GetTotalFolds(person.content.folds)

    const petroskiHomens =
        1.10194032 -
        0.00031836 * totalFolds +
        0.00000029 * (totalFolds * totalFolds) -
        0.00029542 * person.age

    return (4.95 / petroskiHomens - 4.5) * person.weight
}

function SobrepesoHomem(person: EquationNeeds) {
    const { weight } = person
    const { waist, abdomen } = person.content.perimetry

    if (!waist || !abdomen) return 0

    const sob =
        (0.31457 * (waist + abdomen / 2) - 0.10969 * weight + 10.8336) / 100

    return weight * sob
}

function ChildrenMale(person: EquationNeeds) {
    if (
        !person.weight ||
        !person.content.folds.triceps ||
        !person.content.folds.calf
    )
        return 0

    const totalFolds = person.content.folds.triceps + person.content.folds.calf

    const c = (0.735 * totalFolds + 1) / 100

    return c * person.weight
}

function ChildrenFemale(person: EquationNeeds) {
    if (
        !person.weight ||
        !person.content.folds.triceps ||
        !person.content.folds.calf
    )
        return 0

    const totalFolds = person.content.folds.triceps + person.content.folds.calf

    const c = (0.61 * totalFolds + 5.1) / 100

    return c * person.weight
}

function Petroski9Women(person: EquationNeeds) {
    const totalFolds = GetTotalFolds(person.content.folds)

    const total =
        1.03987298 -
        0.00031853 * totalFolds +
        0.00000047 * Math.pow(totalFolds, 2) -
        0.00025486 * person.age -
        0.00047358 * person.weight +
        0.00046897 * (person.height * 100)

    const a = 4.95 / total

    return person.weight * (a - 4.5)
}

function SobrepesoMulheres(person: EquationNeeds) {
    if (!person.content.perimetry.waist || !person.content.perimetry.abdomen)
        return 0

    const sob =
        (0.11077 *
            (person.content.perimetry.waist +
                person.content.perimetry.abdomen / 2) -
            0.17666 * (person.height * 100) +
            0.14354 * person.weight +
            39.83) /
        100

    console.log("sobrepeso", [
        person.content.perimetry.waist,
        person.content.perimetry.abdomen,
        person.height,
        person.weight,
    ])

    return person.weight * sob
}

function Mulheres51P(person: EquationNeeds) {
    const { height, age } = person
    const { waist, abdomen, hip } = person.content.perimetry

    if (!waist || !abdomen || !hip) return 0

    const sob =
        1.168297 -
        0.002824 * (waist + abdomen / 2) +
        0.0000122098 * Math.pow(waist + abdomen / 2, 2) -
        0.000733128 * hip +
        0.000510477 * height * 100 -
        0.000216161 * age

    return person.weight * (5.01 / sob - 4.57)
}

//type Keys = SkinFoldsListKeys & PerimetryListKeys & BonesListKeys

interface Person {
    age: number
    gender: string
}

type EquationNeeds = AssessmentClass & Person

interface FoldEquation {
    title: string
    equation(person: EquationNeeds): number
    gender: "Male" | "Female"
    affects: string[]
}

const Equations: FoldEquation[] = [
    {
        title: "9 Dobras, Homens (Petroski)",
        equation: PetroskiMale,
        gender: "Male",
        affects: ["folds_all"],
    },
    {
        title: "Sobrepeso, Homens (Weltman, 1987)",
        equation: SobrepesoHomem,
        gender: "Male",
        affects: ["waist", "abdomen"],
    },
    {
        title: "Crianças, Masculino (Slaughter, 1988)",
        equation: ChildrenMale,
        gender: "Male",
        affects: ["triceps", "calf"],
    },
    {
        title: "Crianças, Feminino (Slaughter, 1988)",
        equation: ChildrenFemale,
        gender: "Female",
        affects: ["triceps", "calf"],
    },
    {
        title: "9 Dobras, Mulheres (Petroski)",
        equation: Petroski9Women,
        gender: "Female",
        affects: ["folds_all"],
    },
    {
        title: "Sobrepeso, Mulheres (Weltman, 1988)",
        equation: SobrepesoMulheres,
        gender: "Female",
        affects: ["waist", "abdomen"],
    },
    {
        title: "Mulheres acima de 51 anos (Tran & Weltman, 1989)",
        equation: Mulheres51P,
        gender: "Female",
        affects: ["waist", "abdomen", "hip"],
    },
]

export default Equations
