import React, { useState } from "react"
import CustomContextMenu from "./custom-context-menu"

// Define uma interface para os itens do menu de contexto
export interface ContextMenuItem {
    title: string
    icon: React.ElementType
    action: () => void
}

// Define a interface para o estado do menu de contexto
export interface ContextMenuState {
    show: boolean
    x: number
    y: number
    items: ContextMenuItem[]
}

// Define o estado inicial do menu de contexto
const initialContextMenuState: ContextMenuState = {
    show: false,
    x: 0,
    y: 0,
    items: [],
}

// Cria o hook useCustomContextMenu para gerenciar o menu de contexto
export const useCustomContextMenu = () => {
    const [contextMenu, setContextMenu] = useState<ContextMenuState>(
        initialContextMenuState
    )

    // Função para exibir o menu de contexto
    const showContextMenu = (
        e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
        items: ContextMenuItem[]
    ) => {
        e.preventDefault()
        const { pageX, pageY } = e
        setContextMenu({ show: true, x: pageX, y: pageY, items })
    }

    const ContextMenu = () => {
        if (contextMenu.show) {
            return (
                <CustomContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    items={contextMenu.items}
                    show={true}
                    onClose={() => setContextMenu(initialContextMenuState)}
                />
            )
        } else {
            return <></>
        }
    }

    // Função para fechar o menu de contexto
    const closeContextMenu = () => {
        setContextMenu(initialContextMenuState)
    }

    return { showContextMenu, closeContextMenu, ContextMenu }
}
