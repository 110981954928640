import React, { useContext, useEffect, useRef, useState } from "react"
import { Backdrop, Panel } from "./styles"
import StudentsList from "src/screens/students-list"
import { Student } from "src/screens/student-info"
import { useStudent } from "src/contexts/student"
import ReactDOM from "react-dom"
import api from "src/API/rest"
import AuthContext from "src/contexts/auth"
import { useNavigate } from "react-router-dom"

// import { Container } from './styles';

const StudentsModal: React.FC<{
    onSelect?: (std: Student) => void
    active: boolean
    setActive: (value: boolean) => void
}> = ({ onSelect, active, setActive }) => {
    const student = useStudent()
    const auth = useContext(AuthContext)
    const root = document.getElementById("root")

    const navigate = useNavigate()

    useEffect(() => {
        student.blurContent(active)
    }, [active])

    function handleSelect(std: Student) {
        if (onSelect != undefined) {
            onSelect(std)
            setActive(false)
        } else {
            api.post<Student>("/students/get", {
                id: std.id,
                trainer_id: auth.user?.id,
            }).then((res) => {
                student.onSetStudent(res.data)
                navigate("/student")
                setActive(false)
            })
        }

        student.blurContent(false)
    }

    if (root === null || !active) return <></>

    return ReactDOM.createPortal(
        <Backdrop>
            <Panel className="flex column">
                <StudentsList
                    dismiss={() => setActive(false)}
                    onSelect={handleSelect}
                />
            </Panel>
        </Backdrop>,
        root
    )
}

export default StudentsModal
