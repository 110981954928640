import styled from "styled-components"
export const DescriptionArea = styled.textarea`
    position: relative;
    resize: vertical;
    min-height: 60px;
    padding: 5px; /* Adicionando padding para o espaço do placeholder */
    border-radius: 5px;

    ::before {
        position: absolute; /* Corrigindo o erro de digitação */
        content: attr(data-placeholder);
        top: 0; /* Ajustando a posição para o topo */
        left: 0; /* Ajustando a posição para a esquerda */
        background-color: magenta;
        padding: 0 5px; /* Adicionando padding para espaçamento */
    }
`
