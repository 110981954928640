import React from "react"
import styled from "styled-components"
import { CardButton } from ".."
import { IconType } from "react-icons"

const Numbers = styled.h1`
    text-transform: uppercase;
    color: #4a4a4a;
    font-weight: 600;
`

const Subtitle = styled.h3`
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #b3b3b3;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
`

const Inline = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
`

interface InfoProps {
    Icon: IconType
    amount: number
    subtitle: string
    onClick?(): void
}

const InfoCard: React.FC<InfoProps> = ({ Icon, amount, subtitle, onClick }) => {
    return (
        <CardButton onClick={onClick} style={{ alignItems: "center" }}>
            <Inline>
                <Block>
                    <Numbers>{amount}</Numbers>
                    <Subtitle>{subtitle}</Subtitle>
                </Block>
                <Icon size={40} color={"#4a4a4a"} />
            </Inline>
        </CardButton>
    )
}

export default InfoCard
