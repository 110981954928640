// firebase.ts
import { FirebaseOptions, initializeApp } from "firebase/app"
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    User,
} from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyD3RXLnMhPJ16usDCh3eloL3iW9tFRSgHM",
    authDomain: "hypefit-f98e9.firebaseapp.com",
    projectId: "hypefit-f98e9",
    storageBucket: "hypefit-f98e9.appspot.com",
    messagingSenderId: "819282526595",
    appId: "1:819282526595:web:8367300c3dcf4f85a3b6b6",
    measurementId: "G-QTKWQDK1XH",
} as FirebaseOptions

// Inicializar Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

// Provedor Google
const provider = new GoogleAuthProvider()

// Função para login com Google
export const signInWithGoogle = async (): Promise<User | null> => {
    try {
        const result = await signInWithPopup(auth, provider)

        return result.user
    } catch (error) {
        console.error("Erro durante o login com Google", error)
        return null
    }
}

// Função para logout
export const logOut = async (): Promise<void> => {
    try {
        await signOut(auth)
    } catch (error) {
        console.error("Erro durante o logout", error)
    }
}

export { auth }
