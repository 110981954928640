import styled from "styled-components"

export const Container = styled.div`
    position: relative;
    flex-direction: column;
    padding: 2px;

    width: 100%;

    margin-bottom: 10px;
    border-radius: 5px;

    background-color: #ddd;

    :nth-child(odd) {
        background-color: #eee;
    }
`

export const CommentField = styled.div`
    position: absolute;
    z-index: 100;
    background-color: #1d1d1d;
    padding: 5px;
    width: 300px;
    height: 150px;
    right: 0;
    top: 0;
    height: "100%";

    flex-direction: column;
    border-radius: 5px;
    textarea {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 5px;
        resize: none;

        background-color: #0008;

        color: white;
    }

    .buttons {
        padding: 5px;
        justify-content: space-evenly;

        button {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 4px;
            border-radius: 5px;
            background-color: #444;
            color: white;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            p {
                padding-left: 5px;
            }
        }

        .delete {
            background-color: red;
        }
    }
`

export const RecoverButton = styled.button`
    padding: 5px;
    margin-inline: 5px;
    cursor: pointer;

    background-color: #0002;

    transition: transform 0.1s;

    :hover {
        font-weight: bold;
        transform: scale(1.05);
    }
`

export const DeletedInfo = styled.div`
    color: red;

    align-items: center;
    justify-content: center;
    height: 15px;

    font-size: 14px;

    margin-block: 8px;

    padding-right: 8px;
    border-radius: 15px;
`

export const DragItem = styled.div<{ isDragging: boolean }>`
    cursor: ${(p) => (p.isDragging ? "grabbing" : "grab")};

    width: 32px;
    height: 100%;

    :hover {
        opacity: 0.8;
    }

    margin-right: 2px;

    align-items: center;
    justify-content: center;
    background-color: #aaa;
    border-radius: 3px;
`

export const HeaderButton = styled.button<{ width?: number }>`
    cursor: pointer;
    min-width: 32px;
    width: ${(p) => (p.width ? p.width + "px" : "fit-content")};
    height: 100%;

    :not(:last-child) {
        margin-right: 2px;
    }

    :hover {
        opacity: 0.8;
        border-radius: 25px;
    }

    transition: border-radius 0.2s;

    align-items: center;
    justify-content: center;
    background-color: #aaa;
    border-radius: 3px;
`

export const ChangeItem = styled.div`
    cursor: pointer;

    height: 100%;
    padding-right: 5px;
    margin-right: 5px;

    border-right: 1px solid #888;

    height: 20px;

    :hover {
        opacity: 0.8;
    }

    align-items: center;
    justify-content: center;
    background-color: #ccc;
`

export const HeaderTitle = styled.div`
    display: flex; /* Adicionar display flex para alinhar corretamente os itens */
    flex-direction: row;
    width: 100%;
    height: 100%;

    margin-right: 2px;

    align-items: center;
    justify-content: space-between;
    background-color: #ccc;
    border-radius: 3px;

    padding-left: 7px;

    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
`

export const HeaderTextScroll = styled.div`
    display: flex;
    flex: 1;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`

export const HeaderText = styled.p`
    //display: block; /* Usar display block para que o elemento possa ajustar a largura corretamente */
    //flex: 1; /* Permitir que o HeaderText ocupe o espaço restante */

    padding-right: 10px;

    font-size: clamp(8px, 2.5vw, 14px);
    font-weight: 600;

    /*white-space: nowrap; /* Não quebrar linhas */
    //overflow: hidden; /* Ocultar texto que ultrapassa o espaço disponível */
    //text-overflow: ellipsis;
`

export const Header = styled.div`
    flex-direction: row;

    height: 32px;
    width: 100%;

    @media (max-width: 800px) {
        height: 32px;
    }

    margin-bottom: 2px;
`

export const Column = styled.div`
    flex-direction: column;
    width: 100%;
    text-align: left;
    padding: 6px;

    span {
        margin-block: 10px;
    }

    h4 {
        font-size: 14px;
        margin-right: 10px;
        font-weight: 600;
    }

    .tooltip-wrap {
        position: relative;
        height: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;

        .tooltip {
            top: -200%;
            z-index: 99;
            position: absolute;
            padding: 0px;

            color: white;
            background-color: #888;
        }
    }
`

export const ObsButton = styled.button`
    color: white;
    background-color: #444;

    width: 90px;

    padding-block: 5px;
    padding-inline: 7px;
    font-size: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;

    cursor: pointer;

    :hover {
        opacity: 0.7;
    }
`

export const RowAligned = styled.div`
    flex: 1;
    flex-direction: row;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`
