import React, { useEffect, useRef } from "react"

// import { Container } from './styles';

type Event = MouseEvent | TouchEvent

export const useOnOutClick = <T extends HTMLElement = HTMLElement>(
    ref: React.RefObject<T>,
    handler: (event: Event) => void
) => {
    useEffect(() => {
        const listener = (event: Event) => {
            const el = ref?.current
            if (!el || el.contains(event?.target as Node) || null) {
                return
            }

            handler(event as Event)
        }

        document.addEventListener("mousedown", listener)
        document.addEventListener("touchstart", listener)

        return () => {
            document.removeEventListener("mousedown", listener)
            document.removeEventListener("touchstart", listener)
        }
    }, [ref, handler])
}

export const useCSSTransition = <T extends HTMLElement>(
    ref: React.RefObject<T> | null,
    className: string,
    duration = 100,
    // eslint-disable-next-line
    watchProp: any = undefined
) => {
    const elementRef = useRef<T | null>(null)

    useEffect(() => {
        // Use o ref fornecido ou crie um novo ref se nenhum for fornecido
        const targetRef = ref || elementRef

        const element = targetRef.current

        if (element) {
            // Adicione a classe para iniciar a animação
            element.classList.add(className)

            // Use setTimeout para remover a classe após a animação
            const timeout = setTimeout(() => {
                element.classList.remove(className)
            }, duration)

            // Limpe o timeout para evitar vazamento de memória
            return () => clearTimeout(timeout)
        }
    }, watchProp)

    return elementRef
}
