import styled from "styled-components"

export const Container = styled.div`
    flex: 1;

    overflow: hidden;

    .centered {
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 768px) {
        flex: 1;
    }
`

export const List = styled.ul<{ flex: number }>`
    flex: ${(p) => p.flex};
    overflow: hidden;
    @media (min-width: 768px) {
        width: 10vh;
        min-width: 270px;
    }
`

export const HorizontalResize = styled.span`
    position: relative;

    width: 5px;
    justify-content: center;

    cursor: ew-resize;

    border-left: 2px solid #ddd;
    color: #ddd;

    justify-content: center;
    align-items: center;

    margin-bottom: 0.5vh;

    span {
        position: absolute;
        right: -4px;
        height: 25px;
        background-color: white;
        align-items: center;
        justify-content: center;
    }

    :hover {
        border-left: 2px solid #888;
        color: #888;
    }

    transition: color 200ms, border 200ms;
`

export const Content = styled.ul<{ flex: number }>`
    flex: ${(p) => p.flex};
    overflow-y: auto;
`

export const GoBack = styled.button`
    cursor: pointer;
    width: fit-content;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    :active {
        opacity: 0.5;
    }
`
