import React from "react"
import styled from "styled-components"
import { useStudent } from "src/contexts/student"
import MutableIcon from "src/components/mutable-icon"
import { HiChevronLeft } from "react-icons/hi"
import moment from "moment"
import { useDialog } from "src/contexts/dialog-context"
import { useNavigate } from "react-router-dom"
import Toggle from "react-toggle"
import { Avatar } from "src/components/header/styles"

import demoPic from "src/assets/png/userd-demo-pic.png"
import { RiAlertFill } from "react-icons/ri"
import api from "src/API/rest"
import { truncateSync } from "fs"
import mostrarAlerta from "src/components/utils/alert"

const Container = styled.div`
    display: flex;
    * {
        display: flex;
    }
    flex: 1;

    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div`
    flex: 1;
    flex-direction: column;

    padding: 10px;

    max-width: 600px;

    h3 {
        padding-left: 15px;
        font-weight: bold;
    }
`

const LinkButton = styled.button`
    background-color: #444;
    padding-inline: 5px;
    align-items: center;
    font-weight: bold;
    color: white;
    cursor: pointer;

    border-radius: 5px;

    transition: border-radius 0.1s;

    margin-right: 5px;

    :active {
        border-radius: 50px;
    }
`

const Section = styled.div`
    border-radius: 5px;
    height: 40px;
    min-width: 40%;

    flex-direction: column;

    padding-inline: 5px;

    h4 {
        font-weight: bold;
        font-size: 14px;

        align-items: center;

        color: #888;

        > * {
            font-size: 20px;
            margin-right: 5px;
        }
    }

    margin-bottom: 10px;
`

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    border: 1px solid #ddd;
    border-radius: 7.5px;
    padding: 10px;

    margin-bottom: 15px;
`

/*export interface Student {
    id: string
    name: string
    username: string
    email: string
    phone: string
    gender: string
    notes: string
    birthdate: Date
    created_at: Date
}*/

const StudentSettings: React.FC = () => {
    const { student } = useStudent()

    const dialog = useDialog()
    const nav = useNavigate()

    function Unlink() {
        dialog.openDialog(
            "Desvincular Aluno",
            "Gostaria de desvincular este aluno? Os dados vinculados com sua conta serão excluídos e não poderão ser recuperados!"
        )
    }

    function ResetPass() {
        console.log("reset pass for student", student?.id)

        dialog.openDialog(
            "Redefinir Senha",
            "Gostaria de desvincular este aluno? Os dados vinculados com sua conta serão excluídos e não poderão ser recuperados!",
            () =>
                api
                    .post("/reset/password", {
                        user: student?.email,
                        id: student?.id,
                    })
                    .then((res) => {
                        if (res.status === 200)
                            mostrarAlerta("Senha foi Redefinida!")
                        else mostrarAlerta("Houve um erro ao redefinir")
                    })
                    .catch((err) => mostrarAlerta(err))
        )
    }

    return (
        <Container className="">
            <Wrapper>
                <div
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: 10,
                    }}
                >
                    <MutableIcon
                        onClick={() => nav(-1)}
                        icon={HiChevronLeft}
                        size={40}
                    />
                    <Avatar src={demoPic} />
                    <h3>{student?.name}</h3>
                </div>

                <Grid>
                    <div
                        style={{
                            width: "100%",
                            paddingBottom: 20,
                            flexDirection: "column",
                        }}
                    >
                        <h2>Informaçoes Pessoais</h2>
                        <p style={{ color: "#555" }}>Dados de Cadastro</p>
                    </div>
                    <Section>
                        <h4>Nome:</h4>
                        <p>{student?.name}</p>
                    </Section>

                    <Section>
                        <h4>Email:</h4>
                        <p>{student?.email}</p>
                    </Section>

                    <Section>
                        <h4>Celular:</h4>
                        <p>{student?.phone}</p>
                    </Section>

                    <Section>
                        <h4>Gênero:</h4>
                        <p>
                            {!student?.gender ? (
                                <p style={{ alignItems: "center" }}>
                                    <RiAlertFill style={{ marginRight: 10 }} />
                                    Indefinido!
                                </p>
                            ) : (
                                student.gender
                            )}
                        </p>
                    </Section>

                    <Section>
                        <h4>Nascimento: </h4>
                        <p>{moment(student?.birthdate).format("LL")}</p>
                    </Section>
                </Grid>
                <Grid>
                    <div
                        style={{
                            width: "100%",
                            paddingBottom: 20,
                            flexDirection: "column",
                        }}
                    >
                        <h2>Faturamento</h2>
                        <p style={{ color: "#555" }}>Planos e Pagamento</p>
                    </div>

                    <Section>
                        <h4>Plano Atual: </h4>
                        <p>Indisponível{/*Semestral Pro*/}</p>
                    </Section>
                    {/*<Section>
                        <h4>Forma de Pagamento</h4>
                        <p>Recorrente, PIX</p>
                    </Section>*/}
                </Grid>
                <div
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        backgroundColor: "#ddd",
                        padding: 10,
                        borderRadius: 12.5,
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            flex: 0.5,
                        }}
                    >
                        <Toggle icons={false} />
                        <h4
                            style={{ alignItems: "center", fontWeight: "bold" }}
                        >
                            Bloquear Acesso
                        </h4>
                    </div>
                    <span
                        style={{
                            backgroundColor: "#000",
                            width: 1,
                        }}
                    />
                    <div style={{ flex: 0.5, justifyContent: "center" }}>
                        <LinkButton onClick={ResetPass}>
                            REDEFINIR SENHA
                        </LinkButton>
                        <LinkButton
                            onClick={Unlink}
                            style={{ backgroundColor: "var(--primary)" }}
                        >
                            DESVINCULAR
                        </LinkButton>
                    </div>
                </div>
            </Wrapper>
        </Container>
    )
}

export default StudentSettings
